import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Upload from '../../../../assets/images/upload.svg';

import { actionTracking } from '../../../../store/common/actions';

function UploadDocSuccessful() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actionTracking({
        url: window.location.href,
      }),
    );
  }, [dispatch]);

  return (
    <div className="bg-selago p-5 rounded-xl mb-6 mx-5">
      <div className="flex justify-center">
        <img src={Upload} alt="" />
      </div>
      <p className="text-primary text-2xl text-center mb-8">文件上傳成功</p>
      <p className="text-gray text-center">我們已收到閣下上傳之文件並會盡快處理。</p>
      <p className="text-gray text-center">如有任何疑問，歡迎於辦公時間內致電</p>
      <p className="text-gray text-center mb-8">3700 4488。</p>
      <div className="flex justify-center mb-4">
        <button onClick={() => navigate('/')} className="rounded-full text-white text-xl bg-lightningYellow w-52 py-4">
          返回主頁
        </button>
      </div>
    </div>
  );
}

export default UploadDocSuccessful;
