import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { Common, PopUpBox } from '../../components';
import MobileSms from '../../assets/images/mobile-sms.svg';

import { actionSendOTP, actionLoginOTP, actionSendClientID } from '../../store/application/actions';
import { numberWithNoCommas } from '../../utils/common';

const COUNT_TIMES = 4;

function ApplicationOTP() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  const [isVisibleTimeOverOTP, setIsVisibleTimeOverOTP] = useState(false);
  const [isVisibleWrongOTP, setIsVisibleWrongOTP] = useState(false);
  const [isVisibleExpiredOTP, setIsVisibleExpiredOTP] = useState(false);
  const [countTimes, setCountTimes] = useState(COUNT_TIMES);
  const [countView, setCountView] = useState(COUNT_TIMES + 1);
  const [resendDisabled, setResendDisabled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!location.state?.phone) {
      navigate('/');
    }
  }, [location.state?.phone, navigate]);

  useEffect(() => {
    if (countTimes === 0 && !isVisibleExpiredOTP) {
      setIsVisibleTimeOverOTP(true);
    }
  }, [countTimes, isVisibleExpiredOTP]);

  const onReSendOTP = () => {
    if (resendDisabled) {
      return;
    }
    setResendDisabled(true);
    if (countTimes !== 0) {
      dispatch(
        actionSendOTP({
          phone: location.state?.phone,
        }),
      );
      setIsVisibleTimeOverOTP(false);
    } else {
      navigate('/?open=register');
    }
  };

  const onSubmit = async (data) => {
    const response = await dispatch(
      actionLoginOTP({
        phone: location.state?.phone,
        otp: data.otp,
      }),
    );
    if (response?.sts_code === 0) {
      // SUCCESS
      await localStorage.setItem('access_token', response?.content?.access_token);
      console.info(process.env.REACT_APP_GA_CODE);
      ReactGA.initialize(process.env.REACT_APP_GA_CODE, {
        gaOptions: {
          userId: response?.content?.user_id,
        },
      });
      ReactGA.send('pageview');
      ReactGA.ga(async (tracker) => {
        const clientId = tracker.get('clientId');
        await dispatch(
          actionSendClientID({
            client_id: clientId,
          }),
        );
      });
      ReactGA.set({ user_app_id: response?.content?.user_id });
      navigate('/application-otp-success', { replace: true, state: { phone: location.state?.phone, path: '/application-consent' } });
    } else if (response?.sts_code === 5) {
      // INVALID_OTP
      setValue('otp', '');
      setIsVisibleWrongOTP(true);
      setCountView(countView - 1);
    } else if (response?.sts_code === 6) {
      // EXPIRED_OTP
      setCountTimes(0);
      setIsVisibleExpiredOTP(true);
      setIsVisibleTimeOverOTP(false);
    } else if (response?.sts_code === 7) {
      // MAX_WRONG_INPUT_OTP
      setCountTimes(0);
      setIsVisibleTimeOverOTP(true);
    } else {
      setCountTimes(countTimes - 1);
    }
  };

  return (
    <>
      <Common.Loading isVisible={isSubmitting} />
      <div className="bg-white p-5">
        {isVisibleTimeOverOTP && <PopUpBox.TimeOverOTP onClick={onReSendOTP} number={countTimes}>
          <div className="text-center my-3">
            <button onClick={onReSendOTP} type="button" className="rounded-full text-white text-sm bg-lightningYellow px-14 py-2">
              確定
            </button>
          </div>
        </PopUpBox.TimeOverOTP>}
        {isVisibleWrongOTP && !isVisibleExpiredOTP && (
          <PopUpBox.Error
            onClick={() => {
              setIsVisibleWrongOTP(false);
            }}
            content="驗證碼錯誤，請重新輸入。"
          >
            <div className="text-center">
              {countView > 0 && <p className="text-primary my-3">(剩餘{countView}次)</p>}
              <button onClick={() => {
                setIsVisibleWrongOTP(false);
              }} type="button" className="rounded-full text-white text-sm bg-lightningYellow px-14 py-2">
                確定
              </button>
            </div>
          </PopUpBox.Error>
        )}
        {isVisibleExpiredOTP && (
          <PopUpBox.Error
            onClick={() => {
              setIsVisibleExpiredOTP(false);
            }}
            content="驗證超時，按此取得新的驗證碼。"
          >
            <div className="text-center">
              <button onClick={() => {
                dispatch(
                  actionSendOTP({
                    phone: location.state?.phone,
                  }),
                );
                setCountTimes(1)
                setValue('otp', '')
              }} type="button" className="rounded-full text-white text-sm bg-lightningYellow px-14 py-2 mt-3">
                確定
              </button>
            </div>
          </PopUpBox.Error>
        )}
        <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">安全驗證</p>
        <p className="text-gray mb-4">驗證碼已發送到閣下登記之手提電話號碼</p>
        <div className="flex justify-center mb-5">
          <img src={MobileSms} alt="" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-12 mb-2">
            <input
              inputMode='numeric'
              {...register('otp', {
                required: true,
                onChange: (e) => {
                  const value = numberWithNoCommas(e?.target?.value)
                  setValue('otp', value)
                }
              })}
              className="border border-primary rounded-md w-full h-10 outline-none px-2 text-center text-primary"
              style={{ marginBottom: -5 }}
              placeholder="請輸入驗證碼"
              maxLength={6}
            />
            {errors.otp && errors.otp.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
          </div>
          <p className="text-8 mb-6 text-center text-gray">
            未能收到驗證碼？
            <span className={`${!resendDisabled ? 'text-primary cursor-pointer' : 'text-gray cursor-not-allowed'} underline`} onClick={onReSendOTP}>
              取得新的驗證碼
            </span>
          </p>
          <div className="flex justify-center mb-4">
            <button type="submit" disabled={isSubmitting} className={`rounded-full text-white text-xl px-14 py-2 ${isSubmitting ? 'bg-mercury' : 'bg-lightningYellow'}`}>
              提交
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default ApplicationOTP;
