import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { PopUpBox } from '../../components';
import PhoneSelfie from '../../assets/images/phone-selfie.png';
import LightBulb from '../../assets/images/light-bulb.svg';

import { actionLink2ndCheck, actionTracking2 } from '../../store/common/actions';

function ConfirmSelfieCheck() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useParams();
  const [isVisibleConfirmSelfieTakePhoto, setIsVisibleConfirmSelfieTakePhoto] = useState(false);
  const [countTimes, setCountTimes] = useState(-1)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!token) {
      navigate('/');
    } else {
      localStorage.setItem('access_token', token);
    }
  }, [token, navigate]);

  useEffect(() => {
    async function tracking() {
      const res = await dispatch(
        actionTracking2({
          url: window.location.href,
        }),
      );
      if (res?.sts_code !== 0) {
        navigate('/');
        localStorage.removeItem('access_token');
      } else if (!location.state?.notCheckLink) {
        const res = await dispatch(
          actionLink2ndCheck(),
        );
        if (res && res?.content?.error_count > 4) {
          window.location.href = `${process.env.REACT_APP_HOME_URL}/page-expired`
        } else {
          setCountTimes(res?.content?.error_count > 0 ? res?.content?.error_count : -1)
        }
        if ([4, 5].includes(res?.content?.status) && !location?.state?.selfie) {
          navigate(`${location.pathname}/confirm-id-check`)
        }
      }
    }
    tracking()
  }, [dispatch, location.state?.notCheckLink, location?.state?.selfie]);

  useEffect(() => {
    setIsVisibleConfirmSelfieTakePhoto(!!location.state?.showCamera)
  }, [location.state?.showCamera, navigate]);

  return (
    <div className="bg-white p-5">
      {isVisibleConfirmSelfieTakePhoto && <PopUpBox.ConfirmSelfieTakePhoto countTimeDefault={countTimes} flow={location?.state?.flow ?? 2} />}
      <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">本人自拍</p>
      <p className="text-gray text-center">
        正面面向手機
        <span className="text-primary">
          前置鏡頭
        </span>
      </p>
      <div className="flex justify-center">
        <img className='my-3' style={{ width: 249 }} src={PhoneSelfie} alt="" />
      </div>
      <div className="flex items-center text-lg mb-2">
        <img src={LightBulb} alt="" />
        <p className="text-gray underline mt-2">自拍小貼士</p>
      </div>
      <p className="text-gray ml-4">
        ・確保四周光線充足
        <br />
        ・與鏡頭保持適當距離
        <br />
        ・確保背景清晰
        <br />
        ・除去口罩
        <br />
        ・除去太陽眼鏡
        <br />
        ・除去帽子及所有頭飾
      </p>
      <div className="flex justify-center mt-9 mb-4">
        <button className="rounded-full text-white text-xl bg-lightningYellow px-14 py-3" onClick={() => setIsVisibleConfirmSelfieTakePhoto(true)}>
          開始拍攝
        </button>
      </div>
    </div>
  );
}

export default ConfirmSelfieCheck;
