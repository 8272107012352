import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { actionTracking } from '../../store/common/actions';

function ApplicationConsent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      actionTracking({
        url: window.location.href,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!location.state?.phone) {
      navigate('/');
    }
  }, [location.state?.phone, navigate]);

  return (
    <div className="bg-white p-5">
      <p className="text-xs text-doveGray mb-2">閣下使用本私人貸款申請服務前，務請仔細閱讀本聲明：</p>
      <div className="border-2 border-primary p-4 text-xs text-doveGray">
        <p className="mb-2">本人謹此同意及授權環聯資訊有限公司:-</p>
        <p className="mb-5">(a)	使用、處理由本人或KM International (Asia) Co., Limited提供的所有資料（包括任何圖像、影像或文件）及作互相核對；及 </p>
        <p className="mb-5">(b)	取用部份或全部本人不時於環聯資訊有限公司資料庫內本人的信貸資料（「本人的信貸資料」）用作以部份或全部「本人的信貸資料」及其他資料庫内資料核對由本人提供及由KM International (Asia) Co., Limited轉交予環聯資訊有限公司的資料；</p>
        <p className="mb-5">作為有關本人借貸貸款申請時評估本人信貸風險的一部份，以驗證本人身份、文件或任何資料及之後用作處理、使用及轉移驗證結果及因此而產生的資料予銀行; 及</p>
        <p className="mb-5">(c)	轉移「本人的信貸資料」作有關本人借貸貸款申請時評估本人信貸風險之用。</p>
        <p className="mb-5">
          本人亦知悉及同意環聯資訊有限公司就有關取用及使用本人提供及於環聯資訊有限公司資料庫內的個人信貸資料向KM International (Asia) Co., Limited收取費用及就有關取用及使用不能構成本人對環聯資訊有限公司作任何投訴、申索、訴訟、索求或訴訟理由或其他法律程序的基礎。
        </p>
        <div className="flex">
          <input type="radio" id="id-radio1" checked={isChecked} className="mr-2 mt-1" onChange={(e) => setIsChecked(e.target.checked)} />
          <label htmlFor="id-radio1" className="flex-1">
            本人已閱讀並同意以上聲明，並在此同意 KM International (Asia) Co., Limited
            將其後提供的全部或部分資料(「資料」)給予環聯資訊有限公司以作不時為了驗證我的身份而與環聯資訊有限公司數據庫中的數據進行資料對比。
          </label>
        </div>
        <div className="flex justify-center my-4">
          <button
            onClick={() => {
              if (isChecked) {
                navigate('/application-form-1', { replace: true, state: { phone: location.state?.phone } });
              }
            }}
            disabled={!isChecked}
            className={`${isChecked ? 'bg-lightningYellow' : 'bg-mercury'} rounded-full text-white text-xl px-14 py-3`}
          >
            下一步
          </button>
        </div>
      </div>
    </div>
  );
}

export default ApplicationConsent;
