import React from 'react';

function Error({ children, content, onClick }) {
  return (
    <div className="p-4 fixed w-full z-30 top-0 left-0 flex items-center justify-center h-full" style={{ backgroundColor: '#0000004d' }}>
      <div className="border-4 border-primary p-4 bg-white w-full" onClick={onClick} style={{ maxWidth: 414 }}>
        <div className="text-center">
          <p className="text-gray">{content}</p>
        </div>
        {children}
      </div>
    </div>
  );
}

export default Error;
