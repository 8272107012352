import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Common, PopUpBox } from '../../components';
import PhoneSelfie from '../../assets/images/phone-selfie.png';
import LightBulb from '../../assets/images/light-bulb.svg';
import { getMobileOperatingSystem } from '../../utils/common';

import { actionAgreeCamera } from '../../store/application/actions';
import { actionTracking } from '../../store/common/actions';

function ApplicationSelfieCheck() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isVisibleSelfieTakePhoto, setIsVisibleSelfieTakePhoto] = useState(false);
  const [isVisibleInvalidToken, setIsVisibleInvalidToken] = useState(false);
  const [isVisibleBlockCamera, setIsVisibleBlockCamera] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      actionTracking({
        url: window.location.href,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!location.state?.status) {
      navigate('/');
    }
  }, [location.state?.status, navigate]);

  useEffect(() => {
    setIsVisibleSelfieTakePhoto(!!location.state?.showCamera)
  }, [location.state?.showCamera, navigate]);

  const degreeCamera = useCallback(async () => {
    const response = await dispatch(
      actionAgreeCamera({
        agree_cam: false,
      }),
    );
    setIsVisibleBlockCamera(true);
    if (response?.sts_code === 3) {
      // INVALID_TOKEN
      setIsVisibleInvalidToken(true);
    }
  }, [dispatch]);

  const agreeCamera = useCallback(async () => {
    const response = await dispatch(
      actionAgreeCamera({
        agree_cam: true,
      }),
    );
    if (response?.sts_code === 3) {
      // INVALID_TOKEN
      setIsVisibleInvalidToken(true);
    }
  }, [dispatch]);

  const requestPermissionCamera = () => {
    if (getMobileOperatingSystem() !== 'Android') {
      if (getMobileOperatingSystem() === 'iOS') {
        navigator.mediaDevices
          ?.getUserMedia({ video: true })
          .then(() => {
            agreeCamera();
          })
          .catch(() => {
            degreeCamera();
          });
      } else {
        navigator.permissions
          ?.query({ name: 'camera' })
          .then((permission) => {
            if (permission.state !== 'granted') {
              navigator.mediaDevices
                ?.getUserMedia({ video: true })
                .then(() => {
                  agreeCamera();
                })
                .catch(() => {
                  degreeCamera();
                });
            }
          })
          .catch(() => {
            degreeCamera();
          });
      }
    }
  }

  useEffect(() => {
    requestPermissionCamera()
  }, [agreeCamera, degreeCamera]);

  return (
    <div className="bg-white p-5">
      <Common.StepBar active={3} />
      {isVisibleBlockCamera && getMobileOperatingSystem() !== 'Android' && <PopUpBox.Error content="請允許相機權限以繼續貸款申請。" onClick={() => setIsVisibleBlockCamera(false)}>
        <div className="text-center my-3">
          <button onClick={() => setIsVisibleBlockCamera(false)} type="button" className="rounded-full text-white text-sm bg-lightningYellow px-14 py-2">
            確定
          </button>
        </div>
        </PopUpBox.Error>}
      {isVisibleInvalidToken && <PopUpBox.InvalidToken />}
      {isVisibleSelfieTakePhoto && <PopUpBox.SelfieTakePhoto setIsVisible={setIsVisibleSelfieTakePhoto} />}
      <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">本人自拍</p>
      <p className="text-gray text-center">
        正面面向手機
        <span className="text-primary">
          前置鏡頭
        </span>
      </p>
      <div className="flex justify-center">
        <img className='my-3' style={{ width: 249 }} src={PhoneSelfie} alt="" />
      </div>
      <div className="flex items-center text-lg mb-2">
        <img src={LightBulb} alt="" />
        <p className="text-gray underline mt-2">自拍小貼士</p>
      </div>
      <p className="text-gray ml-4">
        ・確保四周光線充足
        <br />
        ・與鏡頭保持適當距離
        <br />
        ・確保背景清晰
        <br />
        ・除去口罩
        <br />
        ・除去太陽眼鏡
        <br />
        ・除去帽子及所有頭飾
      </p>
      <div className="flex justify-center mt-9 mb-4">
        <button className="rounded-full text-white text-xl bg-lightningYellow px-14 py-3" onClick={() => setIsVisibleSelfieTakePhoto(true)}>
          開始拍攝
        </button>
      </div>
    </div>
  );
}

export default ApplicationSelfieCheck;
