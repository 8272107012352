import React, { useEffect } from 'react';

function EditInput(props) {
  const { setValue, register, title, value, editing, errors, field, onChange } = props;

  useEffect(() => {
    if (setValue) {
      setValue(field, value);
    }
  }, [field, setValue, value]);

  return (
    <>
      <div className={`flex items-center ${editing ? 'border-primary border-b' : ''}`}>
        <p className="text-gray w-1/2">{title}</p>
        {editing ? (
          <>
            <input {...register(field, { required: true, onChange: (e) => onChange(e) })} className="flex-1 pr-2 outline-none text-primary w-1/2" style={{ height: 36 }} />
          </>
        ) : (
          <p className="text-primary flex-1 whitespace-nowrap">{value}</p>
        )}
      </div>
      {errors?.[field] && errors?.[field]?.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
    </>
  );
}

export default EditInput;
