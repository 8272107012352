import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4'
import { useEffect } from "react";
function UsePageTracking() {
    const location = useLocation();
    ReactGA.initialize(process.env.REACT_APP_GA_CODE);
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
        
        // meta pixel
        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init('304462837829739', {}, {autoConfig: true, debug: true}) // facebookPixelId
                ReactPixel.pageView()
            })
    }, [location]);
}

export default UsePageTracking;