import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { Common, PopUpBox } from '../../components';
import { styles, options, numberWithNoCommas, sw2Alert, alphabetOnly } from '../../utils/common';

import { actionSubmitForm22 } from '../../store/application/actions';
import { actionAddressConfig, actionTracking } from '../../store/common/actions';

function ApplicationForm22() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    setValue,
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();
  const [isVisibleInvalidToken, setIsVisibleInvalidToken] = useState(false);
  // const [building, setBuilding] = useState('');
  const [estate, setEstate] = useState('');
  const [addressConfig, setAddressConfig] = useState(null);
  const [optionsDistrict, setOptionsDistrict] = useState([]);
  const [districtValue, setDistrictValue] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(
      actionTracking({
        url: window.location.href,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    getAddressConfig()
  }, []);

  const getAddressConfig = async () => {
    setLoading(true)
    const response = await dispatch(actionAddressConfig());
    console.log(response);
    if (response?.content) {
      setAddressConfig(response.content);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!location.state?.status) {
      navigate('/');
    }
  }, [location.state?.status, navigate]);

  const onSubmit = async (data) => {
    const district = optionsDistrict.find(t => t.value === districtValue?.value);
    const province = addressConfig.area.find(t => t.value === data?.area);
    let addr_line1 = '';
    if (data?.addr_line1_room) {
      addr_line1 = 'RM ' + (data?.addr_line1_room ?? '')
        .replace(new RegExp('ROOM', 'g'), '')
        .replace(new RegExp('RM', 'g'), '')
        .replace(new RegExp('FLAT', 'g'), '')
        .replace(new RegExp('FLT', 'g'), '')
        .replace(new RegExp('UNIT', 'g'), '').trim();
    }
    if (data?.addr_line1_floor) {
      addr_line1 += ' ' + (data?.addr_line1_floor ?? '')
        .replace(new RegExp('FLOOR', 'g'), '')
        .replace(new RegExp('F', 'g'), '')
        .replace(new RegExp("/F", 'g'), '').trim()
        + '/F';
    }
    if (data?.addr_line1_block) {
      addr_line1 += ' BLK ' + data?.addr_line1_block
        .replace(new RegExp('BLK', 'g'), '')
        .replace(new RegExp('BLOCK', 'g'), '')
        .replace(new RegExp("BL", 'g'), '').trim();
    }
    console.log('addr_line1', addr_line1)
    if (addr_line1.length > 49 || data?.estate?.length > 49) {
      return sw2Alert('輸入的地址不可以超過50個字元');
    };
    let obj = {
      area: data?.area,
      city_id: district?.city_id,
      province_id: province?.province_id,
      city_id_tu: district?.tu_value, 
      addr_line1: addr_line1,
      addr_line1_room: data?.addr_line1_room,
      addr_line1_floor: data?.addr_line1_floor,
      addr_line1_block: data?.addr_line1_block,
      addr_line2: data?.estate,
      property_type: data?.property_type,
      number_living_with: data?.number_living_with,
      property_owner: data?.property_owner,
      monthly_rent: numberWithNoCommas(data?.monthly_rent),
    };
    const response = await dispatch(actionSubmitForm22(obj));
    if (response?.sts_code === 0) {
      obj['district_value'] = districtValue?.value
      obj['address_config'] = addressConfig;
      navigate('/application-confirm-data', { replace: true, state: { status: true, form1: location.state?.form1, form2: location.state?.form2, form3: obj } });
    } else if (response?.sts_code === 3) {
      // INVALID_TOKEN
      setIsVisibleInvalidToken(true);
    }
  };

  const beforeunload = (e) => {
    const event = e || window.event;
    if (event) {
      event.returnValue = 'Sure?';
    }
    return 'Sure?';
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('beforeunload', beforeunload);

    return () => {
      window.removeEventListener('beforeunload', beforeunload);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isVisibleInvalidToken && <PopUpBox.InvalidToken />}
      <Common.Loading isVisible={isSubmitting || isLoading} />
      <div className="bg-white p-5">
        <Common.StepBar active={1} />
        <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">住址資料</p>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">住宅地址</p>
          <div className="mb-5">
            <p className="text-gray">區域</p>
            <div className="border-b border-primary relative">
              <Select
                placeholder="- 請選擇 -"
                className="w-full"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                })}
                styles={styles}
                {...register('area', { required: true })}
                onChange={(e) => {
                  setValue('area', e.value);
                  setError('area', null);
                  setDistrictValue(null);
                  setValue('district', null)
                  switch (e.value) {
                    case 'HK':
                      setOptionsDistrict(addressConfig.district_hk);
                      break;
                    case 'KLN':
                      setOptionsDistrict(addressConfig.district_kw);
                      break;
                    case 'NT':
                      setOptionsDistrict(addressConfig.district_nt);
                      break;
                    default:
                      break;
                  }
                }}
                options={options.area}
                isSearchable={false}
              />
            </div>
            {errors.area && errors.area.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
          </div>
          <div className="mb-5">
            <p className="text-gray">地區</p>
            <div className="border-b border-primary relative">
              <Select
                placeholder="- 請選擇 -"
                className="w-full"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                })}
                styles={styles}
                {...register('district', { required: true })}
                value={districtValue}
                onChange={(e) => {
                  setDistrictValue(e)
                  setValue('district', e?.value)
                  setError('district', null)
                }}
                options={optionsDistrict}
                isSearchable={false}
              />
            </div>
            {errors.district && errors.district.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
          </div>
          <div className="mb-5 flex items-center">
            <div className="flex flex-col pr-1">
              <p className="text-gray mb-2">單位</p>
              <div className="flex items-center border-b border-primary">
                <input
                  {...register('addr_line1_room', { required: true })}
                  onChange={(e) => {
                    const value = alphabetOnly((e?.target?.value || '')).toUpperCase()
                    setValue('addr_line1_room', value)
                  }}
                  className="flex-1 px-1 outline-none text-primary w-full"
                  placeholder="Room/Flat/Unit"
                />
              </div>
              {errors.addr_line1_room && errors.addr_line1_room.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
            </div>
            <div className="flex flex-col px-1">
              <p className="text-gray mb-2">樓層</p>
              <div className="flex items-center border-b border-primary">
                <input
                  {...register('addr_line1_floor', { required: false })}
                  onChange={(e) => {
                    const value = alphabetOnly((e?.target?.value || '')).toUpperCase()
                    setValue('addr_line1_floor', value)
                  }}
                  className="flex-1 px-1 outline-none text-primary w-full"
                  placeholder="Floor"
                />
              </div>
              {errors.addr_line1_floor && errors.addr_line1_floor.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
            </div>
            <div className="flex flex-col px-1">
              <p className="text-gray mb-2">座數/大廈</p>
              <div className="flex items-center border-b border-primary">
                <input
                  {...register('addr_line1_block', { required: false })}
                  onChange={(e) => {
                    const value = alphabetOnly((e?.target?.value || '')).toUpperCase()
                    setValue('addr_line1_block', value)
                  }}
                  className="flex-1 px-1 outline-none text-primary w-full"
                  placeholder="Block"
                />
              </div>
              {errors.addr_line1_block && errors.addr_line1_block.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
            </div>
          </div>
          <div className="mb-5">
            <p className="text-gray mb-2">屋苑/街道</p>
            <div className="flex items-center border-b border-primary">
              <input
                {...register('estate', { required: true })}
                value={estate}
                onChange={(e) => {
                  const value = alphabetOnly((e?.target?.value || '')).toUpperCase()
                  setEstate(value)
                }}
                className="flex-1 px-2 outline-none text-primary w-full"
                placeholder="(E.g. HAPPY EST)"
              />
            </div>
            {errors.estate && errors.estate.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
          </div>
        </div>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">房屋類型</p>
          <div className="border-b border-primary relative">
            <Select
              placeholder="- 請選擇 -"
              className="w-full"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              styles={styles}
              {...register('property_type', { required: true })}
              onChange={(e) => {
                setValue('property_type', e.value);
                setError('property_type', null);
              }}
              options={options.property_type}
              isSearchable={false}
            />
          </div>
          {errors.property_type && errors.property_type.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
        </div>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">同住人數</p>
          <div className="border-b border-primary relative">
            <Select
              placeholder="- 請選擇 -"
              className="w-full"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              styles={styles}
              {...register('number_living_with', { required: true })}
              onChange={(e) => {
                setValue('number_living_with', e.value);
                setError('number_living_with', null);
              }}
              options={options.number_living_with}
              isSearchable={false}
            />
          </div>
          {errors.number_living_with && errors.number_living_with.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
        </div>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">持有人</p>
          <div className="border-b border-primary relative">
            <Select
              placeholder="- 請選擇 -"
              className="w-full"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              styles={styles}
              {...register('property_owner', { required: true })}
              onChange={(e) => {
                setValue('property_owner', e.value);
                setError('property_owner', null);
              }}
              options={options.property_owner}
              isSearchable={false}
            />
          </div>
          {errors.property_owner && errors.property_owner.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
        </div>
        <div className="mb-14">
          <p className="text-xl text-gray mb-2">每月房租</p>
          <Common.InputMoney zeroAllow={true} placeholder=" " register={register} title="monthly_rent" setError={setError} errors={errors} />
        </div>
        <div className="flex justify-center mb-4">
          <button disabled={isSubmitting} type="submit" className={`rounded-full text-white text-xl px-14 py-3 ${isSubmitting ? 'bg-mercury' : 'bg-lightningYellow'}`}>
            下一步
          </button>
        </div>
      </div>
    </form>
  );
}

export default ApplicationForm22;
