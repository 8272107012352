import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

function ConfirmEsignCheck({ }) {

    const navigate = useNavigate()
    const location = useLocation()
    const [enableEsign, setEnableEsign] = useState(false)

    useEffect(() => {
        if (!location.state?.status) {
            navigate('/');
        }
    }, [location.state?.status, navigate]);

    const onOpenEsignTab = () => {
        setEnableEsign(!!location.state?.response?.content?.url)
    }

    // listen data from iframe
    useEffect(() => {
        window.addEventListener('message', (e) => {
            console.log('Received data from child iframe:', e);
            let url = e?.data ?? '';
            const urlRegex = /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
            if (url && typeof url === 'string') {
                if (url.substr(-1) === '/') {
                    url = url.substring(0, url.length - 1);
                    if (url.match(urlRegex)) {
                        window.location.href = url;
                    }
                } else if (url.match(urlRegex)) {
                    window.location.href = url;
                }
            }
        })
        return () => {
            window.removeEventListener('message', null);
        }
    }, [])

    return (
        <div className="bg-white p-5">
            {
                enableEsign ? <>
                    <div>
                        <iframe id="ifrmEsign" className="w-full" style={{ height: 560 }} src={location.state?.response?.content?.url} title="crediAI" />
                    </div>
                </> :
                    <>
                        <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">簽署貸款合約</p>
                        <p className="text-gray">閣下將簽署以下貸款合約文件：</p>
                        <p className="text-gray">1. 外債狀況聲明書</p>
                        <p className="text-gray">2. 貸款合約</p>
                        <p className="text-primary my-4 p-2">＊請注意：簽署文件時必須使用一致的簽署＊</p>
                        <div className="flex justify-center mt-9 mb-4">
                            <button onClick={onOpenEsignTab} className="rounded-full text-white text-xl bg-lightningYellow px-14 py-3">
                                確定
                            </button>
                        </div>
                    </>
            }
        </div>
    )
}

export default ConfirmEsignCheck;