import React from 'react';
import { useNavigate } from 'react-router-dom';

function InvalidToken() {
  const navigate = useNavigate();
  return (
    <div className="p-4 fixed w-full z-10 top-0 left-0 flex items-center justify-center h-full" style={{ backgroundColor: '#0000004d' }}>
      <div className="border-4 border-primary p-4 bg-white w-full" onClick={() => navigate('/')} style={{ maxWidth: 414 }}>
        <div className="text-center">
          <p className="text-gray">登入逾時，請重新登入。</p>
        </div>
      </div>
    </div>
  );
}

export default InvalidToken;
