import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Status from './components/Status';
import UploadDoc from './components/UploadDoc';

import { actionLoanCheckStatus, actionLoanRefreshToken } from '../../store/loan/actions';
import { actionTracking } from '../../store/common/actions';
import { onTokenExpired } from '../../utils/common'


function CheckLoanStatusStage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [result, setResult] = useState();
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    dispatch(
      actionTracking({
        url: window.location.href,
      }),
    );
  }, [dispatch]);

  // check refresh token
  useEffect(() => {
    // window.addEventListener('beforeunload', onRefreshToken);
    // return () => {
    //   window.removeEventListener('beforeunload', onRefreshToken);
    // };
    onTokenExpired()
  }, [])

  const onRefreshToken = async () => {
    const refresh_token = localStorage.getItem('access_token')
    if(!refresh_token) return false
    const response = await dispatch(
      actionLoanRefreshToken({ token: refresh_token }),
    );
    if (response?.sts_code === 0) {
      await localStorage.setItem('access_token', response?.content?.access_token)
    } else {
      localStorage.removeItem('access_token')
      window.location.href = process.env.REACT_APP_HOME_URL
    }
    return false
  }

  useEffect(() => {
    if (!location.state?.requestId) {
      navigate('/');
    }
  }, [location.state?.requestId, navigate]);

  const loanCheckStatus = useCallback(async () => {
    const response = await dispatch(
      actionLoanCheckStatus({
        request_id: location.state?.requestId,
        day: location.state?.day,
        month: location.state?.month,
        year: location.state?.year,
      }),
    );
    if (response?.sts_code !== 0) {
      window.location.href = process.env.REACT_APP_HOME_URL
    } else {
      setResult(response);
    }
  }, [dispatch, location.state?.requestId, location.state?.day, location.state?.month, location.state?.year]);

  useEffect(() => {
    loanCheckStatus();
  }, [loanCheckStatus]);

  return (
    <>
      <div className="p-5">
        <p className="text-gray">
          您好，{`${result?.content?.first_name || ''} ${result?.content?.last_name || ''}`} ({location.state?.requestId})
        </p>
        <p className="text-gray mb-4">登入時間：{result?.content?.create_time}</p>
        <p className="flex-1 text-2xl text-primary font-semibold text-center mb-4">貸款申請追蹤</p>
        <div className="flex justify-center mb-4">
          <p className={`text-gray font-semibold text-center cursor-pointer mx-5 ${activeTab === 1 ? 'border-b-2 border-primary' : ''}`} onClick={() => setActiveTab(1)}>
            申請狀態
          </p>
          <p className={`text-gray font-semibold text-center cursor-pointer mx-5 ${activeTab === 2 ? 'border-b-2 border-primary' : ''}`} onClick={() => setActiveTab(2)}>
            上載文件
          </p>
        </div>
      </div>
      {activeTab === 1 && <Status result={Number(result?.content?.loan_state_code || 1)} setActiveTab={setActiveTab} />}
      {activeTab === 2 && <UploadDoc setActiveTab={setActiveTab} requestId={location.state?.requestId} />}
    </>
  );
}

export default CheckLoanStatusStage;
