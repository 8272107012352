import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Common } from '../../components';
import PhoneDone from '../../assets/images/phone-done.svg';

import { actionTracking } from '../../store/common/actions';

function ApplicationComplete() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(actionTracking(window.location.href));
  }, [dispatch]);

  useEffect(() => {
    if (!location.state?.status) {
      navigate('/');
    }
  }, [location.state?.status, navigate]);

  useEffect(() => {
    const disableBack = () => {
      navigate(1);
    };
    window.addEventListener('popstate', disableBack);
    return () => {
      window.removeEventListener('popstate', disableBack);
    };
  }, [navigate]);

  const goToRoot = (url) => {
    navigate(url, { state: { status: false } });
    localStorage.removeItem('access_token');
  };

  console.info(location.state?.content);

  return (
    <div className="bg-white p-5">
      <Common.StepBar active={4} />
      <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">申請完成</p>
      <div className="flex justify-center">
        <img src={PhoneDone} alt="" />
      </div>
      <p className="text-2xl text-primary mb-4 text-center">貸款申請成功</p>
      <p className="text-gray text-center">以下是閣下的申請編號</p>
      <div className="py-3 bg-primary text-white text-center text-xl mt-4">
        <p>{location.state?.content?.request_id}</p>
      </div>
      <p className="text-gray">申請編號亦會透過手機短訊(SMS)發送到閣下所登記之電話號碼，方便日後查閱申請進度。(接收SMS可能需時數分鐘，請耐心等候。)</p>
      <p className="text-gray mt-4">感謝閣下的申請，我們會盡快處理閣下的申請及與閣下聯絡。如有任何疑問，歡迎於辦公時間內致電 3700 4488。</p>
      <p className="text-primary text-xl mt-4 text-center">立即上載所需文件<br />加快申請進度！</p>
      <div className="flex justify-center mt-9 mb-4">
        <button onClick={() => goToRoot('/?open=check_status')} className="rounded-full text-white text-xl bg-lightningYellow px-14 py-3">
          上載文件
        </button>
      </div>
      <div className="flex justify-center mb-4">
        <button onClick={() => goToRoot('/')} className="rounded-full text-white text-xl bg-alto px-14 py-3">
          返回主頁
        </button>
      </div>
    </div>
  );
}

export default ApplicationComplete;
