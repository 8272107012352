import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { styles, renderRange } from '../../../utils/common';

function InputDate(props) {
  const { register, errors, getValues, setValue, setError, selectedDate } = props;
  const years = renderRange(moment().subtract(59, 'years').format('YYYY'), moment().subtract(18, 'years').format('YYYY'), true)
  const months = renderRange(1, 12, false)
  const days = renderRange(1, 31, false)
  const [defaultYear, setDefaultYear] = useState(null);
  const [defaultMonth, setDefaultMonth] = useState(null);
  const [defaultDay, setDefaultDay] = useState(null);

  useEffect(() => {
    if (selectedDate) {
      const arr = selectedDate.split('/')
      setDefaultYear(years.find(t => t.value === arr[0]))
      setDefaultMonth(months.find(t => t.value === Number(arr[1]) + ''))
      setDefaultDay(days.find(t => t.value === Number(arr[2]) + ''))

      setValue('date_of_birth3',arr[0] + '')
      setValue('date_of_birth2', Number(arr[1]) + '')
      setValue('date_of_birth1', Number(arr[2]) + '')
    }
  }, [selectedDate])

  const checkDate = (dd, mm, yyyy) => {
    if (dd && mm && yyyy) {
      const newDate = moment(`${yyyy}/${mm}/${dd}`, 'YYYY/MM/DD');
      return newDate.isValid();
    }
    return true;
  };

  return (
    <>
      <div className="flex items-center border-b border-primary">
        <Select
          placeholder="年"
          className="flex-1 pl-1 outline-none text-primary w-full text-center"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
          })}
          styles={styles}
          value={defaultYear}
          {...register('date_of_birth3', { required: true })}
          onChange={(e) => {
            setDefaultYear(years.find(t => t.value === e.value))
            setValue('date_of_birth3', e.value);
            setError('date_of_birth3', null);
          }}
          options={years}
          isSearchable={false}
        />
        <Select
          placeholder="月"
          className="flex-1 pl-1 outline-none text-primary w-full text-center border-l border-r border-primary"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
          })}
          value={defaultMonth}
          styles={styles}
          {...register('date_of_birth2', { required: true })}
          onChange={(e) => {
            setDefaultMonth(months.find(t => t.value === e.value))
            setValue('date_of_birth2', e.value);
            setError('date_of_birth2', null);
          }}
          options={months}
          isSearchable={false}
        />
        <Select
          placeholder="日"
          className="flex-1 pl-1 outline-none text-primary w-full text-center"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
          })}
          value={defaultDay}
          styles={styles}
          {...register('date_of_birth1', { required: true })}
          onChange={(e) => {
            setDefaultDay(days.find(t => t.value === e.value))
            setValue('date_of_birth1', e.value);
            setError('date_of_birth1', null);
          }}
          options={days}
          isSearchable={false}
        />
      </div>
      {(errors?.date_of_birth1 || errors?.date_of_birth2 || errors?.date_of_birth3) &&
        (errors?.date_of_birth1?.type === 'required' || errors?.date_of_birth2?.type === 'required' || errors?.date_of_birth3?.type === 'required') && (
          <span className="text-10 text-red-500">*必填</span>
        )}
      {!checkDate(getValues('date_of_birth1'), getValues('date_of_birth2'), getValues('date_of_birth3')) &&
        (!(errors?.date_of_birth1 || errors?.date_of_birth2 || errors?.date_of_birth3) ||
          !(errors?.date_of_birth1?.type === 'required' || errors?.date_of_birth2?.type === 'required' || errors?.date_of_birth3?.type === 'required')) && (
          <span className="text-10 text-red-500">*无效的</span>
        )}
    </>
  );
}

export default InputDate;
