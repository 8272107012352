import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Close from '../../../assets/images/close.svg';
import Loading from '../../../components/Common/Loading';
import InputDate from '../../../components/Common/InputDate';
import Error from '../../../components/PopUpBox/Error';

import { actionLoanSendOTP } from '../../../store/loan/actions';

function LoginBox() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();
  const [isVisibleError, setIsVisibleError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const showMessage = (message) => {
    setErrorMessage(message);
    setIsVisibleError(true);
  }

  const onSubmit = async (data) => {
    const response = await dispatch(
      actionLoanSendOTP({
        request_id: `R8000000${data.request_id}`,
        day: data.date_of_birth1,
        month: data.date_of_birth2,
        year: data.date_of_birth3,
      }),
    );
    if (response?.sts_code === 0) {
      navigate('/check-loan-status-otp', {
        state: {
          requestId: `R8000000${data.request_id}`,
          day: data.date_of_birth1,
          month: data.date_of_birth2,
          year: data.date_of_birth3,
        },
      });
    } else if (response?.sts_code === 12 || response?.sts_code === 20) {
      const message = response?.content?.valid_date ? '驗證碼發送次數達上限，請稍後再嘗試。' : '閣下所輸入的資料不正確，請重新輸入。';
      showMessage(message);
    }
  };
  return (
    <>
      {isVisibleError && <Error content={errorMessage} onClick={() => setIsVisibleError(false)}>
        <div className="text-center my-3">
          <button onClick={() => setIsVisibleError(false)} type="button" className="rounded-full text-white text-sm bg-lightningYellow px-10 py-2">
            確認
          </button>
        </div>
      </Error>}
      <Loading isVisible={isSubmitting} />
      <div className="p-10 pt-32 fixed w-full z-10 top-0 left-0 flex items-start justify-center h-full" style={{ backgroundColor: '#0000004d' }}>
        <div className="border-4 w-full border-primary p-4 bg-white" style={{ maxWidth: 414 }}>
          <div className="flex justify-between items-center">
            <p className="flex-1 text-xl text-primary font-semibold border-b-4 border-primary mr-5">貸款追蹤</p>
            <div className="p-2 pr-0 cursor-pointer" onClick={() => (window.location.href = process.env.REACT_APP_HOME_URL)}>
              <img src={Close} alt="" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <p className="text-sm text-gray mt-4 mb-1">
              申請編號 <span className="text-10">(請輸入申請編號尾6位數字)</span>
            </p>
            <div className="relative">
              <div style={{ marginTop: 2 }} className="absolute top-1/2 left-2 -translate-y-1/2 text-gray">R8000000</div>
              <input
                type={'number'}
                inputMode={'numeric'}
                autoComplete="off"
                {...register('request_id', { required: true })}
                className="border border-primary text-primary rounded-md w-full h-10 outline-none pr-2"
                placeholder=""
                style={{ marginBottom: -5, paddingLeft: 86 }}
                maxLength={6}
              />
            </div>
            {errors.request_id && errors.request_id.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
            <p className="text-sm text-gray mt-4 mb-1">出生日期</p>
            <InputDate register={register} errors={errors} getValues={getValues} setValue={setValue} setError={setError} />
            <div className="flex justify-center my-4">
              <button type="submit" className="rounded-full text-white text-sm bg-lightningYellow px-14 py-2">
                確定
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default LoginBox;
