import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MobileSuccess from '../../assets/images/mobile-success.svg';

import { actionTracking } from '../../store/common/actions';

function ApplicationOTPSuccess() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      actionTracking({
        url: window.location.href,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!location.state?.path) {
      navigate('/');
    }
  }, [location.state?.path, navigate]);

  return (
    <div className="bg-white p-5">
      <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">本人身份證</p>
      <div className="flex justify-center mb-5">
        <img src={MobileSuccess} alt="" />
      </div>
      <p className="text-2xl text-gray text-center">手機驗證成功</p>
      <div className="flex justify-center mt-9 mb-4">
        <button
          onClick={() => navigate(location.state?.path, { replace: true, state: { phone: location.state?.phone } })}
          className="rounded-full text-white text-xl bg-lightningYellow px-14 py-3"
        >
          下一步
        </button>
      </div>
    </div>
  );
}

export default ApplicationOTPSuccess;
