import React, { useState, useRef, useEffect } from 'react';
import { Camera } from 'react-camera-pro';
import Webcam from 'react-webcam';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { detectMob } from '../../../utils/common';
import LightBulb from '../../../assets/images/light-bulb.svg';
import Loading from '../../../components/Common/Loading';
import InvalidToken from '../../../components/PopUpBox/InvalidToken';

import { actionApplyLater, actionTsoOcrDa, actionTuHealthCheck } from '../../../store/application/actions';
import { actionApplicationIP } from '../../../store/common/actions';

const COUNT_TIMES = 4;

function IDCardTakePhoto({ setIsVisible }) {
  const camera = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const [countTimes, setCountTimes] = useState(COUNT_TIMES);
  const [isVisibleInvalidToken, setIsVisibleInvalidToken] = useState(false);

  useEffect(() => {
    if (location.state?.count || location.state?.count === 0) {
      setCountTimes(location.state?.count);
    }
  }, [location.state?.count]);

  const getUtmParams = async () => {
    const responseIP = await dispatch(actionApplicationIP());
    let data = {};
    const utm_medium = sessionStorage.getItem('utm_medium');
    const utm_source = sessionStorage.getItem('utm_source');
    const utm_campaign = sessionStorage.getItem('utm_campaign');
    const utm_id = sessionStorage.getItem('utm_id');
    const utm_term = sessionStorage.getItem('utm_term');
    const utm_content = sessionStorage.getItem('utm_content');
    const session_id = localStorage.getItem('session_id');
    data = {
      session_id,
      utm_medium,
      utm_source,
      utm_campaign,
      utm_id,
      utm_term,
      utm_content,
      ip: responseIP?.ip ?? '',
      access_query: `utm_medium=${utm_medium ?? ''}&utm_source=${utm_source ?? ''}&utm_campaign=${utm_campaign ?? ''}&utm_id=${utm_id ?? ''}&utm_term=${utm_term ?? ''}&utm_content=${utm_content ?? ''}`,
    }
    return data;
  }

  // try apply request later
  async function apply() {
    const utm_params = await getUtmParams();
    dispatch(actionApplyLater({
      utm_params,
      step: 'HKID'
    }))
  }

  const onSubmit = async () => {

    if (countTimes === COUNT_TIMES) {
      const health_check_res = await dispatch(actionTuHealthCheck());
      console.log('health_check_res', health_check_res)
    }

    const response = await dispatch(
      actionTsoOcrDa({
        photo: image,
        flow: 1
      }),
    );

    if (response?.sts_code === 3) {
      // INVALID_TOKEN
      setIsVisibleInvalidToken(true);
      return;
    }
    if (countTimes === 0) {
      await apply()
      navigate('/application/confirm/complete', { replace: true, state: { status: true, completed: false, content: '' } });
    } else {
      navigate('/application-id-check-result', { replace: true, state: { status: true, result: countTimes === 0 ? 0 : response?.sts_code, count: countTimes } });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isVisibleInvalidToken && <InvalidToken />}
      <Loading isVisible={isSubmitting} />
      <div className="fixed w-full z-30 top-0 left-1/2 h-full bg-white" style={{ maxWidth: 414, transform: 'translateX(-50%)' }}>
        <p className="p-4 text-gray text-center">{!image ? '請拍攝閣下的香港永久性居民身份證' : '請確認閣下所拍攝的照片'}</p>
        <div className="relative">
          {!image ? (
            <>
              {detectMob() ? (
                <Camera
                  ref={camera}
                  facingMode="environment"
                  aspectRatio={4 / 3}
                  errorMessages={{
                    noCameraAccessible: '請允許相機權限以繼續貸款申請。',
                    permissionDenied: '没有权限。请刷新并授予相机权限。',
                    switchCamera: '无法将摄像头切换到其他摄像头，因为只能访问一个视频设备。',
                    canvas: '不支持画布。',
                  }}
                />
              ) : (
                <Webcam
                  ref={camera}
                  audio={false}
                  height={1080}
                  screenshotFormat="image/jpeg"
                  width={1920}
                  minScreenshotHeight={245}
                  videoConstraints={{
                    facingMode: 'environment',
                  }}
                />
              )}
            </>
          ) : (
            <img src={image} alt="" />
          )}
          <div className="absolute top-5 left-5 w-10 h-10 border-t-4 border-l-4 border-white" />
          <div className="absolute top-5 right-5 w-10 h-10 border-t-4 border-r-4 border-white" />
          <div className="absolute bottom-5 left-5 w-10 h-10 border-b-4 border-l-4 border-white" />
          <div className="absolute bottom-5 right-5 w-10 h-10 border-b-4 border-r-4 border-white" />
        </div>
        {!image ? (
          <>
            <div className="p-4 pb-0 flex items-center text-lg mb-2">
              <img src={LightBulb} alt="" className="cursor-pointer" />
              <p className="text-gray underline mt-2">拍攝身份證小貼士</p>
            </div>
            <p className="p-4 pt-0 text-gray ml-4">
              ・移除身份證透明卡套
              <br />
              ・確保四周光線充足
              <br />
              ・避免身份證資料模糊/反光/有陰影
              <br />
              ・應在與有強烈對比的平面背景上拍攝
              <br />
              ・避免遮擋身份證正面任何位置
            </p>
            <div className="flex justify-center">
              <div
                onClick={() => {
                  if (detectMob()) {
                    setImage(camera.current.takePhoto());
                  } else {
                    setImage(camera.current.getScreenshot());
                  }
                }}
                className="w-12 h-12 rounded-full bg-mercury absolute bottom-10 z-10"
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center mt-9 mb-4">
              <button style={{ width: 160 }} disabled={isSubmitting} type="submit" className={`rounded-full text-white text-xl px-14 py-3 ${isSubmitting ? 'bg-mercury' : 'bg-lightningYellow'}`}>
                確定
              </button>
            </div>
            <div className="flex justify-center">
              <button
                type="button"
                className="rounded-full text-white text-xl bg-alto px-10 py-3"
                onClick={() => {
                  //setIsVisible(false);
                  setImage(null);
                }}
              >
                重新拍攝
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  );
}

export default IDCardTakePhoto;
