/* eslint-disable import/no-anonymous-default-export */
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import StepBar from './StepBar';
import Upload from './Upload';
import EditInputMoney from './EditInputMoney';
import EditInput from './EditInput';
import EditSelect from './EditSelect';
import Loading from './Loading';
import InputMoney from './InputMoney';
import InputDate from './InputDate';
import UsePageTracking from './UsePageTracking'

export default {
  AppFooter,
  AppHeader,
  StepBar,
  Upload,
  EditInput,
  EditInputMoney,
  EditSelect,
  Loading,
  InputMoney,
  InputDate,
  UsePageTracking,
};
