import React, { useEffect } from 'react';
import Select from 'react-select';
import { styles } from '../../../utils/common';

function EditSelect(props) {
  const { register, title, value, editing, errors, field, options, setValue, setError, onChange, valueEnabled } = props;
  useEffect(() => {
    if (setValue) {
      setValue(field, value);
    }
  }, [field, setValue, value]);
  return (
    <>
      <div className={`flex items-center ${editing ? 'border-primary border-b' : ''}`}>
        <p className="text-gray w-1/2">{title}</p>
        {editing ? (
          <>
            <div className="relative w-1/2">
              <>
                {
                  valueEnabled ? 
                    <Select
                      placeholder="- 請選擇 -"
                      className="w-full"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                      })}
                      styles={{
                        ...styles,
                        valueContainer: (base) => ({
                          ...base,
                          paddingLeft: 0,
                        }),
                      }}
                      defaultValue={value ? options.find((option) => option?.value === value) : null}
                      value={value ? options.find((option) => option?.value === value) : null}
                      {...register(field, { required: true })}
                      onChange={(e) => {
                        setValue(field, e.value);
                        setError(field, null);
                        onChange && onChange(e);
                      }}
                      options={options}
                      isSearchable={false}
                    /> : 
                    <Select
                      placeholder="- 請選擇 -"
                      className="w-full"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                      })}
                      styles={{
                        ...styles,
                        valueContainer: (base) => ({
                          ...base,
                          paddingLeft: 0,
                        }),
                      }}
                      defaultValue={value ? options.find((option) => option?.value === value) : null}
                      {...register(field, { required: true })}
                      onChange={(e) => {
                        setValue(field, e.value);
                        setError(field, null);
                        onChange && onChange(e);
                      }}
                      options={options}
                      isSearchable={false}
                    />
                 }
              </>
            </div>
          </>
        ) : (
          <p className="text-primary flex-1">{options?.find((i) => i?.value === value)?.label}</p>
        )}
      </div>
      {errors?.[field] && errors?.[field]?.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
    </>
  );
}

export default EditSelect;
