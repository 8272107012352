import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import validid from 'validid';
import Select from 'react-select';
import { Common, PopUpBox } from '../../components';
import { styles, options, numberWithNoCommas, detectMob, pad, capitalizeName, alphabetOnly } from '../../utils/common';

import { actionGetStateDoc, actionSubmitForm1 } from '../../store/application/actions';
import { actionApplicationIP, actionTracking } from '../../store/common/actions';
import moment from 'moment';

function ApplicationForm1() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();
  const [gender, setGender] = useState(1);
  const [isVisibleInvalidToken, setIsVisibleInvalidToken] = useState(false);
  const [hkid2, setHkid2] = useState('');
  const [checkBoxTime, setCheckBoxTime] = useState({})

  useEffect(() => {
    if (!location.state?.phone) {
      navigate('/');
    }
  }, [location.state?.phone, navigate]);

  const onSubmit = async (data) => {
    // check hkid valid
    if (!checkId(data?.hkid1, data?.hkid2, data?.hkid3)) return;
    const responseDoc = await dispatch(actionGetStateDoc());
    const agree_doc = (responseDoc?.content ?? []).map(doc => {
      return {
        doc_type: doc.doc_type,
        doc_version: doc.doc_ver,
      }
    })
    const responseIP = await dispatch(actionApplicationIP());
    const obj = {
      loan_amount: numberWithNoCommas(data?.loan_amount),
      loan_purpose: data?.loan_purpose,
      last_name: data?.last_name,
      first_name: data?.first_name,
      cust_gender: `0${gender}`,
      hkid: `${data?.hkid1}${data?.hkid2}${data?.hkid3}`,
      date_of_birth: `${data?.date_of_birth3}${pad(data?.date_of_birth2)}${pad(data?.date_of_birth1)}`,
      phone_number: data?.phone_number,
      email: data?.email,
      education: data?.education,
      marriage_status: data?.marriage_status,
      application_device: detectMob() ? 2 : 1,
      application_ip: responseIP?.ip,
      application_browser: window.navigator.userAgent,
      hkid_array: [data?.hkid1, data?.hkid2, data?.hkid3],
      check_box_1: data?.checkbox1,
      check_box_2: data?.checkbox2,
      check_box_1_time: moment(checkBoxTime?.check_box_1_time).format('YYYY-MM-DD HH:mm:ss'),
      check_box_2_time: moment(checkBoxTime?.check_box_2_time).format('YYYY-MM-DD HH:mm:ss'),
      agree_doc: JSON.stringify(agree_doc)
    };
    const response = await dispatch(actionSubmitForm1(obj));
    if (response?.sts_code === 0) {
      navigate('/application-form-2-1', { replace: true, state: { status: true, form1: obj } });
    } else if (response?.sts_code === 3) {
      // INVALID_TOKEN
      setIsVisibleInvalidToken(true);
    }
  };

  useEffect(() => {
    dispatch(
      actionTracking({
        url: window.location.href,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    setValue('phone_number', location.state?.phone);
  }, [location.state?.phone, setValue]);

  const checkId = (hkid1, hkid2, hkid3) => {
    if (hkid1 && hkid2 && hkid3) {
      return validid.hkid(`${hkid1}${hkid2}/${hkid3}`);
    }
    return true;
  };

  const beforeunload = (e) => {
    const event = e || window.event;
    if (event) {
      event.returnValue = 'Sure?';
    }
    return 'Sure?';
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('beforeunload', beforeunload);

    return () => {
      window.removeEventListener('beforeunload', beforeunload);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isVisibleInvalidToken && <PopUpBox.InvalidToken />}
      <Common.Loading isVisible={isSubmitting} />
      <div className="bg-white p-5">
        <Common.StepBar active={1} />
        <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">申請貸款</p>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">貸款金額</p>
          <Common.InputMoney register={register} title="loan_amount" setError={setError} errors={errors} />
        </div>
        <div className="mb-9">
          <p className="text-xl text-gray mb-2">貸款目的</p>
          <div className="border-b border-primary relative">
            <Select
              placeholder="- 請選擇 -"
              className="w-full"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              styles={styles}
              {...register('loan_purpose', { required: true })}
              onChange={(e) => {
                setValue('loan_purpose', e.value);
                setError('loan_purpose', null);
              }}
              options={options.loan_purpose}
              isSearchable={false}
            />
          </div>
          {errors?.loan_purpose && errors?.loan_purpose?.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
        </div>
        <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">申請人資料</p>
        <div className="mb-6">
          <p className="text-xl text-gray">英文姓名</p>
          <p className="text-xs text-gray1 mb-2">需與身份證上的名字相同</p>
          <div className="flex border-b border-primary mb-2">
            <p className="text-gray1">姓氏：</p>
            <input {...register('last_name', {
              required: true,
              onChange: (e) => {
                const value = alphabetOnly(e?.target?.value + '')
                setValue('last_name', value.toUpperCase())
              }
            })} className="flex-1 px-2 outline-none text-primary" placeholder="" />
          </div>
          {errors?.last_name && errors?.last_name?.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
          <div className="flex border-b border-primary">
            <p className="text-gray1">名字：</p>
            <input {...register('first_name', {
              required: true,
              onChange: (e) => {
                const value = alphabetOnly(e?.target?.value + '')
                setValue('first_name', capitalizeName(value))
              }
            })} className="flex-1 px-2 outline-none text-primary" placeholder="" />
          </div>
          {errors?.first_name && errors?.first_name?.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
        </div>
        <div className="flex items-center justify-center mb-6">
          <button
            type="button"
            onClick={() => {
              setGender(1);
              setValue('gender', 1);
            }}
            className={`rounded-full text-xl border border-primary px-10 py-2 mr-3 ${gender === 1 ? 'bg-primary text-white' : ' text-primary'}`}
          >
            先生
          </button>
          <button
            type="button"
            onClick={() => {
              setGender(2);
              setValue('gender', 2);
            }}
            className={`rounded-full text-xl border border-primary px-10 py-2 ${gender === 2 ? 'bg-primary text-white' : 'text-primary'}`}
          >
            小姐
          </button>
        </div>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">香港身份證號碼</p>
          <div className="flex items-center border-b border-primary">
            <input maxLength={2} {...register('hkid1', { required: true })}
              onChange={(e) => setValue('hkid1', e.target.value.trim().toUpperCase())}
              className="px-2 outline-none text-primary w-10 text-center"
              placeholder="A"
            />
            <input
              maxLength={6}
              {...register('hkid2', { required: true })}
              value={hkid2}
              onChange={(e) => setHkid2(e.target.value.trim().replace(/[^0-9]/g, ''))}
              className="px-2 outline-none text-primary w-20 text-center border-l border-r border-primary"
              placeholder="123456"
            />
            <div className="ml-4">
              <span className="text-primary">(</span>
              <input maxLength={1} {...register('hkid3', { required: true })}
                onChange={(e) => setValue('hkid3', e.target.value.trim().toUpperCase())}
                className="px-2 outline-none text-primary w-10 text-center"
                placeholder="3" />
              <span className="text-primary">)</span>
            </div>
          </div>
          {(errors?.hkid1 || errors?.hkid2 || errors?.hkid3) &&
            (errors?.hkid1?.type === 'required' || errors?.hkid2?.type === 'required' || errors?.hkid3?.type === 'required') && <span className="text-10 text-red-500">*必填</span>}
          {!checkId(getValues('hkid1'), getValues('hkid2'), getValues('hkid3')) &&
            (!(errors?.hkid1 || errors?.hkid2 || errors?.hkid3) ||
              !(errors?.hkid1?.type === 'required' || errors?.hkid2?.type === 'required' || errors?.hkid3?.type === 'required')) && (
              <span className="text-10 text-red-500">*請輸入正確的身份證號碼</span>
            )}
        </div>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">出生日期</p>
          <Common.InputDate register={register} errors={errors} getValues={getValues} setValue={setValue} setError={setError} />
        </div>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">手提電話</p>
          <div className="flex items-center border-b border-primary">
            <input value={location.state?.phone} disabled {...register('phone_number')} className="flex-1 px-2 outline-none w-full bg-white text-primary" />
          </div>
        </div>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">電郵地址</p>
          <div className="flex items-center border-b border-primary">
            <input
              {...register('email', {
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/g,
                onChange: (e) => {
                  setValue('email', (e.target?.value || '').replace(new RegExp(' ', 'g'), '').toLowerCase())
                }
              })}
              className="flex-1 px-2 outline-none text-primary w-full"
              placeholder=""
            />
          </div>
          {errors?.email && errors?.email?.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
          {errors.email && errors.email.type === 'pattern' && <span className="text-10 text-red-500">*請輸入正確的電郵</span>}
        </div>

        <div className="mb-6">
          <p className="text-xl text-gray mb-2">學歷</p>
          <div className="border-b border-primary relative">
            <Select
              placeholder="- 請選擇 -"
              className="w-full"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              styles={styles}
              {...register('education', { required: true })}
              onChange={(e) => {
                setValue('education', e.value);
                setError('education', null);
              }}
              options={options.education}
              isSearchable={false}
            />
          </div>
          {errors?.education && errors?.education?.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
        </div>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">婚姻狀態</p>
          <div className="border-b border-primary relative">
            <Select
              placeholder="- 請選擇 -"
              className="w-full"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              styles={styles}
              {...register('marriage_status', { required: true })}
              onChange={(e) => {
                setValue('marriage_status', e.value);
                setError('marriage_status', null);
              }}
              options={options.marriage_status}
              isSearchable={false}
            />
          </div>
          {errors?.marriage_status && errors?.marriage_status?.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
        </div>
        <div className="flex mb-2">
          <input type="checkbox" {...register('checkbox1', {
            required: true,
            onChange: (e) => {
              const checked = e.target.checked
              if (checked) {
                setCheckBoxTime(prev => {
                  return { ...prev, check_box_1_time: new Date() }
                })
              }
            }
            })} id="id-checkbox1" className="mr-2 mt-1" />
          <label htmlFor="id-checkbox1" className="text-scorpion text-xs flex-1">
            本人聲明本人
            <span className="text-primary">並非破產中</span>或<span className="text-primary">曾經破產</span>
            人士，而本人並無意申請破產及據本人所知現時並
            <span className="text-primary">無任何有關本人破產呈請在進行中</span>。
          </label>
        </div>
        <div className="flex">
          <input type="checkbox" {...register('checkbox2', {
            required: true,
            onChange: (e) => {
              const checked = e.target.checked
              if (checked) {
                setCheckBoxTime(prev => {
                  return { ...prev, check_box_2_time: new Date() }
                })
              }
            }
          })} id="id-checkbox2" className="mr-2 mt-1" />
          <label htmlFor="id-checkbox2" className="text-scorpion text-xs flex-1">
            本人確認為<span className="text-primary">香港永久性居民</span>並年齡為<span className="text-primary">18歲或以上人</span>士，本人已詳細閱讀並同意
            <a href={`${process.env.REACT_APP_HOME_URL}/money-lenders-ordinance/`} className="text-primary underline" target="_blank" rel="noreferrer">
              放債人條例
            </a>
            、
            <a
              href={`${process.env.REACT_APP_HOME_URL}/privacy-policy-and-personal-information-collection-statement/`}
              className="text-primary underline"
              target="_blank"
              rel="noreferrer"
            >
              私隱政策及收集個人資料聲明
            </a>
            、
            <a
              href={`${process.env.REACT_APP_HOME_URL}/terms-and-conditions/`}
              className="text-primary underline"
              target="_blank"
              rel="noreferrer"
            >
              私人貸款使用條款
            </a>
            及
            <a href={`${process.env.REACT_APP_HOME_URL}/website-terms/`} className="text-primary underline" target="_blank" rel="noreferrer">
              網站使用條款
            </a>
            ，亦同意KM信貸有權向任何第三方人士索取有關本人的資料，包括向信貸資料機構查閱及提供本人的信貸記錄。
          </label>
        </div>
        {(errors.checkbox1 || errors.checkbox2) && (errors.checkbox1?.type === 'required' || errors.checkbox2?.type === 'required') && (
          <span className="text-10 text-red-500">*必填</span>
        )}
        <div className="flex justify-center mt-9 mb-4">
          <button disabled={isSubmitting} type="submit" className={`rounded-full text-white text-xl px-14 py-3 ${isSubmitting ? 'bg-mercury' : 'bg-lightningYellow'}`}>
            下一步
          </button>
        </div>
      </div>
    </form>
  );
}

export default ApplicationForm1;
