import React, { useEffect } from 'react';
import { numberWithCommas, numberWithNoCommas } from '../../../utils/common';

function EditInputMoney(props) {
  const { setValue, register, title, value, editing, setError, errors, field, placeholder, zeroAllow } = props;

  useEffect(() => {
    if (setValue) {
      setValue(field, numberWithCommas(value));
    }
  }, [field, setValue, value]);

  const onChangeValue = (input) => {
    if ((input || '').startsWith('0') && !zeroAllow) { 
      return setValue(field, numberWithCommas(''));
    }
    if (input && Number(numberWithNoCommas(input)) > 400000) {
      return setValue(field, numberWithCommas(400000));
    }
    setValue(field, numberWithCommas(input));
    setError(field, null);
  };

  return (
    <>
      <div className={`flex items-center ${editing ? 'border-primary border-b' : ''}`}>
        <p className="text-gray w-1/2">{title}</p>
        {editing ? (
          <span className="text-primary flex-1 flex items-center">
            HK$&nbsp;
            <input
              inputMode={'numeric'}
              {...register(field, { required: true, pattern: /^[0-9,]*$/ })}
              onChange={(e) => onChangeValue(e.target.value)}
              className="w-1/2 outline-none"
              style={{ height: 36 }}
              placeholder={placeholder ?? "貸款額最高為港幣$400,000"}
              maxLength={7}
            />
          </span>
        ) : (
          <p className="text-primary flex-1">HK$&nbsp;{value}</p>
        )}
      </div>
      <div>
        {errors?.[field] && errors?.[field]?.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
        {errors?.[field] && errors?.[field]?.type === 'pattern' && <span className="text-10 text-red-500">*无效的</span>}
      </div>
    </>
  );
}

export default EditInputMoney;
