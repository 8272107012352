import React, { useState } from 'react';
import { numberWithCommas, numberWithNoCommas } from '../../../utils/common';
function InputMoney(props) {
  const { register, title, setError, errors, placeholder, zeroAllow } = props;
  const [valueInside, setValueInside] = useState('');

  const onChangeValue = (input) => {
    if ((input || '').startsWith('0') && !zeroAllow) {
      return setValueInside(numberWithCommas(''));
    }
    if (placeholder !== '' && Number(numberWithNoCommas(input)) > 400000) {
      setValueInside(numberWithCommas(400000));
    } else {
      setValueInside(numberWithCommas(input));
    }

    setError(title, null);
  };
  return (
    <>
      <div className="flex border-b border-primary">
        <p className="text-xl text-primary mr-2">HK$</p>
        <input
          inputMode={'numeric'}
          {...register(title, { required: true, pattern: /^[0-9,]*$/ })}
          onChange={(e) => onChangeValue(e.target.value)}
          value={valueInside}
          className="flex-1 px-2 outline-none text-primary"
          placeholder={placeholder ?? '貸款額最高為港幣$400,000'}
          {...(placeholder !== '' && { maxLength: 7 })}
        />
      </div>
      {errors?.[title] && errors?.[title]?.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
      {errors?.[title] && errors?.[title]?.type === 'pattern' && <span className="text-10 text-red-500">*无效的</span>}
    </>
  );
}

export default InputMoney;
