import { combineReducers } from 'redux';

import common from './common/reducer';
import application from './application/reducer';
import loan from './loan/reducer';

export const rootReducer = () =>
  combineReducers({
    common,
    application,
    loan,
  });
