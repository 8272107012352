import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Document, Page, pdfjs } from 'react-pdf';
import Plus from '../../../assets/images/plus.svg';
import PopUpBox from '../../PopUpBox';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Upload({ setFiles, files, idx, title }) {
  const [file, setFile] = useState([]);
  const [isVisibleError, setIsVisibleError] = useState(false);
  const accepts = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
  const { getRootProps, getInputProps } = useDropzone({
    // accept: ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'],
    maxFiles: 1,
    maxSize: 5000000,
    multiple: false,
    noDrag: false,
    onDrop: (acceptedFiles) => {
      if (!accepts.includes(acceptedFiles[0]?.type)) {
        setIsVisibleError(true)
      } else {
        const data = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        );
        const newFiles = files?.map((j, jdx) =>
          idx === jdx
            ? {
              ...j,
              ...(data[0] && { file: data[0] }),
            }
            : j,
        );
        setFiles(newFiles);
        setFile(data);
      }
    },
  });

  const thumbs = file.map((file) => {
    if (file?.type === 'application/pdf') {
      return (
        <Document key={file.name} file={file}>
          <Page pageNumber={1} />
        </Document>
      );
    }
    return file && <img key={file.name} src={file.preview} alt="" className="absolute top-0 left-0 w-full h-full object-cover" />;
  });

  useEffect(() => {
    file.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [file]);

  return (
    <>
      {isVisibleError && <PopUpBox.Error content={'請上傳正確格式的文件。'}>
        <div className="text-center my-3">
          <button onClick={() => setIsVisibleError(false)} type="button" className="rounded-full text-white text-sm bg-lightningYellow px-10 py-2">
            確認
          </button>
        </div>
      </PopUpBox.Error>}
      <div className="mt-6">
        <p className="text-primary text-sm mb-1">{title}</p>
        <div
          {...getRootProps({ className: 'dropzone' })}
          className="flex justify-center items-center flex-col border border-primary rounded-xl bg-aliceBlue h-48 overflow-hidden relative cursor-pointer"
        >
          <input {...getInputProps()} />
          {thumbs}
          <img src={Plus} alt="" />
          <p className="text-gray text-sm mb-2">(按此上傳)</p>
        </div>
      </div>
    </>
    
  );
}

export default Upload;
