import axios from '../../api';

export const actionLoanSendOTP = (payload) => async () => {
  try {
    const response = await axios.post('/loan-send-otp', payload);
    return response.data;
  } catch (error) {
    console.log(error)
  }
};

export const actionLoanCheckOTP = (payload) => async () => {
  try {
    const response = await axios.post('/loan-check-otp', payload);
    return response.data;
  } catch (error) {
    console.log(error)
  }
};

export const actionLoanRefreshToken = (payload) => async () => {
  try {
    const response = await axios.post('/loan-refresh-token', payload);
    return response.data;
  } catch (error) {
    console.log(error)
  }
};

export const actionLoanCheckStatus = (payload) => async () => {
  try {
    const response = await axios.post('/loan-check-status', payload);
    return response.data;
  } catch (error) {
    console.log(error)
  }
};

export const actionLoanUploadDoc = (payload) => async () => {
  try {
    const response = await axios.post('/loan-upload-doc', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.log(error)
  }
};
