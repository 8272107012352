import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Common } from '../../components';
import SelfieSuccess from '../../assets/images/selfie-success.svg';
import SelfieFailed from '../../assets/images/selfie-failed.svg';
import { PopUpBox } from '../../components';

import { actionApplicationIP, actionTracking } from '../../store/common/actions';
import { actionApplyLater } from '../../store/application/actions';

function ApplicationSelfieCheckResult() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isVisiblePopUpWrong5TimesTakePhoto, setIsVisiblePopUpWrong5TimesTakePhoto] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      actionTracking({
        url: window.location.href,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!location.state?.status) {
      navigate('/');
    }
  }, [location.state?.status, navigate]);

  useEffect(() => {
    const disableBack = () => {
      navigate(1);
    };
    window.addEventListener('popstate', disableBack);
    return () => {
      window.removeEventListener('popstate', disableBack);
    };
  }, [navigate]);

  const getUtmParams = async () => {
    const responseIP = await dispatch(actionApplicationIP());
    let data = {};
    const utm_medium = sessionStorage.getItem('utm_medium');
    const utm_source = sessionStorage.getItem('utm_source');
    const utm_campaign = sessionStorage.getItem('utm_campaign');
    const utm_id = sessionStorage.getItem('utm_id');
    const utm_term = sessionStorage.getItem('utm_term');
    const utm_content = sessionStorage.getItem('utm_content');
    const session_id = localStorage.getItem('session_id');
    data = {
      session_id,
      utm_medium,
      utm_source,
      utm_campaign,
      utm_id,
      utm_term,
      utm_content,
      ip: responseIP?.ip ?? '',
      access_query: `utm_medium=${utm_medium ?? ''}&utm_source=${utm_source ?? ''}&utm_campaign=${utm_campaign ?? ''}&utm_id=${utm_id ?? ''}&utm_term=${utm_term ?? ''}&utm_content=${utm_content ?? ''}`,
    }
    return data;
  }

  // try apply request later
  async function apply() {
    const utm_params = await getUtmParams();
    return dispatch(actionApplyLater({
      utm_params,
      step: 'Selfie'
    }))
  }

  useEffect(() => {
    if (location.state?.count === 0) {
      //setIsVisiblePopUpWrong5TimesTakePhoto(true);
      apply()
    }
  }, [location.state?.count, location.state?.response?.sts_code]);

  useEffect(() => {
    if (location.state?.count === 0 || location.state?.response?.sts_code === 0) {
      setIsSuccess(true);
    }
  }, [location.state?.count, location.state?.response?.sts_code]);

  const onBackOrNext = async () => {
    if (isSuccess) {
      let content = location.state?.response?.content
      let completed = true;
      if (content === "EXISTS_APPLY_LATER" || location.state?.count === 0) {
        await apply()
        content = ''
        completed = false;
      }
      navigate('/application/confirm/complete', { replace: true, state: { status: true, completed, content: '' } });
    } else {
      navigate('/application-selfie-check', { replace: true, state: { status: true, showCamera: true, count: location.state?.count - 1 } });
    }
  };

  return (
    <div className="bg-white p-5">
      <Common.StepBar active={3} />
      <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">本人自拍</p>
      {
        isSuccess ?
          <>
            <div className="flex justify-center"><img src={SelfieSuccess} alt="" /> </div>
            <p className="text-gray text-center">自拍照上傳成功</p>
          </> :
          <>
            <div className="flex justify-center"><img src={SelfieFailed} alt="" /></div>
            <p className="text-gray text-center">抱歉，未能驗證閣下身份</p>
            <p className="text-gray text-center">{`請重試 (剩餘${location.state?.count}次)`}</p>
          </>
      }
      <div className="flex justify-center mt-9 mb-4">
        <button onClick={onBackOrNext} className="rounded-full text-white text-xl bg-lightningYellow px-14 py-3">
          {isSuccess ? '下一步' : '重試'}
        </button>
      </div>
      {/* {isVisiblePopUpWrong5TimesTakePhoto && (
        <PopUpBox.Wrong5TimesTakePhoto
          onClick={() => {
            setIsVisiblePopUpWrong5TimesTakePhoto(false);
            navigate('/');
          }}
        />
      )} */}
    </div>
  );
}

export default ApplicationSelfieCheckResult;