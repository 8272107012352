import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Common, PopUpBox } from '../../components';
import CardId2003 from '../../assets/images/card_id_2003.png';
import CardId2018 from '../../assets/images/card_id_2018.png';
import LightBulb from '../../assets/images/light-bulb.svg';

import { actionTsoNewApplication, actionAgreeCamera, actionApplyLater } from '../../store/application/actions';
import { actionApplicationIP, actionTracking } from '../../store/common/actions';
import { getMobileOperatingSystem } from '../../utils/common';

function ApplicationIdCheck() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isVisibleInvalidToken, setIsVisibleInvalidToken] = useState(false);
  const [isVisibleBlockCamera, setIsVisibleBlockCamera] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardType, setCardType] = useState(2018)
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      actionTracking({
        url: window.location.href,
      }),
    );
  }, [dispatch]);

  // useEffect(() => {
  //   if (!location.state?.status) {
  //     navigate('/');
  //   }
  // }, [location.state?.status, navigate]);

  const tsoNewApplication = useCallback(async () => {
    const responseIP = await dispatch(actionApplicationIP());
    const response = await dispatch(actionTsoNewApplication({ ip: responseIP?.ip }));
    if (response?.sts_code === 3) {
      // INVALID_TOKEN
      setIsVisibleInvalidToken(true);
    }
  }, [dispatch]);

  // useEffect(() => {
  //   if (!location.state?.count && location.state?.count !== 0) {
  //     tsoNewApplication();
  //   }
  // }, [tsoNewApplication, location.state?.count]);

  const degreeCamera = useCallback(async () => {
    const response = await dispatch(
      actionAgreeCamera({
        agree_cam: false,
      }),
    );
    setIsVisibleBlockCamera(true);
    if (response?.sts_code === 3) {
      // INVALID_TOKEN
      setIsVisibleInvalidToken(true);
    }
  }, [dispatch]);

  const agreeCamera = useCallback(async () => {
    const response = await dispatch(
      actionAgreeCamera({
        agree_cam: true,
      }),
    );
    if (response?.sts_code === 3) {
      // INVALID_TOKEN
      setIsVisibleInvalidToken(true);
    }
  }, [dispatch]);

  const requestPermissionCamera = () => {
    if (getMobileOperatingSystem() === 'iOS') {
      navigator.mediaDevices
        ?.getUserMedia({ video: true })
        .then(() => {
          agreeCamera();
        })
        .catch(() => {
          degreeCamera();
          apply(null)
        });
    } else {
      navigator.permissions
        ?.query({ name: 'camera' })
        .then((permission) => {
          if (permission.state === 'prompt') {
            navigator.mediaDevices
              ?.getUserMedia({ video: true })
              .then(() => {
                agreeCamera();
              })
              .catch(() => {
                degreeCamera();
                apply(null)
              });
          }
        })
        .catch(() => {
          degreeCamera();
        });
    }
  }

  const getUtmParams = async () => {
    const responseIP = await dispatch(actionApplicationIP());
    let data = {};
    const utm_medium = sessionStorage.getItem('utm_medium');
    const utm_source = sessionStorage.getItem('utm_source');
    const utm_campaign = sessionStorage.getItem('utm_campaign');
    const utm_id = sessionStorage.getItem('utm_id');
    const utm_term = sessionStorage.getItem('utm_term');
    const utm_content = sessionStorage.getItem('utm_content');
    const session_id = localStorage.getItem('session_id');
    data = {
      session_id,
      utm_medium,
      utm_source,
      utm_campaign,
      utm_id,
      utm_term,
      utm_content,
      ip: responseIP?.ip ?? '',
      access_query: `utm_medium=${utm_medium ?? ''}&utm_source=${utm_source ?? ''}&utm_campaign=${utm_campaign ?? ''}&utm_id=${utm_id ?? ''}&utm_term=${utm_term ?? ''}&utm_content=${utm_content ?? ''}`,
    }
    return data;
  }

  async function apply(photo) {
    setLoading(true);
    setIsVisibleBlockCamera(false);
    const utm_params = await getUtmParams();
    await tsoNewApplication()
    const response = await dispatch(actionApplyLater({
      utm_params,
      step: 'HKID',
      photo
    }));
    setLoading(false);
    if (response?.sts_code === 0) {
      navigate('/application/confirm/complete', { replace: true, state: { status: true, completed: false, content: '' } });
    }
  }

  const onDetectHKID = () => {
    navigate('/application-id-check/hkid', { replace: true, state: { cardType, count: location.state?.count } });
  }

  useEffect(() => {
    if (location.state?.showCamera) {
      navigate('/application-id-check/hkid', {
        replace: true,
        state: {
          cardType: location.state?.cardType ?? cardType,
          count: location.state?.count
        }
      });
    }
  }, [location.state?.showCamera]);

  return (
    <div className="bg-white p-5">
      <Common.StepBar active={3} />
      {isVisibleBlockCamera && getMobileOperatingSystem() !== 'Android' && <PopUpBox.Error content="請允許相機權限以繼續貸款申請。" onClick={() => setIsVisibleBlockCamera(false)}>
        <div className="text-center my-3">
          <button onClick={requestPermissionCamera} type="button" className="rounded-full text-white text-sm bg-lightningYellow px-14 py-2">
            確定
          </button>
        </div>
      </PopUpBox.Error>}
      {isVisibleInvalidToken && <PopUpBox.InvalidToken />}
      <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">本人身份證</p>
      <p className="text-gray1 text-center">閣下即將進行身份證認證</p>
      <p className="text-gray1 text-center mb-6">請選擇身份證版本，然後點擊「開始拍攝」</p>
      <div onClick={() => { setCardType(2018) }} className={`flex justify-center flex-col p-4 ${cardType === 2018 ? 'border-4' : ''} border-primary rounded-lg mb-6 cursor-pointer`}>
        <p className="text-gray1 ml-4">新智能身份證 (2018年版)</p>
        <img src={CardId2018} alt="" />
      </div>
      <div onClick={() => { setCardType(2003) }} className={`flex justify-center flex-col p-4 ${cardType === 2003 ? 'border-4' : ''} border-primary rounded-lg mb-6 cursor-pointer`}>
        <p className="text-gray1 ml-4">舊智能身份證 (2003年版)</p>
        <img src={CardId2003} alt="" />
      </div>
      <div className="flex items-center text-lg mb-2">
        <img src={LightBulb} alt="" />
        <p className="text-gray underline mt-2">拍攝身份證小貼士</p>
      </div>
      <p className="text-gray ml-4">
        ・移除身份證透明卡套
        <br />
        ・確保四周光線充足
        <br />
        ・避免身份證資料模糊/反光/有陰影
        <br />
        ・應在與有強烈對比的平面背景上拍攝
        <br />
        ・避免遮擋身份證正面任何位置
      </p>
      <div className="flex justify-center mt-9 mb-4">
        <button disabled={isSubmitting} className="rounded-full text-white text-xl bg-lightningYellow px-14 py-3" onClick={onDetectHKID}>
          開始拍攝
        </button>
      </div>
    </div>
  );
}

export default ApplicationIdCheck;
