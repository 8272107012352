import axios from 'axios';
import Swal from 'sweetalert2';
import Close from '../assets/images/close.svg';
import { getCookie } from '../utils/storage';

/**
 * Axios defaults
 */
console.info(process.env.REACT_APP_ENV);
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

// Headers
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.withCredentials = true;

/**
 * Request Interceptor
 */
axios.interceptors.request.use(
  async (inputConfig) => {
    const config = inputConfig;

    // Check for and add the stored Auth Token to the header request
    let token = '';
    try {
      token = localStorage.getItem('access_token') || '';
    } catch (error) {
      /* Nothing */
    }
    const url = ['/send-otp', '/auth/login-otp', '/loan-send-otp', '/loan-check-otp'];
    if (token && url.indexOf(config.url) === -1) {
      config.headers.common['Authorization'] = `token ${token}`;
    }
    return config;
  },
  (error) => {
    throw error;
  },
);

const config = {
  text: '載入時發生錯誤，請重試。', 
  showConfirmButton: false,
  timer: 5000,
  toast: true,
  position: 'top',
  padding: '0.75rem',
  showCloseButton: true,
  closeButtonHtml: `<img src="${Close}" alt="close" />`,
}

/**
 * Response Interceptor
 */
axios.interceptors.response.use(
  (res) => {
    // Status code isn't a success code - throw error
    if (!`${res.status}`.startsWith('2')) {
      if ((res?.request?.responseURL || '').includes('/loan-upload-doc')) {
        Swal.fire({
          text: '未能上傳文件，請稍後再試。',
          ...config
        });
      } else {
        Swal.fire(config);
      }
      throw res.data;
    }

    // Otherwise just return the data
    return res;
  },
  (error) => {
    if ((error?.request?.responseURL || '').includes('/loan-upload-doc')) {
      Swal.fire({
        text: '未能上傳文件，請稍後再試。',
        ...config
      });
    } else {
      Swal.fire(config);
    }
    // Pass the response from the API, rather than a status code
    if (error && error.response && error.response.data) {
      throw error.response.data;
    }
    throw error;
  },
);

export default axios;
