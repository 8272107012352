import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Common, PopUpBox } from '../../components';

import { actionApplyLater, actionEkycLater, actionTsoNewApplication } from '../../store/application/actions';
import { actionApplicationIP, actionTracking } from '../../store/common/actions';

function ApplicationEkycConfirm() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [isVisibleInvalidToken, setIsVisibleInvalidToken] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [cardType, setCardType] = useState(2018)

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(
            actionTracking({
                url: window.location.href,
            }),
        );
    }, [dispatch]);

    // useEffect(() => {
    //   if (!location.state?.status) {
    //     navigate('/');
    //   }
    // }, [location.state?.status, navigate]);

    const tsoNewApplication = useCallback(async () => {
        const responseIP = await dispatch(actionApplicationIP());
        const response = await dispatch(actionTsoNewApplication({ ip: responseIP?.ip }));
        if (response?.sts_code === 3) {
            // INVALID_TOKEN
            setIsVisibleInvalidToken(true);
        }
    }, [dispatch]);


    const onNextEkycPage = () => {
        navigate('/application-id-check', { replace: true, state: { cardType, count: location.state?.count } });
    }

    const getUtmParams = async () => {
        const responseIP = await dispatch(actionApplicationIP());
        let data = {};
        const utm_medium = sessionStorage.getItem('utm_medium');
        const utm_source = sessionStorage.getItem('utm_source');
        const utm_campaign = sessionStorage.getItem('utm_campaign');
        const utm_id = sessionStorage.getItem('utm_id');
        const utm_term = sessionStorage.getItem('utm_term');
        const utm_content = sessionStorage.getItem('utm_content');
        const session_id = localStorage.getItem('session_id');
        data = {
            session_id,
            utm_medium,
            utm_source,
            utm_campaign,
            utm_id,
            utm_term,
            utm_content,
            ip: responseIP?.ip ?? '',
            access_query: `utm_medium=${utm_medium ?? ''}&utm_source=${utm_source ?? ''}&utm_campaign=${utm_campaign ?? ''}&utm_id=${utm_id ?? ''}&utm_term=${utm_term ?? ''}&utm_content=${utm_content ?? ''}`,
        }
        return data;
    }

    async function apply(photo) {
        const utm_params = await getUtmParams();
        const response = await dispatch(actionApplyLater({
            utm_params,
            step: 'HKID',
            photo
        }));
        if (response?.sts_code === 0) { 
            navigate('/application/confirm/complete', { replace: true, state: { status: true, completed: false, content: '' } });
        }
    }

    const onEkycLater = async () => {
        setLoading(true);
        await tsoNewApplication();
        const response = await dispatch(actionEkycLater());
        setLoading(false);
        if (response?.sts_code === 3) {
            // INVALID_TOKEN
            setIsVisibleInvalidToken(true);
        }
        if (response?.sts_code === 0) {
            await apply(null);
        }
    }

    return (
        <>
            <div className="bg-white p-5">
                <Common.StepBar active={3} />
                {isVisibleInvalidToken && <PopUpBox.InvalidToken />}
                <Common.Loading isVisible={isLoading} />
                <p className="flex-1 text-2xl text-primary2 text-right font-semibold border-b border-gray2 mb-4 pb-4">本人認證</p>
                <p className="text-gray1 p-3">接著會進行本人認證，立即進行認證可加快審批流程，閣下亦可以選擇稍後進行認證。</p>
                <p className="text-gray1 ml-8 text-lg">
                    ・掃描香港永久性居民身份證
                    <br />
                    ・拍攝本人自拍照
                </p>
                <div className="flex justify-center mt-6 mb-4">
                    <button className="rounded-full text-white text-xl bg-primary px-12 py-4" onClick={onNextEkycPage}>
                        立即進行認證
                    </button>
                </div>
                <div className="flex justify-center mt-4 mb-4">
                    <button className="rounded-full text-white text-xl bg-primary2 px-12 py-4" onClick={onEkycLater}>
                        稍後進行認證
                    </button>
                </div>
            </div>
        </>
    );
}

export default ApplicationEkycConfirm;
