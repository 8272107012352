import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { Common, PopUpBox } from '../../components';
import MobileSms from '../../assets/images/mobile-sms.svg';

import { actionLoanSendOTP, actionLoanCheckOTP } from '../../store/loan/actions';
import { actionSendClientID } from '../../store/application/actions';
import { numberWithNoCommas } from '../../utils/common';
import { actionTraceLog } from '../../store/common/actions';

const COUNT_TIMES = 4;

function CheckLoanStatusOTP() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  const [isVisibleTimeOverOTP, setIsVisibleTimeOverOTP] = useState(false);
  const [isVisibleWrongOTP, setIsVisibleWrongOTP] = useState(false);
  const [isVisibleExpiredOTP, setIsVisibleExpiredOTP] = useState(false);
  const [countTimes, setCountTimes] = useState(COUNT_TIMES);
  const [resendDisabled, setResendDisabled] = useState(false);

  useEffect(() => {
    if (!location.state?.requestId) {
      navigate('/');
    }
  }, [location.state?.requestId, navigate]);

  useEffect(() => {
    if (countTimes === 0) {
      setIsVisibleTimeOverOTP(true);
    }
  }, [countTimes]);

  const sendLoanSendOTP = () => {
    dispatch(
      actionLoanSendOTP({
        request_id: location.state?.requestId,
        day: location.state?.day,
        month: location.state?.month,
        year: location.state?.year,
      }),
    );
  };

  const onReSendOTP = () => {
    if (resendDisabled) {
      return;
    }
    setResendDisabled(true);
    if (countTimes !== 0) {
      sendLoanSendOTP();
      setIsVisibleTimeOverOTP(false);
    } else {
      navigate('/?open=check_status');
    }
  };

  const onSubmit = async (data) => {
    const response = await dispatch(
      actionLoanCheckOTP({
        request_id: location.state?.requestId,
        otp: data.otp,
      }),
    );
    if (response?.sts_code === 0) {
      // SUCCESS
      await localStorage.setItem('access_token', response?.content?.access_token);
      await localStorage.setItem('expired_date', response?.content?.expired_date);
      dispatch(actionTraceLog({
        message: `Start set expired date expired date: ${response?.content?.expired_date}`
      }));
      console.info(process.env.REACT_APP_GA_CODE);
      ReactGA.initialize(process.env.REACT_APP_GA_CODE, {
        gaOptions: {
          userId: response?.content?.user_id,
        },
      });
      ReactGA.send('pageview');
      ReactGA.ga(async (tracker) => {
        const clientId = tracker.get('clientId');
        await dispatch(
          actionSendClientID({
            client_id: clientId,
          }),
        );
      });
      ReactGA.set({ user_app_id: response?.content?.user_id });
      navigate('/check-loan-status-consent', {
        replace: true,
        state: {
          requestId: location.state?.requestId,
          day: location.state?.day,
          month: location.state?.month,
          year: location.state?.year,
        },
      });
    } else if (response?.sts_code === 5 || response?.sts_code === 21) {
      // INVALID_OTP
      setValue('otp', '');
      if (response?.content?.valid_date) {
        setCountTimes(0)
      } else {
        if (response?.content?.cnt && Number(response?.content?.cnt) < 5) {
          setIsVisibleWrongOTP(true);
          setCountTimes(5 - Number(response?.content?.cnt))
        } else {
          setCountTimes(0)
        }
      }
    } else if (response?.sts_code === 6) {
      // EXPIRED_OTP
      setCountTimes(0);
      setIsVisibleExpiredOTP(true);
    } else if (response?.sts_code === 7) {
      // MAX_WRONG_INPUT_OTP
      setCountTimes(0);
      setIsVisibleTimeOverOTP(true);
    } else {
      setCountTimes(countTimes - 1);
    }
  };

  return (
    <>
      <Common.Loading isVisible={isSubmitting} />
      <div className="bg-white p-5">
        {isVisibleTimeOverOTP && 
          <PopUpBox.TimeOverOTP onClick={() => { navigate('/?open=check_status') }} number={countTimes}>
            <div className="text-center my-3">
              <button onClick={() => { navigate('/?open=check_status') }} type="button" className="rounded-full text-white text-sm bg-lightningYellow px-10 py-2">
                確認
              </button>
            </div>
          </PopUpBox.TimeOverOTP>
        }
        {isVisibleWrongOTP && (
          <PopUpBox.Error
            content="驗證碼錯誤，請重新輸入。"
          >
            <div className="text-center my-3">
              {countTimes > 0 && <p className="text-primary my-3">(剩餘{countTimes}次)</p>}
              <button onClick={() => setIsVisibleWrongOTP(false)} type="button" className="rounded-full text-white text-sm bg-lightningYellow px-10 py-2">
                確認
              </button>
            </div>
          </PopUpBox.Error>
        )}
        {isVisibleExpiredOTP && (
          <PopUpBox.Error
            content="驗證超時，按此取得新的驗證碼。"
          >
            <div className="text-center my-3">
              <button onClick={() => {
                sendLoanSendOTP();
                setIsVisibleExpiredOTP(false);
              }} type="button" className="rounded-full text-white text-sm bg-lightningYellow px-10 py-2">
                確認
              </button>
            </div>
          </PopUpBox.Error>
        )}
        <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">安全驗證</p>
        <p className="text-gray mb-4">驗證碼已發送到閣下登記之手提電話號碼</p>
        <div className="flex justify-center mb-5">
          <img src={MobileSms} alt="" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-12 mb-2">
            <input
              inputMode='numeric'
              {...register('otp', {
                required: true,
                onChange: (e) => {
                  const value = numberWithNoCommas(e?.target?.value)
                  setValue('otp', value)
                }
              })}
              className="border border-primary rounded-md w-full h-10 outline-none px-2 text-center text-primary"
              style={{ marginBottom: -5 }}
              placeholder="請輸入驗證碼"
              maxLength={6}
            />
            {errors.otp && errors.otp.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
          </div>
          <p className="text-8 mb-6 text-center text-gray">
            未能收到驗證碼？
            <span className={`${!resendDisabled ? 'text-primary cursor-pointer' : 'text-gray cursor-not-allowed'} underline`} onClick={onReSendOTP}>
              取得新的驗證碼
            </span>
          </p>
          <div className="flex justify-center mb-4">
            <button type="submit" disabled={isSubmitting} className={`rounded-full text-white text-xl px-14 py-2 ${isSubmitting ? 'bg-mercury' : 'bg-lightningYellow'}`}>
              提交
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default CheckLoanStatusOTP;
