import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { actionTracking } from '../../store/common/actions';

function CheckLoanConsent() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    dispatch(
      actionTracking({
        url: window.location.href,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!location.state?.requestId) {
      navigate('/');
    }
  }, [location.state?.requestId, navigate]);

  return (
    <div className="bg-white p-5">
      <p className="text-xs text-doveGray mb-2">閣下登入前，務請仔細閱讀本聲明：</p>
      <div className="border-2 border-primary p-4 text-xs text-doveGray">
        <p className="mb-2">個人資料收集聲明</p>
        <p>本公司的私隱政策</p>
        <p className="mb-2">本聲明適用於KM International (Asia) Co., Limited（下稱本公司）。</p>
        <p className="mb-2">在本聲明中，「資料當事人」、「閣下」或「客戶」一詞應涵蓋瀏覽本網站之訪客﹑貸款申請人及貸款客戶。</p>
        <p className="mb-2">
          另外，在本聲明中，「集團」，「集團公司」、及「集團成員」／或「關連公司」一詞是指本公司之本地及海外的集團公司或成員及關連公司或成員（例如同一控股的母公司或子公司等）。
        </p>
        <p className="mb-2">
          本公司明白客戶於使用本公司網頁 <a href="https://www.kmcredit.hk">(https://www.kmcredit.hk)</a>
          時，其個人資料須加以保密，以保障客戶的私隱不會遭到外洩。因此，本公司將嚴謹遵守私隱條例。客戶到訪此網頁的同時，亦理解及同意本公司的的私隱政策。否則，客戶可離開此網頁。詳情請參考使用條款。
        </p>
        <p className="mb-2">本聲明之內容適用於所有資料當事人及為資料當事人不時與本公司訂立的貸款協議及／或任何協議或安排的條款及細則的一部分。</p>
        <p>個人資料收集</p>
        <p className="mb-2">個人資料指任何與個人有關的資料，而從該資料可直接或間接地確定有關個人的身分。本公司可能收集（包括但不限於）以下個人資料︰</p>
        <p>・姓名；</p>
        <p>・年齡；</p>
        <p>・性別；</p>
        <p>・電話號碼及電訊商；</p>
        <p>・身分證號碼；</p>
        <p>・國籍或居留身分；</p>
        <p>・出生日期；</p>
        <p>・個人財務狀況；</p>
        <p>・貸款目的；</p>
        <p>・收入或財產資料；</p>
        <p>・教育程度；</p>
        <p>・就業資料；</p>
        <p>・居住資料；</p>
        <p>・銀行戶口之詳情；</p>
        <p>・閣下與本公司的對話內容；</p>
        <p className="mb-2">・有關閣下瀏覽及使用本公司網站的資料。</p>
        <p className="mb-2">個人資料使用</p>
        <p className="mb-2">我們致力保持準確而最新的個人資料記錄，客戶的個人資料可被用作以下用途︰</p>
        <p>・處理和批核貸款申請以及貸款服務所涉及之日常運作；</p>
        <p>・客戶首次或再次申請貸款或申請續借貸款時進行信貸調查；</p>
        <p>・確保資料當事人持續的可靠信用度以及良好聲譽；</p>
        <p>・不定期更新客戶個人資料以協助其他金融機構，包括我們的關連公司及集團成員的公司作信用檢查及追討債務；</p>
        <p>・設計供客戶使用的貸款服務或其他服務；</p>
        <p>・計算我們與客戶之間的欠款；</p>
        <p>・進行市場調查或內部分析以提供更優質貸款服務；</p>
        <p>
          ・遵守有關於任何我們及成員公司內的資料及資訊共享及／或資料及資訊的任何其他使用(有關共享及使用，均依照符合對洗錢、恐怖分子資金籌集或其他不法活動的制裁、防止或偵測的任何我們及成員公司的計劃而進行)的任何責任、規定、政策、程序、措施或安排；
        </p>
        <p className="mb-2">・與上述用途有關的任何其他用途。</p>
        <p className="mb-2">
          我們及我們集團的公司及關連公司希望把客戶的個人資料用於直接促銷目的(作為自願性用途)。我們明白未經事前獲得他或她就該用途的同意(包括表示不反對)，我們及我們集團之任何成員公司均不得將客戶的個人資料用於該用途。
        </p>
        <p>就此，請注意︰</p>
        <p className="mb-2">
          我們或我們集團的任何成員公司或關連公司可能把其不時持有的客戶姓名、聯絡資料、產品及服務組合資料、交易模式及行為、財務背景及人口統計數據等個人資料互相轉移並用於直接促銷；及可用作促銷下列類別的服務、產品及促銷目的︰
        </p>
        <p>由我們，集團公司或關連公司所提供的財務相關的服務及產品；及</p>
        <p className="mb-2">獎賞、客戶忠誠或優惠計劃及相關服務及產品。</p>
        <p className="mb-2">
          客戶有權選擇他們的個人資料是否可用於直接促銷目的。若客戶不欲我們，集團公司或關連公司使用或向其他人士提供其個人資料以作上文所述的直接促銷用途，且希望我們，集團公司或關連公司告知其他人士停止使用其個人資料以作有關的直接促銷目的，則該客戶可在任何其他時間向我們告知此點而無須付款。
        </p>
        <p>個人資料披露</p>
        <p>
          ・本公司可能會把該等資料披露或轉移給第三者服務供應商（包括但不限於代客寄件中心﹑電訊公司﹑電話行銷及直銷代理人﹑電話中心﹑數據處理公司及資訊科技公司），以達至上述之用途。本公司在香港所搜集的個人資料或會被轉移至香港境內外。
        </p>
        <p>・本公司可能隨時被要求向政府部門、司法機構或本公司的監管機構透露閣下的個人資料，然而本公司只會在適當權限下進行。</p>
        <p>
          ・本公司持有的所有個人資料將予保密，以防止任何人（包括本公司職員）未經授權而取得閣下的個人資料。本公司並明確規定所有獲准取得閣下個人資料的本公司職員及第三方，均須遵守本公司的保密責任。
        </p>
        <p>・本公司可能會把該等資料提供及披露給任何對本公司有保密責任的人士，包括本公司集團內已承諾保持該資料保密的成員公司作個人資料使用一欄列出的用途。</p>
        <p className="mb-2">・本公司奉行上述原則，以體現本公司極為重視閣下對本公司的信任。</p>
        <p>私隱政策修訂</p>
        <p className="mb-2">
          本公司保留對私隱政策的修訂權。本公司可隨時修改、增加及刪減私隱政策的內容。任何的內容修訂一經發布即告生效。本公司建議客戶不定期瀏覽此網頁，留意本公司的私隱政策有否任何更新，以取得最新資訊。
        </p>
        <p>聯絡我們</p>
        <p className="mb-2">如客戶對本公司的私隱政策有任何疑問，請致電3700 4488查詢。</p>
        <div className="flex mt-2">
          <input type="checkbox" id="id-radio1" checked={isChecked} className="mr-2" onChange={(e) => setIsChecked(e.target.checked)} />
          <label htmlFor="id-radio1" className="flex-1">
            本人已閱讀並同意以上聲明。
          </label>
        </div>
        <div className="flex justify-center my-4">
          <button
            onClick={() => {
              if (isChecked) {
                navigate('/check-loan-status-stage', {
                  replace: true,
                  state: {
                    requestId: location.state?.requestId,
                    day: location.state?.day,
                    month: location.state?.month,
                    year: location.state?.year,
                  },
                });
              }
            }}
            disabled={!isChecked}
            className={`${isChecked ? 'bg-lightningYellow' : 'bg-mercury'} rounded-full text-white text-xl px-14 py-3`}
          >
            下一步
          </button>
        </div>
      </div>
    </div>
  );
}

export default CheckLoanConsent;
