import React from 'react';

function StepBar({ active = 1 }) {
  return (
    <div className="flex items-center justify-center mb-6">
      <div className={`flex items-center justify-center text-white text-3xl rounded-full w-14 h-14 z-10 ${active >= 1 ? 'bg-primary' : 'bg-gray'} ${active === 1 ? 'border-4 border-lightningYellow' : ''}`}>
        1
      </div>
      <div className="bg-gray w-6 h-1" style={{ margin: '0 -1px' }} />
      <div className={`flex items-center justify-center text-white text-3xl rounded-full w-14 h-14 z-10 ${active >= 2 ? 'bg-primary' : 'bg-gray'} ${active === 2 ? 'border-4 border-lightningYellow' : ''}`}>
        2
      </div>
      <div className="bg-gray w-6 h-1" style={{ margin: '0 -1px' }} />
      <div className={`flex items-center justify-center text-white text-3xl rounded-full w-14 h-14 z-10 ${active >= 3 ? 'bg-primary' : 'bg-gray'} ${active === 3 ? 'border-4 border-lightningYellow' : ''}`}>
        3
      </div>
      <div className="bg-gray w-6 h-1" style={{ margin: '0 -1px' }} />
      <div className={`flex items-center justify-center text-white text-3xl rounded-full w-14 h-14 z-10 ${active >= 4 ? 'bg-primary' : 'bg-gray'} ${active === 4 ? 'border-4 border-lightningYellow' : ''}`}>
        4
      </div>
    </div>
  );
}

export default StepBar;
