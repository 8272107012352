import axios from '../../api';
import * as axiosRaw from 'axios';

export const actionTracking = (payload) => async () => {
  try {
    const response = await axios.post('/tracking', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionTracking2 = (payload) => async () => {
  try {
    const response = await axios.post('/tracking-2', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionLink2ndCheck = (payload) => async () => {
  try {
    const response = await axios.get('/link-2nd-check', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionTraceLog = (payload) => async () => {
  try {
    const response = await axios.post('/client-trace-log', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionErrorCount = (payload) => async () => {
  try {
    const response = await axios.post('/error-count', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionApplicationIP = () => async () => {
  try {
    const response = await fetch('https://api.ipify.org/?format=json')
    return await response.json();
  } catch (error) {
    //
  }
};

export const actionAddressConfig = () => async () => {
  try {
    const response = await axios.get('/address-config');
    return response.data;
  } catch (error) {
    //
  }
};