import React from 'react';

function TimeOverOTP({ children, onClick, number }) {
  return (
    <div className="p-4 fixed w-full z-10 top-0 left-0 flex items-center justify-center h-full" style={{ backgroundColor: '#0000004d' }}>
      <div className="border-4 border-primary p-4 bg-white w-full" onClick={onClick} style={{ maxWidth: 414 }}>
        <div className="text-center">
          {number === 0 ? (
            <p className="text-gray">驗證碼發送次數達上限，請稍後再嘗試。</p>
          ) : (
            <>
              <p className="text-gray">
                驗證超時，
                <span className="text-primary">按此</span>
                取得新的驗證碼。
              </p>
              <p className="text-primary">(剩餘{number}次)</p>
            </>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}

export default TimeOverOTP;
