import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import bg from '../../assets/images/bg.png';

import { PopUpBox } from '../../components';
import { createGuid } from '../../utils/common';

function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isVisiblePopUpSendOTP, setIsVisiblePopUpSendOTP] = useState(false);
  const [isVisiblePopUpBoxLoginBox, setIsVisiblePopUpLoginBox] = useState(false);

  const [detectCamera, setDetectCamera] = useState(true);

  const saveSessionInfo = () => {
    const params = new URLSearchParams(location.search)
    const session_id = params.get('session_id')
    if (location.search.includes('session_id=')) {
      localStorage.setItem('session_id', session_id)
    } else {
      const session_id = localStorage.getItem('session_id');
      if (!session_id) {
        localStorage.setItem('session_id', createGuid());
      }
    }
    let utm_medium = params.get('utm_medium')
    if (utm_medium) {
      sessionStorage.setItem('utm_medium', utm_medium);
    } else {
      utm_medium = sessionStorage.getItem('utm_medium') ?? '';
    }
    let utm_source = params.get('utm_source')
    if (utm_source) {
      sessionStorage.setItem('utm_source', utm_source);
    } else {
      utm_source = sessionStorage.getItem('utm_source') ?? '';
    }
    let utm_campaign = params.get('utm_campaign')
    if (utm_campaign) {
      sessionStorage.setItem('utm_campaign', utm_campaign);
    } else {
      utm_campaign = sessionStorage.getItem('utm_campaign') ?? '';
    }
    let utm_id = params.get('utm_id')
    if (utm_id) {
      sessionStorage.setItem('utm_id', utm_id);
    } else {
      utm_id = sessionStorage.getItem('utm_id') ?? '';
    }
    let utm_term = params.get('utm_term')
    if (utm_term) {
      sessionStorage.setItem('utm_term', utm_term);
    } else {
      utm_term = sessionStorage.getItem('utm_term') ?? '';
    }
    let utm_content = params.get('utm_content')
    if (utm_content) {
      sessionStorage.setItem('utm_content', utm_content);
    } else {
      utm_content = sessionStorage.getItem('utm_content') ?? '';
    }
  }

  useEffect(() => {
    if (!detectCamera && !location.search.includes('?open=check_status')) {
      navigate('/check-device-no-camera');
      return;
    }
    if (location.search.includes('?open=register')) {
      setIsVisiblePopUpSendOTP(true);
      saveSessionInfo()
    } else if (location.search.includes('?open=check_status')) {
      setIsVisiblePopUpLoginBox(true);
      saveSessionInfo()
    } else {
      window.location.href = process.env.REACT_APP_HOME_URL
    }
  }, [detectCamera, location, navigate])
  

  useEffect(() => {
    // detect camera
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      // Firefox 38+ seems having support of enumerateDevicesx
      navigator.enumerateDevices = function (callback) {
        navigator.mediaDevices.enumerateDevices().then(callback);
      };
    }

    let MediaDevices = [];
    let isHTTPs = window.location.protocol === 'https:';
    let canEnumerate = false;

    if (typeof MediaStreamTrack !== 'undefined' && 'getSources' in MediaStreamTrack) {
      canEnumerate = true;
    } else if (navigator.mediaDevices && !!navigator.mediaDevices.enumerateDevices) {
      canEnumerate = true;
    }

    let hasMicrophone = false;
    let hasSpeakers = false;
    let hasWebcam = false;

    let isMicrophoneAlreadyCaptured = false;
    let isWebcamAlreadyCaptured = false;

    function checkDeviceSupport(callback) {
      if (!canEnumerate) {
        return;
      }

      if (!navigator.enumerateDevices && window.MediaStreamTrack && window.MediaStreamTrack.getSources) {
        navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(window.MediaStreamTrack);
      }

      if (!navigator.enumerateDevices && navigator.enumerateDevices) {
        navigator.enumerateDevices = navigator.enumerateDevices.bind(navigator);
      }

      if (!navigator.enumerateDevices) {
        if (callback) {
          callback();
        }
        return;
      }

      MediaDevices = [];
      navigator.enumerateDevices(function (devices) {
        devices.forEach(function (_device) {
          let device = {};
          for (let d in _device) {
            device[d] = _device[d];
          }

          if (device.kind === 'audio') {
            device.kind = 'audioinput';
          }

          if (device.kind === 'video') {
            device.kind = 'videoinput';
          }

          let skip;
          MediaDevices.forEach(function (d) {
            if (d.id === device.id && d.kind === device.kind) {
              skip = true;
            }
          });

          if (skip) {
            return;
          }

          if (!device.deviceId) {
            device.deviceId = device.id;
          }

          if (!device.id) {
            device.id = device.deviceId;
          }

          if (!device.label) {
            device.label = 'Please invoke getUserMedia once.';
            if (!isHTTPs) {
              device.label = 'HTTPs is required to get label of this ' + device.kind + ' device.';
            }
          } else {
            if (device.kind === 'videoinput' && !isWebcamAlreadyCaptured) {
              isWebcamAlreadyCaptured = true;
            }

            if (device.kind === 'audioinput' && !isMicrophoneAlreadyCaptured) {
              isMicrophoneAlreadyCaptured = true;
            }
          }

          if (device.kind === 'audioinput') {
            hasMicrophone = true;
          }

          if (device.kind === 'audiooutput') {
            hasSpeakers = true;
          }

          if (device.kind === 'videoinput') {
            hasWebcam = true;
          }

          // there is no 'videoouput' in the spec.
          MediaDevices.push(device);

          return {
            hasWebcam,
            hasMicrophone,
            hasSpeakers,
            isMicrophoneAlreadyCaptured,
            isWebcamAlreadyCaptured,
          };
        });

        if (callback) {
          callback();
        }
      });
    }

    checkDeviceSupport(function () {
      setDetectCamera(hasWebcam);
    });
  }, []);

  return (
    <>
      <div className="flex flex-1 flex-col">
        <img src={bg} alt="" />
        <p className="text-center font-bold text-bayOfMany my-5" style={{ fontSize: 28 }}>
          KM信貸A.I.批核<br />
          貸款最快即日^^收到！
        </p>
      </div>
      {isVisiblePopUpSendOTP && <PopUpBox.SendPhoneNumber />}
      {isVisiblePopUpBoxLoginBox && <PopUpBox.LoginBox />}
    </>
  );
}

export default Home;
