import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogoFooter from '../../../assets/images/logo-footer.svg';
import BgFooter from '../../../assets/images/bg-footer.png';
import Time from '../../../assets/images/time.png';
import Address from '../../../assets/images/address.png';
import Phone from '../../../assets/images/phone.png';

function AppFooter() {
  return (
    <>
      <div className="text-primary2 p-5 pt-20" style={{ backgroundImage: `url(${BgFooter})` }}>
        <div className="flex items-center justify-center mb-10 lg:mt-12">
          <a href="https://www.facebook.com/kmcredit.hk/">
            <FontAwesomeIcon className="mx-4" icon={['fab', 'facebook']} size="2x" />
          </a>
          <a href="https://www.instagram.com/kmcredit_hk">
            <FontAwesomeIcon className="mx-4" icon={['fab', 'instagram']} size="2x" />
          </a>
          <a href="https://www.youtube.com/channel/UCrz9FiwbVAvfrFd7wJ0arqA">
            <FontAwesomeIcon className="mx-4" icon={['fab', 'youtube']} size="2x" />
          </a>
          <a href="https://www.linkedin.com/company/km-international-asia/?viewAsMember=true%20">
            <FontAwesomeIcon className="mx-4" icon={['fab', 'linkedin']} size="2x" />
          </a>
        </div>
        <div className="flex justify-center lg:items-center flex-col w-full">
          <div className="flex mt-3 text-sm">
            <div className="pt-1">
              <img style={{ width: 21 }} src={Time} alt="" />
            </div>
            <div className="pl-2 leading-5">
              星期一至五&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;：早上9時至下午6時 <br />
              星期六, 日及公眾假期&nbsp;：休息
            </div>
          </div>
          <div className="flex mt-3 text-sm">
            <div className="pt-1">
              <img style={{ width: 21 }} src={Address} alt="" />
            </div>
            <div className="pl-2 leading-5">
              <p>香港九龍尖沙咀麼地道67號半島中心10樓1013室</p>
            </div>
          </div>
          <div className="flex mt-3 text-sm">
            <div className="pt-1">
              <img style={{ width: 21 }} src={Phone} alt="" />
            </div>
            <div className="pl-2 leading-5">
              <p>(852) 3700 4488</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center mt-5">
          <img src={LogoFooter} alt="" className="w-48 mb-4" />
          <p className="text-xs">Brand under KM International (Asia) Co., Limited.</p>
        </div>
      </div>
      <div className="py-2 text-white" style={{ backgroundColor: '#6887bb' }}>
        <p className="text-center my-4 text-3xl px-2">忠告：借錢梗要還，咪俾錢中介</p>
        <p className="text-center text-lg">
          投訴熱線 : 3700 4499 <br />
          放債人牌照號碼 : 0269/2024
          <br />
        </p>
        <p className="text-xs text-center mt-6">Copyright © KM International (Asia) Co., Limited</p>
      </div>
    </>
  );
}

export default AppFooter;
