import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SelfieFailed from '../../assets/images/selfie-failed.svg';
import SelfieSuccess from '../../assets/images/selfie-success.svg';
import { PopUpBox } from '../../components';

import { actionApplicationIP, actionTracking2 } from '../../store/common/actions';
import { actionExtra1stComplete, actionNeedEkyc } from '../../store/application/actions';

function ConfirmSelfieCheckResult() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isVisiblePopUpWrong5TimesTakePhoto, setIsVisiblePopUpWrong5TimesTakePhoto] = useState(false);

  useEffect(() => {
    async function tracking() {
      const res = await dispatch(
        actionTracking2({
          url: window.location.href,
        }),
      );
      if (res?.sts_code !== 0) {
        navigate('/');
        localStorage.removeItem('access_token');
      }
    }
    tracking()
  }, [dispatch]);

  // useEffect(() => {
  //   if (!location.state?.status) {
  //     navigate('/');
  //   }
  // }, [location.state?.status, navigate]);

  useEffect(() => {
    const disableBack = () => {
      navigate(1);
    };
    window.addEventListener('popstate', disableBack);
    return () => {
      window.removeEventListener('popstate', disableBack);
    };
  }, [navigate]);

  const getUtmParams = async () => {
    const responseIP = await dispatch(actionApplicationIP());
    let data = {};
    const utm_medium = sessionStorage.getItem('utm_medium');
    const utm_source = sessionStorage.getItem('utm_source');
    const utm_campaign = sessionStorage.getItem('utm_campaign');
    const utm_id = sessionStorage.getItem('utm_id');
    const utm_term = sessionStorage.getItem('utm_term');
    const utm_content = sessionStorage.getItem('utm_content');
    const session_id = localStorage.getItem('session_id');
    data = {
      session_id,
      utm_medium,
      utm_source,
      utm_campaign,
      utm_id,
      utm_term,
      utm_content,
      ip: responseIP?.ip ?? '',
      access_query: `utm_medium=${utm_medium ?? ''}&utm_source=${utm_source ?? ''}&utm_campaign=${utm_campaign ?? ''}&utm_id=${utm_id ?? ''}&utm_term=${utm_term ?? ''}&utm_content=${utm_content ?? ''}`,
    }
    return data;
  }

  // try apply request later
  async function apply() {
    const res = await dispatch(actionNeedEkyc());
    console.log('need ekyc res', res);
  }

  useEffect(() => {
    if (location.state?.count === 0) {
      setIsVisiblePopUpWrong5TimesTakePhoto(true);
      apply()
    }
  }, [location.state?.count]);

  const onBackOrNext = async () => {
    if ((location.state?.response?.sts_code === 0 && location.state?.response?.content !== 'EXISTS_APPLY_LATER') || location.state?.count === 0) {
      let content = location.state?.response?.content
      let completed = true;
      if (content === "EXISTS_APPLY_LATER") {
        await apply()
        content = ''
        completed = false
      }
      await dispatch(actionExtra1stComplete())
      navigate('/application/confirm/complete', { replace: true, state: { completed } });
    } else {
      navigate(location.state?.path, { replace: true, state: { status: true, flow: location?.state?.flow ?? 2, notCheckLink: true, showCamera: location.state?.response?.sts_code !== 25, count: location.state?.count - 1 } });
    }
  };

  return (
    <div className="bg-white p-5">
      {location.state?.response?.sts_code !== 0 || (location.state?.response?.content === 'EXISTS_APPLY_LATER') ? (
        <>
          <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">本人自拍</p>
          <div className="flex justify-center"><img src={SelfieFailed} alt="" /></div>
          {/*TODO: hide message error*/}
          {location.state?.response?.sts_code === 25 ?
            <p className="text-gray text-center"> 抱歉，閣下進行身份驗證時出現錯誤，請致電☎3700 4488聯絡職員了解詳情。</p>
            : <>
              <p className="text-gray text-center">抱歉，未能驗證閣下身份</p>
              <p className="text-gray text-center">{`請重試 (剩餘${location.state?.count}次)`}</p>
            </>
          }
          <div className="flex justify-center mt-9 mb-4">
            <button onClick={onBackOrNext} className="rounded-full text-white text-xl bg-lightningYellow px-14 py-3">
              重試
            </button>
          </div>
        </>
      ) : (
          <>
            <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">本人自拍</p>
            <div className="flex justify-center"><img src={SelfieSuccess} alt="" /></div>
            <p className="text-gray text-center">自拍照上傳成功</p>
            <div className="flex justify-center mt-9 mb-4">
              <button onClick={onBackOrNext} className="rounded-full text-white text-xl bg-lightningYellow px-14 py-3">
                下一步
              </button>
            </div>
          </>
      )}
      {isVisiblePopUpWrong5TimesTakePhoto && (
        <PopUpBox.Wrong5TimesTakePhoto
          onClick={() => {
            setIsVisiblePopUpWrong5TimesTakePhoto(false);
            navigate('/');
          }}
        />
      )}
    </div>
  );
}

export default ConfirmSelfieCheckResult;
