import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Common, PopUpBox } from '../../components';
import CardId2003 from '../../assets/images/card_id_2003.svg';
import CardId2018 from '../../assets/images/card_id_2018.svg';
import LightBulb from '../../assets/images/light-bulb.svg';

import { actionTsoNewApplication, actionAgreeCamera } from '../../store/application/actions';
import { actionApplicationIP, actionLink2ndCheck, actionTracking, actionTracking2 } from '../../store/common/actions';

export default function ConfirmIdCheck() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isVisibleIDCardTakePhoto, setIsVisibleIDCardTakePhoto] = useState(false);
  const [isVisibleBlockCamera, setIsVisibleBlockCamera] = useState(false);
  const [countTimes, setCountTimes] = useState(-1)
  const [cardType, setCardType] = useState(2018)

  useEffect(() => {
    async function tracking() {
      const res = await dispatch(
        actionTracking2({
          url: window.location.href,
        }),
      );
      if (res?.sts_code !== 0) {
        navigate('/');
        localStorage.removeItem('access_token');
      } else if (!location.state?.notCheckLink) {
        const res = await dispatch(
          actionLink2ndCheck(),
        );
        if (res?.content?.error_count > 4) {
          window.location.href = `${process.env.REACT_APP_HOME_URL}/page-expired`
        } else {
          setCountTimes(res?.content?.error_count > 0 ? res?.content?.error_count : -1)
        }
        if (res?.content?.extra_1st_completed === 1) {
          navigate('/');
          localStorage.removeItem('access_token');
        }
      }
    }
    tracking()
  }, [dispatch, location.state?.notCheckLink]);

  useEffect(() => {
    if (location.state?.showCamera) {
      navigate(`${location.pathname}/hkid`, {
        replace: true,
        state: {
          cardType: location.state?.cardType ?? cardType,
          count: location.state?.count
        }
      });
    }
  }, [location.state?.showCamera]);

  const onDetectHKID = () => {
    navigate(`${location.pathname}/hkid`, {
      replace: true, state: {
        cardType,
        count: location.state?.count
      }
    });
  }

  return (
    <div className="bg-white p-5">
      <Common.StepBar active={3} />
      <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">本人身份證</p>
      <p className="text-gray1 text-center mb-6">請選擇身份證版本，然後點擊「開始拍攝」</p>
      <div onClick={() => { setCardType(2018) }} className={`flex justify-center flex-col p-4 ${cardType === 2018 ? 'border-4' : ''} border-primary rounded-lg mb-6 cursor-pointer`}>
        <p className="text-gray1 ml-4">新智能身份證 (2018年版)</p>
        <img src={CardId2018} alt="" />
      </div>
      <div onClick={() => { setCardType(2003) }} className={`flex justify-center flex-col p-4 ${cardType === 2003 ? 'border-4' : ''} border-primary rounded-lg mb-6 cursor-pointer`}>
        <p className="text-gray1 ml-4">舊智能身份證 (2003年版)</p>
        <img src={CardId2003} alt="" />
      </div>
      <div className="flex items-center text-lg mb-2">
        <img src={LightBulb} alt="" />
        <p className="text-gray1 underline mt-2">拍攝身份證小貼士</p>
      </div>
      <p className="text-gray ml-4">
        ・移除身份證透明卡套
        <br />
        ・確保四周光線充足
        <br />
        ・避免身份證資料模糊/反光/有陰影
        <br />
        ・應在與有強烈對比的平面背景上拍攝
        <br />
        ・避免遮擋身份證正面任何位置
      </p>
      <div className="flex justify-center mt-9 mb-4">
        <button className="rounded-full text-white text-xl bg-lightningYellow px-14 py-3" onClick={onDetectHKID}>
          開始拍攝
        </button>
      </div>
    </div>
  );
}