
const message = {
    NEEDOLD: '請提供舊款智能身份證 (2003年版)',
    NEEDNEW: '請提供新款智能身份證 (2018年版)',
    NEEDFACE: '請拍攝身份證正面',
    NEEDROTATE: '請傾斜拍攝身份證正面',
    NEEDBACK: '請拍攝身份證背面',
    ERRORLINK: '“certiType” 數值錯誤',
    LESS_ROTATE: '向下傾斜度過小​',
    MORE_ROTATE: '向下傾斜度過大​',
    FAR: '證件過遠，請移近拍攝',
    MOREA: '請水平擺放證件',
    LIGHT: '光線過強',
    DARK: '光線過暗',
    ERRORCERTI: '請提供香港身份證',
    EXCEED_LEFT: '請確保證件在相框內',
    EXCEED_TOP: '請確保證件在相框內',
    EXCEED_RIGHT: '請確保證件在相框內',
    EXCEED_BOTTOM: '請確保證件在相框內',
    LOOSE: '檢測不到證件',
    OK: '拍攝成功',
};

export default function getMessage(code) {
    return message[code];
}
