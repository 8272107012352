import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './configureStore';

import './App.css';

import { Common } from './components';

import Home from './pages/Home';
import CheckDeviceNoCamera from './pages/CheckDeviceNoCamera';

import ApplicationOTP from './pages/ApplicationOTP';
import ApplicationOTPSuccess from './pages/ApplicationOTPSuccess';

import ApplicationConsent from './pages/ApplicationConsent';
import ApplicationForm1 from './pages/ApplicationForm1';
import ApplicationForm21 from './pages/ApplicationForm21';
import ApplicationForm22 from './pages/ApplicationForm22';
import ApplicationConfirmData from './pages/ApplicationConfirmData';
import ApplicationIdCheck from './pages/ApplicationIdCheck';
import ApplicationIdCheckResult from './pages/ApplicationIdCheckResult';
import ApplicationSelfieCheck from './pages/ApplicationSelfieCheck';
import ApplicationSelfieCheckResult from './pages/ApplicationSelfieCheckResult';
import ApplicationComplete from './pages/ApplicationComplete';

import CheckLoanStatusOTP from './pages/CheckLoanStatusOTP';
import CheckLoanStatusConsent from './pages/CheckLoanStatusConsent';
import CheckLoanStatusStage from './pages/CheckLoanStatusStage';

import ConfirmSelfieCheck from './pages/ConfirmSelfieCheck';
import ConfirmSelfieCheckResult from './pages/ConfirmSelfieCheckResult';
import ConfirmEsignCheck from './pages/ConfirmEsignCheck';
import { useEffect } from 'react';
import { onTokenExpired } from './utils/common'
import ConfirmIdCheck from './pages/ConfirmIdCheck';
import ConfirmIdCheckResult from './pages/ConfirmIdCheckResult';
import ConfirmApplicationComplete from './pages/ConfirmApplicationComplete';
import ConfirmIdCheckDalite from './pages/ConfirmIdCheckDalite';
import ApplicationIdCheckDalite from './pages/ApplicationIdCheckDalite';

import TagManager from 'react-gtm-module'
import ApplicationEkycConfirm from './pages/ApplicationEkycConfirm';

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_CODE
})

function App() {
  const store = configureStore();
  // check expired token
  useEffect(() => {
    onTokenExpired()
  }, [])
  
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className={`App ${window.location.href.includes('money-hero') ? 'MoneyHero' : ''}`}>
          <Common.AppHeader />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/check-device-no-camera" element={<CheckDeviceNoCamera />} />

            <Route path="/application-otp" element={<ApplicationOTP />} />
            <Route path="/application-otp-success" element={<ApplicationOTPSuccess />} />
            <Route path="/application-consent" element={<ApplicationConsent />} />
            <Route path="/application-form-1" element={<ApplicationForm1 />} />
            <Route path="/application-form-2-1" element={<ApplicationForm21 />} />
            <Route path="/application-form-2-2" element={<ApplicationForm22 />} />
            <Route path="/application-confirm-data" element={<ApplicationConfirmData />} />
            <Route path="/application-ekyc-confirm" element={<ApplicationEkycConfirm />} />
            <Route path="/application-id-check" element={<ApplicationIdCheck />} />
            <Route path="/application-id-check/hkid" element={<ApplicationIdCheckDalite />} />
            <Route path="/application-id-check-result" element={<ApplicationIdCheckResult />} />
            <Route path="/application-selfie-check" element={<ApplicationSelfieCheck />} />
            <Route path="/application-selfie-check-result" element={<ApplicationSelfieCheckResult />} />
            <Route path="/application-complete" element={<ApplicationComplete />} />

            <Route path="/check-loan-status-otp" element={<CheckLoanStatusOTP />} />
            <Route path="/check-loan-status-consent" element={<CheckLoanStatusConsent />} />
            <Route path="/check-loan-status-stage" element={<CheckLoanStatusStage />} />

            <Route path="/application/confirm/:token/confirm-id-check" element={<ConfirmIdCheck />} />
            <Route path="/application/confirm/:token/confirm-id-check/hkid" element={<ConfirmIdCheckDalite />} />
            <Route path="/application/confirm/:token/confirm-id-check-result" element={<ConfirmIdCheckResult />} />
            <Route path="/application/confirm/:token" element={<ConfirmSelfieCheck />} />
            <Route path="/application/confirm/:token/confirm-selfie-check-result" element={<ConfirmSelfieCheckResult />} />
            <Route path="/application/confirm/:token/confirm-esign-check" element={<ConfirmEsignCheck />} />
            <Route path="/application/confirm/complete" element={<ConfirmApplicationComplete />} />

          </Routes>
          <Common.AppFooter />
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
