import React from 'react';

function Wrong5TimesTakePhoto({ onClick }) {
  return (
    <div className="p-4 fixed w-full z-10 top-0 left-0 flex items-center justify-center h-full" style={{ backgroundColor: '#0000004d' }}>
      <div className="border-4 border-primary p-4 bg-white w-full" onClick={onClick} style={{ maxWidth: 414 }}>
        <div className="text-center">
          <p className="text-gray">未能成功上傳身份證超過5次</p>
          <p className="text-gray"><span className="text-primary">按此</span>返回主頁重新申請</p>
          <button onClick={onClick} type="button" className="rounded-full text-white text-sm bg-lightningYellow px-14 py-2 mt-3">
            確定
          </button>
        </div>
      </div>
    </div>
  );
}

export default Wrong5TimesTakePhoto;
