import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { Common, PopUpBox } from '../../components';
import { styles, options, numberWithNoCommas } from '../../utils/common';

import { actionSubmitForm21 } from '../../store/application/actions';
import { actionTracking } from '../../store/common/actions';

function ApplicationForm21() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    setValue,
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();
  const [mpf, setMpf] = useState(1);
  const [isVisibleInvalidToken, setIsVisibleInvalidToken] = useState(false);

  useEffect(() => {
    dispatch(
      actionTracking({
        url: window.location.href,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!location.state?.status) {
      navigate('/');
    }
  }, [location.state?.status, navigate]);

  const onSubmit = async (data) => {
    const obj = {
      business_type: data?.business_type,
      job_type: data?.job_type,
      position: data?.position,
      employment_type: data?.employment_type,
      receipt_way: data?.receipt_way,
      monthly_income: numberWithNoCommas(data?.monthly_income),
      payroll_method: data?.payroll_method,
      mpf,
    };
    const response = await dispatch(actionSubmitForm21(obj));
    if (response?.sts_code === 0) {
      navigate('/application-form-2-2', { replace: true, state: { status: true, form1: location.state?.form1, form2: obj } });
    } else if (response?.sts_code === 3) {
      // INVALID_TOKEN
      setIsVisibleInvalidToken(true);
    }
  };

  const beforeunload = (e) => {
    const event = e || window.event;
    if (event) {
      event.returnValue = 'Sure?';
    }
    return 'Sure?';
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('beforeunload', beforeunload);

    return () => {
      window.removeEventListener('beforeunload', beforeunload);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isVisibleInvalidToken && <PopUpBox.InvalidToken />}
      <Common.Loading isVisible={isSubmitting} />
      <div className="bg-white p-5">
        <Common.StepBar active={1} />
        <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">工作資料</p>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">業務類型</p>
          <div className="border-b border-primary relative">
            <Select
              placeholder="- 請選擇 -"
              className="w-full"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              styles={styles}
              {...register('business_type', { required: true })}
              onChange={(e) => {
                setValue('business_type', e.value);
                setError('business_type', null);
              }}
              options={options.business_type}
              isSearchable={false}
            />
          </div>
          {errors?.business_type && errors?.business_type?.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
        </div>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">工作類型</p>
          <div className="border-b border-primary relative">
            <Select
              placeholder="- 請選擇 -"
              className="w-full"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              styles={styles}
              {...register('job_type', { required: true })}
              onChange={(e) => {
                setValue('job_type', e.value);
                setError('job_type', null);
              }}
              options={options.job_type}
              isSearchable={false}
            />
          </div>
          {errors?.job_type && errors?.job_type?.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
        </div>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">職位</p>
          <div className="border-b border-primary relative">
            <Select
              placeholder="- 請選擇 -"
              className="w-full"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              styles={styles}
              {...register('position', { required: true })}
              onChange={(e) => {
                setValue('position', e.value);
                setError('position', null);
              }}
              options={options.position}
              isSearchable={false}
            />
          </div>
          {errors?.position && errors?.position?.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
        </div>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">受聘形式</p>
          <div className="border-b border-primary relative">
            <Select
              placeholder="- 請選擇 -"
              className="w-full"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              styles={styles}
              {...register('employment_type', { required: true })}
              onChange={(e) => {
                setValue('employment_type', e.value);
                setError('employment_type', null);
              }}
              options={options.employment_type}
              isSearchable={false}
            />
          </div>
          {errors?.employment_type && errors?.employment_type?.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
        </div>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">出糧方式</p>
          <div className="border-b border-primary relative">
            <Select
              placeholder="- 請選擇 -"
              className="w-full"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              styles={styles}
              {...register('receipt_way', { required: true })}
              onChange={(e) => {
                setValue('receipt_way', e.value);
                setError('receipt_way', null);
              }}
              options={options.receipt_way}
              isSearchable={false}
            />
          </div>
          {errors?.receipt_way && errors?.receipt_way?.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
        </div>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">月收入</p>
          <Common.InputMoney zeroAllow={true} placeholder=" " register={register} title="monthly_income" setError={setError} errors={errors} />
        </div>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">支薪方式</p>
          <div className="border-b border-primary relative">
            <Select
              placeholder="- 請選擇 -"
              className="w-full"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              styles={styles}
              {...register('payroll_method', { required: true })}
              onChange={(e) => {
                setValue('payroll_method', e.value);
                setError('payroll_method', null);
              }}
              options={options.payroll_method}
              isSearchable={false}
            />
          </div>
          {errors?.payroll_method && errors?.payroll_method?.type === 'required' && <span className="text-10 text-red-500">*必填</span>}
        </div>
        <div className="mb-6">
          <p className="text-xl text-gray mb-2">MPF強積金</p>
          <div className="flex items-center justify-center mb-6">
            <button
              type="button"
              onClick={() => {
                setMpf(1);
                setValue('mpf', 1);
              }}
              className={`rounded-full text-xl text-primary border border-primary px-10 py-2 mr-3 ${mpf === 1 ? 'bg-primary text-white' : 'text-primary'}`}
            >
              有
            </button>
            <button
              type="button"
              onClick={() => {
                setMpf(2);
                setValue('mpf', 2);
              }}
              className={`rounded-full text-xl text-primary border border-primary px-10 py-2 ${mpf === 2 ? 'bg-primary text-white' : 'text-primary'}`}
            >
              沒有
            </button>
          </div>
        </div>
        <div className="flex justify-center mt-9 mb-4">
          <button disabled={isSubmitting} type="submit" className={`rounded-full text-white text-xl px-14 py-3 ${isSubmitting ? 'bg-mercury' : 'bg-lightningYellow'}`}>
            下一步
          </button>
        </div>
      </div>
    </form>
  );
}

export default ApplicationForm21;
