import React from 'react';
import { useNavigate } from 'react-router-dom';
import Status1 from '../../../../assets/images/status-1.svg';
import Status2 from '../../../../assets/images/status-2.svg';
import Status3 from '../../../../assets/images/status-3.svg';
import Status4 from '../../../../assets/images/status-4.svg';
import Fast from '../../../../assets/images/fast.svg';

function Status({ result, setActiveTab }) {
  const navigate = useNavigate();

  const renderResult = () => {
    switch (result) {
      case 1:
        return (
          <>
            <img src={Status1} alt="" className="w-full" />
            <p className="text-primary mb-2">申請完成</p>
            <p className="text-gray text-sm mb-8">我們已收到閣下的貸款申請，我們的貸款專員會盡快與閣下聯絡。閣下可預先遞交基本文件，以更快獲得批核結果。</p>
            <div className="flex justify-center mb-4 relative">
              <img src={Fast} alt="" />
              <button
                onClick={() => setActiveTab(2)}
                className="rounded-full text-white text-sm bg-lightningYellow px-5 py-2 absolute"
                style={{ bottom: -8 }}
              >
                立即上載文件
              </button>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <img src={Status2} alt="" className="w-full" />
            <p className="text-primary mb-2">審查中</p>
            <p className="text-gray text-sm mb-8">我們的貸款專員正在審核閣下之貸款申請，並會與閣下聯絡進一步確認相關資料。閣下可預先遞交基本文件，以更快獲得批核結果。</p>
            <div className="flex justify-center mb-4 relative">
              <img src={Fast} alt="" />
              <button
                onClick={() => setActiveTab(2)}
                className="rounded-full text-white text-sm bg-lightningYellow px-5 py-2 absolute"
                style={{ bottom: -8 }}
              >
                立即上載文件
              </button>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <img src={Status3} alt="" className="w-full" />
            <p className="text-primary mb-2">批核中</p>
            <p className="text-gray text-sm mb-8">閣下的貸款申請已轉交到批核部作覆核，稍後將以電話或手機短訊通知批核結果。</p>
            <div className="flex justify-center mb-4">
              <button onClick={() => navigate('/')} className="rounded-full text-white text-xl bg-lightningYellow px-10 py-4">
                返回主頁
              </button>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <img src={Status4} alt="" className="w-full" />
            <p className="text-primary mb-2">貸款批核成功</p>
            <p className="text-gray text-sm mb-8">閣下的貸款已成功批核，我們的貸款專員將聯絡閣下講解簽約流程。</p>
            <div className="flex justify-center mb-4">
              <button onClick={() => navigate('/')} className="rounded-full text-white text-xl bg-lightningYellow px-10 py-4">
                返回主頁
              </button>
            </div>
          </>
        );
      case 5:
        return (
          <>
            <img src={Status4} alt="" className="w-full" />
            <p className="text-primary mb-2">貸款批核不成功</p>
            <p className="text-gray text-sm mb-8">非常抱歉，閣下是次貸款申請未能成功。</p>
            <div className="flex justify-center mb-4">
              <button onClick={() => navigate('/')} className="rounded-full text-white text-xl bg-lightningYellow px-10 py-4">
                返回主頁
              </button>
            </div>
          </>
        );
      default:
        return (
          <>
            <img src={Status1} alt="" className="w-full" />
            <p className="text-primary mb-2">申請完成</p>
            <p className="text-gray text-sm mb-8">我們已收到閣下的貸款申請，我們的貸款專員會盡快與閣下聯絡。閣下可預先遞交基本文件，以更快獲得批核結果。</p>
            <div className="flex justify-center mb-4 relative">
              <img src={Fast} alt="" />
              <button
                onClick={() => setActiveTab(2)}
                className="rounded-full text-white text-sm bg-lightningYellow px-5 py-2 absolute"
                style={{ bottom: -8 }}
              >
                立即上載文件
              </button>
            </div>
          </>
        );
    }
  };

  return (
    <div className="bg-selago p-5 rounded-xl mb-6 mx-5">
      <p className="flex-1 text-2xl text-gray font-semibold text-center mb-4">申請狀態</p>
      {renderResult()}
    </div>
  );
}

export default Status;
