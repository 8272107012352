import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import moment from 'moment';
import { Common, PopUpBox } from '../../components';
import Edit from '../../assets/images/edit.svg';
import EditPng from '../../assets/images/edit.png';
import { options, numberWithNoCommas, detectMob, pad, capitalizeName, alphabetOnly, sw2Alert} from '../../utils/common';

import { actionSubmitForm1, actionSubmitForm21, actionSubmitForm22, actionConfirmData } from '../../store/application/actions';
import { actionTracking } from '../../store/common/actions';
import validid from 'validid';


function ApplicationConfirmData() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    setValue,
    getValues,
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();
  const [isEditingForm1, setIsEditingForm1] = useState(false);
  const [isEditingForm2, setIsEditingForm2] = useState(false);
  const [isEditingForm3, setIsEditingForm3] = useState(false);
  const [isEditingForm11, setIsEditingForm11] = useState(false);
  const [isVisibleInvalidToken, setIsVisibleInvalidToken] = useState(false);
  const [optionsDistrict, setOptionsDistrict] = useState([]);
  const [gender, setGender] = useState(Number(location.state?.form1?.cust_gender));
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      actionTracking({
        url: window.location.href,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!location.state?.status) {
      //navigate('/');
    }
  }, [location.state?.status, navigate]);

  useEffect(() => {
    if (location.state?.form3?.district_value && location.state?.form3?.area && location.state?.form3?.address_config) {
      const address_config = location.state?.form3?.address_config;
      switch (location.state?.form3?.area) {
        case 'HK':
          setOptionsDistrict(address_config?.district_hk);
          break;
        case 'KLN':
          setOptionsDistrict(address_config?.district_kw);
          break;
        case 'NT':
          setOptionsDistrict(address_config?.district_nt);
          break;
        default:
          break;
      }
      setValue('district', location.state?.form3?.district_value)
    }
  }, [location.state?.form3?.area, location.state?.form3?.district_value, location.state?.form3?.address_config])

  useEffect(() => {
    if (location.state?.form1?.hkid_array) {
      const hdids = location.state?.form1?.hkid_array;
      setValue('hkid1', hdids[0])
      setValue('hkid2', hdids[1])
      setValue('hkid3', hdids[2])
    }
    setValue('gender', gender)
  }, [location.state?.form1?.hkid_array, setValue])

  const onSubmit = async (data) => {
    // check hkid valid
    if (!checkId(getValues('hkid1'), getValues('hkid2'), getValues('hkid3'))) {
      setErrorMessage('身份證格式錯誤，請重新輸入');
      window.scrollTo(0, 0);
      return;
    }
    if (data?.building?.length > 49 || data?.estate?.length > 49) {
      return sw2Alert('輸入的地址不可以超過50個字元');
    };
    await dispatch(
      actionSubmitForm1({
        loan_amount: numberWithNoCommas(data?.loan_amount || location.state?.form1?.loan_amount),
        loan_purpose: data?.loan_purpose || location.state?.form1?.loan_purpose,
        first_name: data?.first_name || location.state?.form1?.first_name,
        last_name: data?.last_name || location.state?.form1?.last_name,
        cust_gender: `0${gender}`,
        hkid: data?.hkid1 ? `${data?.hkid1}${data?.hkid2}${data?.hkid3}` : location.state?.form1?.hkid,
        date_of_birth: data?.date_of_birth3 ? `${data?.date_of_birth3}${pad(data?.date_of_birth2)}${pad(data?.date_of_birth1)}` : location.state?.form1?.date_of_birth,
        phone_number: location.state?.form1?.phone_number,
        email: data?.email || location.state?.form1?.email,
        education: data?.education || location.state?.form1?.education,
        marriage_status: data?.marriage_status || location.state?.form1?.marriage_status,
        application_device: detectMob() ? 2 : 1,
        application_ip: location.state?.form1?.application_ip,
        application_browser: window.navigator.userAgent,
        check_box_1: location.state?.form1?.check_box_1,
        check_box_2: location.state?.form1?.check_box_2,
        check_box_1_time: location.state?.form1?.check_box_1_time,
        check_box_2_time: location.state?.form1?.check_box_2_time,
        agree_doc: location.state?.form1?.agree_doc,
      }),
    );
    await dispatch(
      actionSubmitForm21({
        business_type: data?.business_type,
        job_type: data?.job_type,
        position: data?.position,
        employment_type: data?.employment_type,
        receipt_way: data?.receipt_way,
        monthly_income: numberWithNoCommas(data?.monthly_income),
        payroll_method: data?.payroll_method,
        mpf: data?.mpf,
      }),
    );
    const district = optionsDistrict.find(t => t.value === data?.district);
    const province = options.area.find(t => t.value === data?.area);
    await dispatch(
      actionSubmitForm22({
        area: data?.area,
        city_id: district?.city_id,
        city_id_tu: district?.tu_value,
        province_id: province?.province_id,
        addr_line1: data?.building,
        addr_line2: data?.estate,
        property_type: data?.property_type,
        number_living_with: data?.number_living_with,
        property_owner: data?.property_owner,
        monthly_rent: numberWithNoCommas(data?.monthly_rent),
      }),
    );
    const response = await dispatch(actionConfirmData());
    if (response?.sts_code === 0) {
      navigate('/application-ekyc-confirm', { replace: true, state: { status: true } });
    } else if (response?.sts_code === 3) {
      // INVALID_TOKEN
      setIsVisibleInvalidToken(true);
    }
  };

  useEffect(() => {
    const disableBack = () => {
      navigate(1);
    };
    window.addEventListener('popstate', disableBack);
    return () => {
      window.removeEventListener('popstate', disableBack);
    };
  }, [navigate]);

  const checkId = (hkid1, hkid2, hkid3) => {
    if (hkid1 && hkid2 && hkid3) {
      return validid.hkid(`${hkid1}${hkid2}/${hkid3}`);
    }
    return true;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isVisibleInvalidToken && <PopUpBox.InvalidToken />}
      <Common.Loading isVisible={isSubmitting} />
      {errorMessage && <PopUpBox.Error content={errorMessage} onClick={() => setErrorMessage(null)}>
        <div className="text-center my-3">
          <button onClick={() => setErrorMessage(null)} type="button" className="rounded-full text-white text-sm bg-lightningYellow px-14 py-2">
            確定
          </button>
        </div>
      </PopUpBox.Error>}
      <div className="bg-white p-5">
        <Common.StepBar active={2} />
        <p className="flex-1 text-2xl text-primary font-semibold border-b-4 border-primary mb-4">申請資料確認</p>
        <p className="text-gray">
          請確認所填之資料，點選「確定提交」後， 便不能再作修改。閣下可以點選
          <img src={Edit} alt="" className="inline" />
          修改相關 部份的資料。
        </p>
        {/* form 1 */}
        <div>
          <div>
            <div className="bg-primary text-center flex justify-center text-white text-xl py-1 my-4">
              <p className="mr-2">貸款資料</p>
              <img style={{ width: 23, height: 23 }} src={EditPng} alt="" onClick={() => setIsEditingForm1(true)} className="cursor-pointer" />
            </div>
            <Common.EditInputMoney
              setValue={setValue}
              register={register}
              errors={errors}
              title="貸款金額"
              setError={setError}
              value={location.state?.form1?.loan_amount}
              editing={isEditingForm1}
              field="loan_amount"
              placeholder=" "
            />
            <Common.EditSelect
              register={register}
              errors={errors}
              title="貸款目的"
              value={location.state?.form1?.loan_purpose}
              editing={isEditingForm1}
              options={options.loan_purpose}
              field="loan_purpose"
              setValue={setValue}
              setError={setError}
            />
          </div>
          <div>
            <div className="bg-primary text-center flex justify-center text-white text-xl py-1 my-4">
              <p className="mr-2">申請人資料</p>
              <img style={{ width: 23, height: 23 }} src={EditPng} alt="" onClick={() => setIsEditingForm11(true)} className="cursor-pointer" />
            </div>
            <div className="flex items-start text-lg">
              {!isEditingForm11 && <p className="text-gray w-1/2">英文姓名</p>}
              <div className='w-full'>
                {!isEditingForm11 ? <p className="text-primary flex-1">{`${location.state?.form1?.first_name} ${location.state?.form1?.last_name}`}</p> :
                  <div className='w-full'>
                    <Common.EditInput
                      setValue={setValue}
                      register={register}
                      errors={errors}
                      title="姓氏"
                      value={location.state?.form1?.last_name}
                      editing={isEditingForm11}
                      field="last_name"
                      onChange={(e) => {
                        const value = alphabetOnly(e?.target?.value + '')
                        setValue('last_name', capitalizeName(value))
                      }}
                    />
                    <Common.EditInput
                      setValue={setValue}
                      register={register}
                      errors={errors}
                      title="名字"
                      value={location.state?.form1?.first_name}
                      editing={isEditingForm11}
                      field="first_name"
                      onChange={(e) => {
                        const value = alphabetOnly(e?.target?.value + '')
                        setValue('first_name', value.toUpperCase())
                      }}
                    />
                  </div>}
                {!isEditingForm11 ?
                  <button disabled type="button" className="mt-2 rounded-full border border-primary px-8 py-2 bg-primary text-white text-sm">
                    {location.state?.form1?.cust_gender === '01' ? '先生' : '小姐'}
                  </button> :
                  <div className="flex items-center justify-center my-3">
                    <button
                      type="button"
                      onClick={() => {
                        setGender(1);
                        setValue('gender', 1);
                      }}
                      className={`rounded-full text-xl border border-primary px-10 py-2 mr-3 ${gender === 1 ? 'bg-primary text-white' : ' text-primary'}`}
                    >
                      先生
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setGender(2);
                        setValue('gender', 2);
                      }}
                      className={`rounded-full text-xl border border-primary px-10 py-2 ${gender === 2 ? 'bg-primary text-white' : 'text-primary'}`}
                    >
                      小姐
                    </button>
                  </div>}
              </div>
            </div>
            <div className="flex items-start text-lg mt-2">
              {isEditingForm11 ? <div className='w-full'>
                <p className="text-xl text-gray mb-2">香港身份證號碼</p>
                <div className="flex items-center border-b border-primary">
                  <input maxLength={2} {...register('hkid1', { required: true })}
                    onChange={(e)=> setValue('hkid1', e.target.value.trim().toUpperCase())}
                    className="px-2 outline-none text-primary w-10 text-center"
                    placeholder="A" />
                  <input
                    maxLength={6}
                    {...register('hkid2', {
                      required: true,
                      onChange: (e) => {
                        setValue('hkid2', (e?.target?.value || '').trim().replace(/[^0-9]/g, ''))
                      }
                    })}
                    className="px-2 outline-none text-primary w-20 text-center border-l border-r border-primary"
                    placeholder="123456"
                  />
                  <div className="ml-4">
                    <span className="text-primary">(</span>
                    <input maxLength={1} {...register('hkid3', { required: true })}
                      onChange={(e) => setValue('hkid3', e.target.value.trim().toUpperCase())}
                      className="px-2 outline-none text-primary w-10 text-center"
                      placeholder="3" />
                    <span className="text-primary">)</span>
                  </div>
                </div>
                {!checkId(getValues('hkid1'), getValues('hkid2'), getValues('hkid3')) &&
                  (!(errors?.hkid1 || errors?.hkid2 || errors?.hkid3) ||
                    !(errors?.hkid1?.type === 'required' || errors?.hkid2?.type === 'required' || errors?.hkid3?.type === 'required')) && (
                    <span className="text-10 text-red-500">*請輸入正確的身份證號碼</span>
                  )}
              </div> : <>
                <p className="text-gray w-1/2">香港身份證號碼</p>
                <p className="text-primary flex-1">{`${_.dropRight(location.state?.form1?.hkid?.split(''))?.join('')}(${_.last(location.state?.form1?.hkid?.split(''))})`}</p>
              </>}
            </div>
            <div className="flex items-start text-lg mt-2">
              {!isEditingForm11 ? <>
                <p className="text-gray w-1/2">出生日期</p>
                <p className="text-primary flex-1">{moment(location.state?.form1?.date_of_birth, 'YYYY/MM/DD').format('DD/MM/YYYY')}</p>
              </> : <div className='w-full'><p className="text-gray w-1/2">出生日期</p><Common.InputDate selectedDate={moment(location.state?.form1?.date_of_birth, 'YYYY/MM/DD').format('YYYY/MM/DD')} register={register} errors={errors} getValues={getValues} setValue={setValue} setError={setError} /> </div>}
            </div>
            <div className="flex items-start text-lg mt-2">
              <p className="text-gray w-1/2">手提電話</p>
              <p className="text-primary flex-1">{location.state?.form1?.phone_number}</p>
            </div>
            <div className="flex items-start text-lg mt-2">
              {isEditingForm11 ?
                <div className='w-full'>
                  <Common.EditInput
                    setValue={setValue}
                    register={register}
                    errors={errors}
                    title="電郵地址"
                    value={location.state?.form1?.email}
                    editing={isEditingForm11}
                    field="email"
                    onChange={(e) => {
                      setValue('email', (e.target?.value || '').replace(new RegExp(' ', 'g'), '').toLowerCase())
                    }}
                  />
                </div> :
                <>
                  <p className="text-gray w-1/2">電郵地址</p>
                  <p className="text-primary flex-1">{location.state?.form1?.email}</p>
                </>
              }
            </div>
            <div className="flex items-start text-lg mt-2">
              {!isEditingForm11 ? <>
                <p className="text-gray w-1/2">學歷</p>
                <p className="text-primary flex-1">{options.education?.find((i) => i?.value === location.state?.form1?.education)?.label}</p></>
                : <div className='w-full'>
                  <Common.EditSelect
                    register={register}
                    errors={errors}
                    title="學歷"
                    value={location.state?.form1?.education}
                    editing={isEditingForm11}
                    options={options.education}
                    field="education"
                    setValue={setValue}
                    setError={setError}
                  /></div>}
            </div>
            <div className="flex items-start text-lg mt-2">
              {!isEditingForm11 ?
                <>              <p className="text-gray w-1/2">婚姻狀態</p>
                  <p className="text-primary flex-1">{options.marriage_status?.find((i) => i?.value === location.state?.form1?.marriage_status)?.label}</p>
                </> : <div className='w-full'>
                  <Common.EditSelect
                    register={register}
                    errors={errors}
                    title="婚姻狀態"
                    value={location.state?.form1?.marriage_status}
                    editing={isEditingForm11}
                    options={options.marriage_status}
                    field="marriage_status"
                    setValue={setValue}
                    setError={setError}
                  /></div>}
            </div>
          </div>
        </div>
        {/* form 2 */}
        <div>
          <div>
            <div className="bg-primary text-center flex justify-center text-white text-xl py-1 my-4">
              <p className="mr-2">工作資料</p>
              <img style={{ width: 23, height: 23 }} src={EditPng} alt="" onClick={() => setIsEditingForm2(true)} className="cursor-pointer" />
            </div>
            <Common.EditSelect
              register={register}
              errors={errors}
              title="業務類型"
              value={location.state?.form2?.business_type}
              editing={isEditingForm2}
              options={options.business_type}
              field="business_type"
              setValue={setValue}
              setError={setError}
            />
            <Common.EditSelect
              register={register}
              errors={errors}
              title="工作類型"
              value={location.state?.form2?.job_type}
              editing={isEditingForm2}
              options={options.job_type}
              field="job_type"
              setValue={setValue}
              setError={setError}
            />
            <Common.EditSelect
              register={register}
              errors={errors}
              title="職位"
              value={location.state?.form2?.position}
              editing={isEditingForm2}
              options={options.position}
              field="position"
              setValue={setValue}
              setError={setError}
            />
            <Common.EditSelect
              register={register}
              errors={errors}
              title="受聘形式"
              value={location.state?.form2?.employment_type}
              editing={isEditingForm2}
              options={options.employment_type}
              field="employment_type"
              setValue={setValue}
              setError={setError}
            />
            <Common.EditSelect
              register={register}
              errors={errors}
              title="出糧方式"
              value={location.state?.form2?.receipt_way}
              editing={isEditingForm2}
              options={options.receipt_way}
              field="receipt_way"
              setValue={setValue}
              setError={setError}
            />
            <Common.EditInputMoney
              setValue={setValue}
              register={register}
              errors={errors}
              title="月收入"
              setError={setError}
              value={location.state?.form2?.monthly_income}
              editing={isEditingForm2}
              field="monthly_income"
              placeholder=" "
              zeroAllow={true}
            />
            <Common.EditSelect
              register={register}
              errors={errors}
              title="支薪方式"
              value={location.state?.form2?.payroll_method}
              editing={isEditingForm2}
              options={options.payroll_method}
              field="payroll_method"
              setValue={setValue}
              setError={setError}
            />
            <Common.EditSelect
              register={register}
              errors={errors}
              title="MPF強積金"
              value={location.state?.form2?.mpf}
              editing={isEditingForm2}
              options={options.mpf}
              field="mpf"
              setValue={setValue}
              setError={setError}
            />
          </div>
        </div>
        {/* form 3 */}
        <div>
          <div>
            <div className="bg-primary text-center flex justify-center text-white text-xl py-1 my-4">
              <p className="mr-2">住址資料</p>
              <img style={{ width: 23, height: 23 }} src={EditPng} alt="" onClick={() => setIsEditingForm3(true)} className="cursor-pointer" />
            </div>
            <p className="text-xl text-gray mb-2">住宅地址</p>
            <Common.EditSelect
              register={register}
              errors={errors}
              title="區域"
              value={getValues('area') || location.state?.form3?.area}
              editing={isEditingForm3}
              options={options.area}
              field="area"
              setValue={setValue}
              setError={setError}
              onChange={(e) => {
                const address_config = location.state?.form3?.address_config;
                switch (e.value) {
                  case 'HK':
                    setOptionsDistrict(address_config?.district_hk);
                    break;
                  case 'KLN':
                    setOptionsDistrict(address_config?.district_kw);
                    break;
                  case 'NT':
                    setOptionsDistrict(address_config?.district_nt);
                    break;
                  default:
                    break;
                }
                setValue('area', e.value)
                setValue('district', null)
              }}
            />
            <Common.EditSelect
              register={register}
              errors={errors}
              title="地區"
              value={getValues('district')}
              editing={isEditingForm3}
              options={optionsDistrict}
              field="district"
              setValue={setValue}
              setError={setError}
              valueEnabled={true}
            />
            <Common.EditInput
              setValue={setValue}
              register={register}
              errors={errors}
              title="單位/樓層/座數/大廈"
              value={location.state?.form3?.addr_line1}
              editing={isEditingForm3}
              field="building"
              onChange={(e) => {
                const value = alphabetOnly((e?.target?.value || '')).toUpperCase()
                setValue('building', value)
              }}
            />
            <Common.EditInput
              setValue={setValue}
              register={register}
              errors={errors}
              title="屋苑/街道"
              value={location.state?.form3?.addr_line2}
              editing={isEditingForm3}
              field="estate"
              onChange={(e) => {
                const value = alphabetOnly((e?.target?.value || '')).toUpperCase()
                setValue('estate', value)
              }}
            />
            <Common.EditSelect
              register={register}
              errors={errors}
              title="房屋類型"
              value={location.state?.form3?.property_type}
              editing={isEditingForm3}
              options={options.property_type}
              field="property_type"
              setValue={setValue}
              setError={setError}
            />
            <Common.EditSelect
              register={register}
              errors={errors}
              title="同住人數"
              value={location.state?.form3?.number_living_with}
              editing={isEditingForm3}
              options={options.number_living_with}
              field="number_living_with"
              setValue={setValue}
              setError={setError}
            />
            <Common.EditSelect
              register={register}
              errors={errors}
              title="持有人"
              value={location.state?.form3?.property_owner}
              editing={isEditingForm3}
              options={options.property_owner}
              field="property_owner"
              setValue={setValue}
              setError={setError}
            />
            <Common.EditInputMoney
              setValue={setValue}
              register={register}
              errors={errors}
              title="每月房租"
              setError={setError}
              value={location.state?.form3?.monthly_rent}
              editing={isEditingForm3}
              field="monthly_rent"
              placeholder=' '
              zeroAllow={true}
            />
          </div>
        </div>
        <div className="flex justify-center mt-9 mb-4">
          <button disabled={isSubmitting} type="submit" className={`rounded-full text-white text-xl px-14 py-3 ${isSubmitting ? 'bg-mercury' : 'bg-lightningYellow'}`}>
            確定提交
          </button>
        </div>
      </div>
    </form>
  );
}

export default ApplicationConfirmData;
