import React from 'react';
import { useNavigate } from 'react-router-dom';
import NoCamera from '../../assets/images/no-camera.svg';

function CheckDeviceNoCamera() {
  const navigate = useNavigate();
  return (
    <div className="bg-white p-10">
      <div className="flex justify-center mb-5">
        <img src={NoCamera} alt="" />
      </div>
      <p className="text-lg text-gray">閣下現正使用的裝置沒有拍攝功能，請轉換至具備拍攝功能的電腦或手機 裝置繼續申請程序。</p>
      <div className="flex justify-center my-4">
        <button onClick={() => navigate('/')} className="rounded-full text-white text-xl bg-lightningYellow px-14 py-3">
          確定
        </button>
      </div>
    </div>
  );
}

export default CheckDeviceNoCamera;
