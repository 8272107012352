import axios from '../../api';

export const actionSendOTP = (payload) => async () => {
  try {
    const response = await axios.post('/send-otp', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionLoginOTP = (payload) => async () => {
  try {
    const response = await axios.post('/auth/login-otp', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionSendClientID = (payload) => async () => {
  try {
    const response = await axios.post('/update-google-client', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionSubmitForm1 = (payload) => async () => {
  try {
    const response = await axios.post('/form-1/submit', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionSubmitForm21 = (payload) => async () => {
  try {
    const response = await axios.post('/form-2-1/submit', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionSubmitForm22 = (payload) => async () => {
  try {
    const response = await axios.post('/form-2-2/submit', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionConfirmData = () => async () => {
  try {
    const response = await axios.post('/confirm-data');
    return response.data;
  } catch (error) {
    //
  }
};

export const actionTsoNewApplication = (payload) => async () => {
  try {
    const response = await axios.post('/tso-new-application', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionTsoOcrDa = (payload) => async () => {
  try {
    const response = await axios.post('/tso-ocr-da', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionTsoFr = (payload) => async () => {
  try {
    const response = await axios.post('/tso-fr', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionAgreeCamera = () => async () => {
  try {
    const response = await axios.post('/agree-camera');
    return response.data;
  } catch (error) {
    //
  }
};

export const actionCheckCamera = () => async () => {
  try {
    const response = await axios.post('/check-camera');
    return response.data;
  } catch (error) {
    //
  }
};

export const actionGetStateDoc = () => async () => {
  try {
    const response = await axios.get('/state-doc-version');
    return response.data;
  } catch (error) {
    //
  }
};

export const actionApplyLater = (data) => async () => {
  try {
    const response = await axios.post('/apply-later', data);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionExtra1stComplete = (payload) => async () => {
  try {
    const response = await axios.post('/extra-1st-complete', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionTuHealthCheck = (payload) => async () => {
  try {
    const response = await axios.get('/tu-health-check', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionImageQuanlity = (payload) => async () => {
  try {
    const response = await axios.post('/tso-image-quanlity', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionEkycLater = () => async () => {
  try {
    const response = await axios.get('/ekyc-later');
    return response.data;
  } catch (error) {
    //
  }
};

export const actionNeedEkyc = () => async () => {
  try {
    const response = await axios.post('/need-ekyc');
    return response.data;
  } catch (error) {
    //
  }
};


export const actionMoneyForm1 = (payload) => async () => {
  try {
    const response = await axios.post('/money-hero/form1', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionMoneyForm2 = (payload) => async () => {
  try {
    const response = await axios.post('/money-hero/form2', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionMoneyForm3 = (payload) => async () => {
  try {
    const response = await axios.post('/money-hero/form3', payload);
    return response.data;
  } catch (error) {
    //
  }
};

export const actionMoneyComplete = (payload) => async () => {
  try {
    const response = await axios.post('/money-hero/complete', payload);
    return response.data;
  } catch (error) {
    //
  }
};