import _ from 'lodash';
import Swal from 'sweetalert2';
import Close from '../assets/images/close.svg'
export const styles = {
  control: (base) => ({
    ...base,
    borderColor: 'transparent !important',
    boxShadow: 'none',
  }),
  singleValue: (base) => ({
    ...base,
    color: '#0A62B3',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#0A62B3',
    padding: 0,
  }),
  placeholder: (base) => ({
    ...base,
    color: '#8E8E8E',
  }),
};

export const numberWithCommas = (x) => {
  return x
    ?.toString()
    ?.replace(/[a-zA-Z,]/gi, '')
    ?.replace(/[^a-z\d\s]+/gi, '')
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberWithNoCommas = (x) => {
  return x.toString().replace(/[a-zA-Z,]/gi, '').replace(/[^a-z\d\s]+/gi, '');
};

export const pad = (d) => {
  return (d < 10) ? '0' + d.toString() : d.toString();
}

export const capitalizeName = (name) => {
  return name.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
}

export const alphabetOnly = (text) => {
  return text?.toString()
    ?.replace(/[^a-z\d\s]+/gi, '')
    ?.replace(/[\u00A0\u1680\u180E\u2000-\u200B\u202F\u205F\u3000\uFEFF]/gi, '')
}

export const alphabetAndNumberOnly = (text) => {
  return text?.toString()
    ?.replace(/[^a-zA-Z0-9\s]+/gi, '')
    ?.replace(/[\u00A0\u1680\u180E\u2000-\u200B\u202F\u205F\u3000\uFEFF]/gi, '')
}

export const options = {
  loan_purpose: [
    { value: 'U001', label: '公司運營資金周轉' },
    { value: 'U002', label: '住宅裝修' },
    { value: 'U003', label: '生活費' },
    { value: 'U004', label: '物品購買' },
    { value: 'U005', label: '建房' },
    { value: 'U006', label: '教育費' },
    { value: 'U007', label: '旅行' },
    { value: 'U008', label: '買車或修車' },
    { value: 'U009', label: '貸款償還' },
    { value: 'U010', label: '信用卡還款' },
    { value: 'U011', label: '購房' },
    { value: 'U012', label: '醫療費' },
    { value: 'U013', label: '結婚備用金' },
    { value: 'U014', label: '其他' },
    { value: 'U017', label: '投資' },
    { value: 'U018', label: '交稅或保險' },
    { value: 'U019', label: '備用' },
  ],
  education: [
    { value: 1, label: '學士或以上' },
    { value: 2, label: '中七畢業/大專' },
    { value: 3, label: '中五畢業(舊制)/中六畢業(新高中學制)' },
    { value: 4, label: '中三畢業' },
    { value: 5, label: '其他' },
  ],
  marriage_status: [
    { value: '01', label: '已婚' },
    { value: '02', label: '未婚' },
    { value: '03', label: '離婚' },
    { value: '05', label: '喪偶' },
  ],
  business_type: [
    { value: '0010', label: '商業服務業' },
    { value: '0020', label: '飲食業' },
    { value: '0030', label: '旅館/酒店業' },
    { value: '0040', label: '水電煤製造業' },
    { value: '0050', label: '建築業' },
    { value: '0060', label: '郵政業' },
    { value: '0070', label: '電子電腦業' },
    { value: '0080', label: '批發及零售業' },
    { value: '0090', label: '通訊業' },
    { value: '0100', label: '金融業' },
    { value: '0110', label: '房地產業' },
    { value: '0120', label: '租借和商務服務業' },
    { value: '0130', label: '教育業' },
    { value: '0140', label: '公共設施管理業' },
    { value: '0150', label: '居民服務和其他服務業' },
    { value: '0160', label: '政府部門' },
    { value: '0170', label: '社會福利業' },
    { value: '0180', label: '文化及娱樂業' },
    { value: '0190', label: '出入口貿易' },
    { value: '0200', label: '保險業' },
    { value: '0210', label: '產品製造業' },
    { value: '0220', label: '倉務運輸業' },
    { value: '0230', label: '醫療' },
    { value: '0240', label: '其他' },
  ],
  job_type: [
    { value: '01', label: '上班族' },
    { value: '03', label: '學生' },
    { value: '04', label: '主婦' },
    { value: '05', label: '無業' },
    { value: '06', label: '公務員' },
    { value: '07', label: '自僱人士' },
    { value: '08', label: '大學生' },
    { value: '09', label: '自由工作者' },
  ],
  position: [
    { label: "會計", value: "101" },
    { label: "收銀員", value: "102" },
    { label: "清潔員", value: "103" },
    { label: "文員", value: "104" },
    { label: "廚師/侍應", value: "105" },
    { label: "客戶服務", value: "106" },
    { label: "跟車/送貨", value: "107" },
    { label: "設計/繪圖", value: "108" },
    { label: "家務助理", value: "109" },
    { label: "司機", value: "110" },
    { label: "工程", value: "111" },
    { label: "非技術人員", value: "112" },
    { label: "管理/行政", value: "113" },
    { label: "營業代表/推廣/售貨員", value: "114" },
    { label: "採購員", value: "115" },
    { label: "辦工室助理", value: "116" },
    { label: "生產/工廠", value: "117" },
    { label: "接待員", value: "118" },
    { label: "秘書", value: "119" },
    { label: "保安", value: "120" },
    { label: "倉務管理", value: "121" },
    { label: "教職員", value: "122" },
    { label: "技工", value: "123" },
    { label: "導遊", value: "124" },
    { label: "打字員", value: "125" },
    { label: "醫護人員", value: "126" },
    { label: "專業人員", value: "127" },
    { label: "警察", value: "128" },
    { label: "消防員", value: "129" },
    { label: "地盤工人", value: "130" },
    { label: "財務", value: "131" },
    { label: "客戶代表", value: "132" },
    { label: "其他", value: "133" },
  ],
  employment_type: [
    { value: '01', label: '全職員工' },
    { value: '02', label: '合約員工' },
    { value: '03', label: '調派員工' },
    { value: '04', label: '兼職員工' },
    { value: '05', label: '自由工作者' },
  ],
  payroll_method: [
    { value: 1, label: '月薪' },
    { value: 2, label: '日薪' },
    { value: 3, label: '時薪' },
    { value: 4, label: '週薪' },
  ],
  mpf: [
    { value: 1, label: '有' },
    { value: 2, label: '沒有' },
  ],
  area: [
    { province_id: 1, value: 'HK', label: '香港' },
    { province_id: 2, value: 'KLN', label: '九龍' },
    { province_id: 3, value: 'NT', label: '新界' },
  ],
  district_hk: [
    { city_id: 1, value: "SHEUNG WAN", label: "上環", tu_value: "SHEUNG WAN" },
    { city_id: 2, value: "TAI HANG", label: "大坑", tu_value: "TAI HANG" },
    { city_id: 3, value: "BIG WAVE BAY", label: "大浪灣", tu_value: "SHEK O" },
    { city_id: 4, value: "TAI TAM", label: "大潭", tu_value: "STANLEY" },
    { city_id: 5, value: "SIU SAI WAN", label: "小西灣", tu_value: "SIU SAI WAN" },
    { city_id: 6, value: "CENTRAL", label: "中環", tu_value: "CENTRAL" },
    { city_id: 7, value: "TAIKOO SHING", label: "太古城", tu_value: "QUARRY BAY" },
    { city_id: 8, value: "VICTORIA PEAK", label: "太平山", tu_value: "THE PEAK" },
    { city_id: 9, value: "NORTH POINT", label: "北角", tu_value: "NORTH POINT" },
    { city_id: 10, value: "SHEK TONG TSUI", label: "石塘咀", tu_value: "SHEK TONG TSUI" },
    { city_id: 11, value: "SHEK O", label: "石澳", tu_value: "SHEK O" },
    { city_id: 12, value: "SAI YING PUN", label: "西營盤", tu_value: "SAI YING PUN" },
    { city_id: 13, value: "SAI WAN HO", label: "西灣河", tu_value: "SAI WAN HO" },
    { city_id: 14, value: "HENG FA CHUEN", label: "杏花邨", tu_value: "CHAI WAN" },
    { city_id: 15, value: "STANLEY", label: "赤柱", tu_value: "STANLEY" },
    { city_id: 16, value: "ADMIRALTY", label: "金鐘", tu_value: "ADMIRALTY" },
    { city_id: 17, value: "FORTRESS HILL", label: "砲台山", tu_value: "NORTH POINT" },
    { city_id: 18, value: "ABERDEEN", label: "香港仔", tu_value: "ABERDEEN" },
    { city_id: 19, value: "CHAI WAN", label: "柴灣", tu_value: "CHAI WAN" },
    { city_id: 20, value: "KENNEDY TOWN", label: "堅尼地城", tu_value: "KENNEDY TOWN" },
    { city_id: 21, value: "DEEP WATER BAY", label: "深水灣", tu_value: "REPULSE BAY" },
    { city_id: 22, value: "REPULSE BAY", label: "淺水灣", tu_value: "REPULSE BAY" },
    { city_id: 23, value: "HAPPY VALLEY", label: "跑馬地", tu_value: "HAPPY VALLEY" },
    { city_id: 24, value: "WONG CHUK HANG", label: "黃竹坑", tu_value: "WONG CHUK HANG" },
    { city_id: 25, value: "SHAU KEI WAN", label: "筲箕灣", tu_value: "SHAU KEI WAN" },
    { city_id: 26, value: "CAUSEWAY BAY", label: "銅鑼灣", tu_value: "CAUSEWAY BAY" },
    { city_id: 27, value: "MOUNT DAVIS", label: "摩星嶺", tu_value: "POK FU LAM" },
    { city_id: 28, value: "AP LEI CHAU", label: "鴨脷洲", tu_value: "AP LEI CHAU" },
    { city_id: 30, value: "POK FU LAM", label: "薄扶林", tu_value: "POK FU LAM" },
    { city_id: 31, value: "QUARRY BAY", label: "鰂魚涌", tu_value: "QUARRY BAY" },
    { city_id: 32, value: "WAN CHAI", label: "灣仔", tu_value: "WAN CHAI" },
    { city_id: 134, value: "CHUNG HOM KOK", label: "舂坎角", tu_value: "STANLEY" },
    { city_id: 135, value: "MID-LEVELS", label: "半山區", tu_value: "MID LEVELS" },
    { city_id: 137, value: "SAI WAN", label: "西環", tu_value: "SAI WAN" },
    { city_id: 138, value: "SHOUSON HILL", label: "壽臣山", tu_value: "WONG CHUK HANG" },
    { city_id: 139, value: "TAI KOO", label: "太古", tu_value: "QUARRY BAY" },
    { city_id: 140, value: "TIN HAU", label: "天后", tu_value: "CAUSEWAY BAY" },
    { city_id: 163, value: "JARDINE'S LOOKOUT", label: "渣甸山", tu_value: "JARDINE'S LOOKOUT" },
  ],
  district_kw: [
    { city_id: 33, value: "KOWLOON CITY", label: "九龍城", tu_value: "KOWLOON CITY" },
    { city_id: 34, value: "KOWLOON TONG", label: "九龍塘", tu_value: "KOWLOON TONG" },
    { city_id: 35, value: "KOWLOON BAY", label: "九龍灣", tu_value: "KOWLOON BAY" },
    { city_id: 36, value: "YAU YAT CHUEN", label: "又一村", tu_value: "KOWLOON TONG" },
    { city_id: 37, value: "TO KWA WAN", label: "土瓜灣", tu_value: "TO KWA WAN" },
    { city_id: 38, value: "TAI HANG TUNG", label: "大坑東", tu_value: "SHEK KIP MEI" },
    { city_id: 39, value: "TAI KOK TSUI", label: "大角咀", tu_value: "TAI KOK TSUI" },
    { city_id: 40, value: "TAI WO PING", label: "大窩坪", tu_value: "SHEK KIP MEI" },
    { city_id: 41, value: "NGAU CHI WAN", label: "牛池灣", tu_value: "WONG TAI SIN" },
    { city_id: 42, value: "NGAU TAU KOK", label: "牛頭角", tu_value: "NGAU TAU KOK" },
    { city_id: 43, value: "PAK TIN", label: "白田", tu_value: "SHAM SHUI PO" },
    { city_id: 44, value: "SHEK KIP MEI", label: "石硤尾", tu_value: "SHEK KIP MEI" },
    { city_id: 45, value: "TSIM SHA TSUI", label: "尖沙咀", tu_value: "TSIM SHA TSUI" },
    { city_id: 46, value: "JORDAN", label: "佐敦", tu_value: "JORDAN" },
    { city_id: 47, value: "HO MAN TIN", label: "何文田", tu_value: "HO MAN TIN" },
    { city_id: 48, value: "SAU MAU PING", label: "秀茂坪", tu_value: "KWUN TONG" },
    { city_id: 49, value: "MONG KOK", label: "旺角", tu_value: "MONG KOK" },
    { city_id: 50, value: "STONECUTTERS ISLAND", label: "昂船洲", tu_value: "STONECUTTERS ISLAND" },
    { city_id: 51, value: "YAU MA TEI", label: "油麻地", tu_value: "YAU MA TEI" },
    { city_id: 52, value: "YAU TONG", label: "油塘", tu_value: "YAU TONG" },
    { city_id: 53, value: "CHEUNG SHA WAN", label: "長沙灣", tu_value: "CHEUNG SHA WAN" },
    { city_id: 54, value: "HUNG HOM", label: "紅磡", tu_value: "HUNG HOM" },
    { city_id: 55, value: "CHA KWO LING", label: "茶果嶺", tu_value: "LAM TIN" },
    { city_id: 56, value: "LAI CHI KOK", label: "荔枝角", tu_value: "LAI CHI KOK" },
    { city_id: 57, value: "MA TAU WAI", label: "馬頭圍", tu_value: "KOWLOON CITY" },
    { city_id: 58, value: "KAI TAK", label: "啟德", tu_value: "KOWLOON CITY" },
    { city_id: 59, value: "SHAM SHUI PO", label: "深水埗", tu_value: "SHAM SHUI PO" },
    { city_id: 60, value: "WONG TAI SIN", label: "黃大仙", tu_value: "WONG TAI SIN" },
    { city_id: 61, value: "TSZ WAN SHAN", label: "慈雲山", tu_value: "TSZ WAN SHAN" },
    { city_id: 62, value: "SAN PO KONG", label: "新蒲崗", tu_value: "SAN PO KONG" },
    { city_id: 63, value: "LOK FU", label: "樂富", tu_value: "WONG TAI SIN" },
    { city_id: 64, value: "WANG TAU HOM", label: "橫頭磡", tu_value: "WONG TAI SIN" },
    { city_id: 65, value: "LAM TIN", label: "藍田", tu_value: "LAM TIN" },
    { city_id: 66, value: "SO UK", label: "蘇屋", tu_value: "CHEUNG SHA WAN" },
    { city_id: 67, value: "KWUN TONG", label: "觀塘", tu_value: "KWUN TONG" },
    { city_id: 68, value: "DIAMOND HILL", label: "鑽石山", tu_value: "DIAMOND HILL" },
    { city_id: 133, value: "CHOI HUNG", label: "彩虹", tu_value: "WONG TAI SIN" },
    { city_id: 141, value: "BEACON HILL", label: "畢架山", tu_value: "KOWLOON TONG" },
    { city_id: 142, value: "JORDAN VALLEY", label: "佐敦谷", tu_value: "KWUN TONG" },
    { city_id: 143, value: "KING`S PARK", label: "京士柏", tu_value: "YAU MA TEI" },
    { city_id: 144, value: "MA TAU KOK", label: "馬頭角", tu_value: "KOWLOON CITY" },
    { city_id: 145, value: "MEI FOO", label: "美孚", tu_value: "LAI CHI KOK" },
    { city_id: 146, value: "PING SHEK", label: "坪石", tu_value: "KWUN TONG" },
    { city_id: 147, value: "PRINCE EDWARD", label: "太子", tu_value: "MONG KOK" },
    { city_id: 148, value: "WHAMPOA", label: "黃埔", tu_value: "HUNG HOM" },
    { city_id: 164, value: "TSIM SHA TSUI EAST", label: "尖東", tu_value: "TSIM SHA TSUI EAST" },
  ],
  district_nt: [
    { city_id: 69, value: "MA LIU SHUI", label: "馬料水", tu_value: "SHA TIN" },
    { city_id: 70, value: "MA ON SHAN", label: "馬鞍山", tu_value: "MA ON SHAN" },
    { city_id: 71, value: "TSEUNG KWAN O", label: "將軍澳", tu_value: "TSEUNG KWAN O" },
    { city_id: 72, value: "SHAM TSENG", label: "深井", tu_value: "SHAM TSENG" },
    { city_id: 73, value: "CLEAR WATER BAY", label: "清水灣", tu_value: "CLEAR WATER BAY" },
    { city_id: 74, value: "YUEN CHAU KOK", label: "圓洲角", tu_value: "SHA TIN" },
    { city_id: 75, value: "SHA TIN", label: "沙田", tu_value: "SHA TIN" },
    { city_id: 76, value: "LOK MA CHAU", label: "落馬洲", tu_value: "YUEN LONG" },
    { city_id: 77, value: "KWAI CHUNG", label: "葵涌", tu_value: "KWAI CHUNG" },
    { city_id: 78, value: "TSUI LAM", label: "翠林", tu_value: "TSEUNG KWAN O" },
    { city_id: 79, value: "TIU KENG LENG", label: "調景嶺", tu_value: "TSEUNG KWAN O" },
    { city_id: 80, value: "KAM TIN", label: "錦田", tu_value: "YUEN LONG" },
    { city_id: 81, value: "LUNG KWU TAN", label: "龍鼓灘", tu_value: "TUEN MUN" },
    { city_id: 83, value: "PO LAM", label: "寶琳", tu_value: "TSEUNG KWAN O" },
    { city_id: 84, value: "HO CHUNG", label: "蠔涌", tu_value: "SAI KUNG" },
    { city_id: 85, value: "LANTAU ISLAND", label: "大嶼山", tu_value: "LANTAU ISLAND" },
    { city_id: 87, value: "PENG CHAU", label: "坪洲", tu_value: "PENG CHAU" },
    { city_id: 88, value: "CHEUNG CHAU", label: "長洲", tu_value: "CHEUNG CHAU" },
    { city_id: 89, value: "LAMMA ISLAND", label: "南丫島", tu_value: "LAMMA ISLAND" },
    { city_id: 90, value: "HEI LING CHAU", label: "喜靈洲", tu_value: "PENG CHAU" },
    { city_id: 91, value: "DISCOVERY BAY", label: "愉景灣", tu_value: "LANTAU ISLAND" },
    { city_id: 92, value: "PAT HEUNG", label: "八鄉", tu_value: "YUEN LONG" },
    { city_id: 93, value: "SHEUNG SHUI", label: "上水", tu_value: "SHEUNG SHUI" },
    { city_id: 94, value: "TAI CHIK SHA", label: "大赤沙", tu_value: "TSEUNG KWAN O" },
    { city_id: 95, value: "TAI PO TSAI", label: "大埔仔", tu_value: "CLEAR WATER BAY" },
    { city_id: 96, value: "TAI PO MARKET", label: "大埔墟", tu_value: "TAI PO" },
    { city_id: 97, value: "TAI WAI", label: "大圍", tu_value: "SHA TIN" },
    { city_id: 98, value: "TAI WO HAU", label: "大窩口", tu_value: "TSUEN WAN" },
    { city_id: 100, value: "SIU CHIK SHA", label: "小赤沙", tu_value: "CLEAR WATER BAY" },
    { city_id: 101, value: "SIU LEK YUEN", label: "小瀝源", tu_value: "SHA TIN" },
    { city_id: 102, value: "YUEN LONG", label: "元朗", tu_value: "YUEN LONG" },
    { city_id: 103, value: "TIN SHUI WAI", label: "天水圍", tu_value: "YUEN LONG" },
    { city_id: 104, value: "TAI WO", label: "太和", tu_value: "TAI PO" },
    { city_id: 105, value: "TUEN MUN", label: "屯門", tu_value: "TUEN MUN" },
    { city_id: 106, value: "FO TAN", label: "火炭", tu_value: "SHA TIN" },
    { city_id: 107, value: "PAK TAM CHUNG", label: "北潭涌", tu_value: "SAI KUNG" },
    { city_id: 108, value: "PO TOI O", label: "布袋澳", tu_value: "CLEAR WATER BAY" },
    { city_id: 109, value: "TA KU LING", label: "打鼓嶺", tu_value: "SHA TAU KOK" },
    { city_id: 110, value: "TING KAU", label: "汀九", tu_value: "TSUEN WAN" },
    { city_id: 111, value: "PAK SHA WAN", label: "白沙灣", tu_value: "SAI KUNG" },
    { city_id: 114, value: "SHEK MUN", label: "石門", tu_value: "SHA TIN" },
    { city_id: 115, value: "SHEK KONG", label: "石崗", tu_value: "YUEN LONG" },
    { city_id: 116, value: "SHEK LEI PUI", label: "石梨貝", tu_value: "KWAI CHUNG" },
    { city_id: 117, value: "WO CHE", label: "禾輋", tu_value: "SHA TIN" },
    { city_id: 118, value: "TOLO HARBOUR", label: "吐露港", tu_value: "TAI PO" },
    { city_id: 120, value: "SAI KUNG", label: "西貢", tu_value: "SAI KUNG" },
    { city_id: 121, value: "HANG HAU", label: "坑口", tu_value: "TSEUNG KWAN O" },
    { city_id: 122, value: "SHA TAU KOK", label: "沙頭角", tu_value: "SHA TAU KOK" },
    { city_id: 123, value: "TSING YI", label: "青衣", tu_value: "TSING YI" },
    { city_id: 124, value: "NAM SANG WAI", label: "南生圍", tu_value: "YUEN LONG" },
    { city_id: 125, value: "PING SHAN", label: "屏山", tu_value: "YUEN LONG" },
    { city_id: 126, value: "HUNG SHUI KIU", label: "洪水橋", tu_value: "YUEN LONG" },
    { city_id: 127, value: "LAU FAU SHAN", label: "流浮山", tu_value: "YUEN LONG" },
    { city_id: 128, value: "FANLING", label: "粉嶺", tu_value: "FANLING" },
    { city_id: 129, value: "TSUEN WAN", label: "荃灣", tu_value: "TSUEN WAN" },
    { city_id: 130, value: "LAI KING", label: "荔景", tu_value: "KWAI CHUNG" },
    { city_id: 131, value: "TUNG CHUNG", label: "東涌", tu_value: "TUNG CHUNG" },
    { city_id: 132, value: "TAI PO", label: "大埔", tu_value: "TAI PO" },
    { city_id: 149, value: "KAM SHEUNG ROAD", label: "錦上路", tu_value: "YUEN LONG" },
    { city_id: 150, value: "KWAI FONG ", label: "葵芳", tu_value: "KWAI CHUNG" },
    { city_id: 151, value: "KWAI HING", label: "葵興", tu_value: "KWAI CHUNG" },
    { city_id: 152, value: "LAM TEI", label: "藍地", tu_value: "TUEN MUN" },
    { city_id: 153, value: "LO WU", label: "羅湖", tu_value: "LO WU" },
    { city_id: 154, value: "MA WAN", label: "馬灣", tu_value: "TSUEN WAN" },
    { city_id: 155, value: "SHEK WU HUI", label: "石湖墟", tu_value: "SHEUNG SHUI" },
    { city_id: 156, value: "SO KWUN WAT", label: "掃管笏", tu_value: "TUEN MUN" },
    { city_id: 157, value: "SUNNY BAY", label: "欣澳", tu_value: "LANTAU ISLAND" },
    { city_id: 158, value: "TAI LAM CHUNG", label: "大欖涌", tu_value: "TUEN MUN" },
    { city_id: 159, value: "TAI MONG TSAI", label: "大網仔", tu_value: "SAI KUNG" },
    { city_id: 160, value: "TAI PO KAU", label: "大埔滘", tu_value: "TAI PO" },
    { city_id: 161, value: "TSING LUNG TAU", label: "青龍頭", tu_value: "TSING LUNG TAU" },
    { city_id: 162, value: "WU KAI SHA", label: "烏溪沙", tu_value: "SHA TIN" },
    { city_id: 165, value: "CHEK LAP KOK", label: "赤鱲角", tu_value: "CHEK LAP KOK" },
    { city_id: 166, value: "CHI MA WAN PENINSULA", label: "芝麻灣半島", tu_value: "CHI MA WAN PENINSULA" },
    { city_id: 167, value: "JUNK BAY", label: "將軍澳海灣", tu_value: "JUNK BAY" },
    { city_id: 168, value: "TAI O", label: "大澳", tu_value: "LANTAU ISLAND" },
  ],
  property_type: [
    { value: '10', label: '公屋' },
    { value: '05', label: '自置公屋' },
    { value: '01', label: '居屋' },
    { value: '04', label: '私樓' },
    { value: '06', label: '村屋(租)' },
    { value: '03', label: '村屋(自置)' },
    { value: '100', label: '宿舍' },
    { value: '99', label: '劏房' },
  ],
  number_living_with: [
    { value: '01', label: '0名' },
    { value: '02', label: '1名' },
    { value: '03', label: '2名' },
    { value: '04', label: '3名' },
    { value: '05', label: '4名' },
    { value: '99', label: '5名以上' },
  ],
  property_owner: [
    { value: '01', label: '本人' },
    { value: '02', label: '配偶' },
    { value: '03', label: '家人' },
    { value: '04', label: '親戚' },
    { value: '05', label: '朋友' },
    { value: '06', label: '租用' },
  ],
  receipt_way: [
    { value: '01', label: '自動轉賬' },
    { value: '02', label: '支票' },
    { value: '03', label: '現金' },
  ],
  genders: [
    { value: 1, label: '先生' },
    { value: 2, label: '小姐' },
  ],
};

export const detectMob = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const renderRange = (start, end, revert) => {
  const result = [];
  for (let i = Number(start); i <= Number(end); i++) {
    result.push({ value: i.toString(), label: i.toString() });
  }
  return revert ? _.reverse(result) : result;
};

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

export const sw2Alert = (text) => {
  Swal.fire({
    text: text,
    showConfirmButton: false,
    timer: 5000,
    toast: true,
    position: 'top',
    padding: '0.75rem',
    showCloseButton: true,
    closeButtonHtml: `<img src="${Close}" alt="close" />`,
  })
}

export const onTokenExpired = () => {
  setInterval(() => {
    const expired_date = localStorage.getItem('expired_date')
    if (expired_date) {
      const currentLocalTime = new Date().toLocaleString("en-US", { timeZone: "Asia/Hong_Kong" })
      const timeout = new Date(expired_date).getTime() - new Date(currentLocalTime).getTime()
      if (timeout < 0) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('expired_date')
        fetch(`${process.env.REACT_APP_BASE_API_URL}/client-trace-log`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ message: `Expired time redirect to home page, current time: ${currentLocalTime}, expired time: ${expired_date}, timeout: ${timeout}` })
        }).then((response) => console.log(response));
        setTimeout(() => {
          window.location.href = process.env.REACT_APP_HOME_URL
        }, 2000)
      }
    }
  }, 1000);
}

export const createGuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
} 

export const getUserMedia = (constrains, success, error) => {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    // 最新标准API
    navigator.mediaDevices.getUserMedia(constrains).then(success).catch(error);
  } else if (navigator.webkitGetUserMedia) {
    // webkit内核浏览器
    navigator.webkitGetUserMedia(constrains).then(success).catch(error);
  } else if (navigator.mozGetUserMedia) {
    // Firefox浏览器
    navigator.mozGetUserMedia(constrains).then(success).catch(error);
  } else if (navigator.getUserMedia) {
    // 旧版API
    navigator.getUserMedia(constrains).then(success).catch(error);
  }
}

// SUCCESS = 0
// PHONE_REQUIRED = 1
// OTP_REQUIRED = 2
// INVALID_TOKEN = 3
// INVALID_INPUT = 4
// INVALID_OTP = 5
// EXPIRED_OTP = 6
// MAX_WRONG_INPUT_OTP = 7
// CAM_STATUS_REQUIRED = 8
// NOT_SUBMIT_DATA_YET = 9
// AUTH_LOAN_SYSTEM_FAILED = 10
// REQUEST_ID_REQUIRED = 11
// REQUEST_ID_NOT_EXISTS = 12
