import React from 'react';

function Loading({ isVisible }) {
  return isVisible && (
    <div className="flex z-50 flex-col items-center justify-center fixed top-0 left-0 w-full h-full" style={{ backgroundColor: '#ffffff80' }}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loading;
