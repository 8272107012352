import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { detectMob, getCertifacationNumber, getMobileOperatingSystem } from '../../utils/common';
import LightBulb from '../../assets/images/light-bulb.svg';
import Loading from '../../components/Common/Loading';
import InvalidToken from '../../components/PopUpBox/InvalidToken';

import { actionApplyLater, actionImageQuanlity, actionTsoNewApplication, actionTsoOcrDa, actionTuHealthCheck } from '../../store/application/actions';
import { actionApplicationIP, actionErrorCount } from '../../store/common/actions';
import getMessage from '../../utils/message';
import Error from '../../components/PopUpBox/Error';
import { PopUpBox } from '../../components';

const COUNT_TIMES = 4;

let delayTimer;
let delayTimeout;
let doc_ids = [];
let timeout_count = 0;
const TIME_SCAN_MAX = 20; // seconds
const disabled_timeout = true;

function ConfirmIdCheckDalite({ cardType }) {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [images, setImages] = useState(location.state?.current?.images ?? []);
    const toast = useRef(null);
    const {
        handleSubmit,
        formState: { isSubmitting },
    } = useForm();
    const [countTimes, setCountTimes] = useState(location?.state?.count ?? COUNT_TIMES);
    const [isVisibleInvalidToken, setIsVisibleInvalidToken] = useState(false);
    const [step, setStep] = useState(location.state?.current?.step ?? 1);
    const [docIds, setDocIds] = useState(location.state?.current?.docIds ?? []);
    const [isDetectSuccess, setIsDetectSuccess] = useState(false)

    const rectangleContainerRef = useRef(null);
    const sdkRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [showNextStep, setShowNextStep] = useState(false);
    const [countDown, setCountDown] = useState(3);

    const [showPopup1, setShowPopup1] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const [scanTimeoutCount, setScanTimeoutCount] = useState(0);

    const [isVisibleBlockCamera, setIsVisibleBlockCamera] = useState(false);
    

    const requestPermissionCamera = () => {
        init();
    }

    const onCheckGrantedCameraPermission = () => {
        navigator.permissions
            ?.query({ name: 'camera' })
            .then((permission) => {
                if (permission.state === 'prompt') {
                    setIsVisibleBlockCamera(true)
                } else {
                    init();
                }
            })
            .catch(() => {});
    }

    useEffect(() => {
        if (location.state?.count || location.state?.count === 0) {
            setCountTimes(location.state?.count);
        }
    }, [location.state?.count]);

    //hide overflow when the sdk is doing init
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.height = '100vh';
        document.body.style.overflow = 'hidden';
        document.body.style.touchAction = 'none';
        onCheckGrantedCameraPermission();
        return () => {
            document.body.style.overflow = 'auto';
            document.body.style.touchAction = 'auto';
            sdkRef?.current?.unmount();
        };
    }, []);

    // for getting the screen hight
    useEffect(() => {
        const handleResize = () => {
            setScreenHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    //function - init sdk
    const init = async () => {
        setIsLoading(true);
        doc_ids = [];
        console.log("start init", location?.state?.cardType, doc_ids)
        const sdk = await new window.daLite({
            certiType: location?.state?.cardType === '2003' ? 1 : 2,
            rectangleContainer: document.body
        });
        console.log(`w: ${document.body.clientWidth}, h: ${document.body.offsetHeight}`);

        sdkRef.current = sdk;
        const { success, message, error } = await sdk.setup();
        if (!success) {
            console.log(message)
            if (error?.name === 'NotAllowedError') {
                console.log(error?.name);
                setIsLoading(false);
                alert(message)
            }
            return;
        }
        await sdk.addListeners(window.daLite.type.RESULT, [listener]);
        await sdk.addListeners(window?.daLite?.type?.NEXTSTEP, [onNextStep])
        //you might need to add the the "next step" listener for ui update

        await sdk.start();// start shooting 
        setTimeout(() => {
            // const parentElement = document.getElementById('videoBackground');
            // const childElement = document.getElementById('sdk_video');
            // parentElement.appendChild(childElement);
            // const childWidth = childElement.offsetWidth;
            // //just checking the sdk video Width
            // console.log('Child Width:', childWidth);
            if (document.getElementById('videoBackground') && document.getElementById('sdk_video')) {
                document.getElementById('videoBackground').appendChild(document.getElementById('sdk_video'))
            }
            setIsLoading(false)
            onTimeout()
        });
    }

    const onTimeout = () => {
        if (!disabled_timeout) { 
            let time = 0;
            timeout_count = 0;
            if (delayTimeout) {
                clearInterval(delayTimeout)
            }
            delayTimeout = setInterval(async () => {
                time++;
                if (time === TIME_SCAN_MAX) {
                    console.log('scan timeout');
                    clearInterval(delayTimeout);
                    setScanTimeoutCount(scanTimeoutCount + 1);
                    if (scanTimeoutCount === 0) {
                        console.log('show 1')
                        setShowPopup1(true);
                    }
                    if (scanTimeoutCount > 0) {
                        console.log('show 2')
                        setShowPopup2(true);
                        setShowPopup1(false);
                    }
                    timeout_count++;
                }
            }, 1000);
        }
    }

    const onNextStep = (msg) => {
        console.log('next step: ', msg)
        setStep(msg.step);
        onTimeout();
        // if (msg.step === 3) {
        //     let countDown = 5;
        //     const loop = setInterval(() => {
        //         if (countDown > 0) {
        //             countDown -= 1;
        //             if (!showNextStep) setShowNextStep(true);
        //             setCountDown(countDown);
        //         } else {
        //             clearInterval(loop);
        //             setCountDown(5);
        //             setShowNextStep(false);
        //             setStep(msg.step);
        //         }
        //     }, 1000);
        // } else if(msg.step < 3){
        //     setStep(msg.step);
        // }
    }

    //reset and restart the sdk, need to clean up all listener before restart
    //need to call reset when the sdk return code === 'LOOSE' which mean it lost the id card in at step 2
    const onReset = async () => {
        setImages([])
        setDocIds([])
        setStep(1)
        doc_ids = [];
        await sdkRef.current.removeListeners(window.daLite.type.RESULT, [listener])
        await sdkRef.current.addListeners(window.daLite.type.RESULT, [listener]);
        await sdkRef.current.removeListeners(window.daLite.type.NEXTSTEP, [onNextStep])
        await sdkRef.current.addListeners(window.daLite.type.NEXTSTEP, [onNextStep]);
        await sdkRef.current.start();
    }

    //sdk listener, just copied from the demo app
    const listener = useCallback(async (msg) => {
        const {
            code, preview, image,
        } = msg;
        console.log('app event ', msg);
        const message = getMessage(code);

        if (timeout_count > 0 && code !== 'LOOSE') {
            console.log('timeout return');
            return;
        }

        if (delayTimer) {
            clearTimeout(delayTimer);
        }
        if (msg.step < 4) {
            if (!toast.current) return;
            toast.current.style.display = 'block';
            delayTimer = setTimeout(() => {
                toast.current.style.display = 'none';
            }, 1000);
            if (code === 'LOOSE') {
                toast.current.style.background = '#0FEDA2';
                toast.current.textContent = message;
                console.log("restart after loose id")
                let countDown = 3;
                const loop = setInterval(() => {
                    if (countDown > 0) {
                        countDown -= 1;
                        if (!showNextStep) setShowNextStep(true);
                        setCountDown(countDown);
                    } else {
                        clearInterval(loop);
                        setCountDown(3);
                        setShowNextStep(false);
                        onReset()
                    }
                }, 1000);
            }
            if (code === 'OK') {
                toast.current.style.background = '#0FEDA2';
                toast.current.textContent = message;
            } else {
                toast.current.style.background = '';
                toast.current.textContent = message;
            }
        }
        if (image) {
            console.log("got image")
            setImages((pre) => [...pre, image]);
            if (msg.step === 3) {
                setIsDetectSuccess(true);
                const video = document.getElementById('sdk_video')
                if (video) {
                    video?.pause();
                }
                // clear timeout
                if (delayTimeout) {
                    clearInterval(delayTimeout);
                }
            }
        }
    }, []);

    //button click - image check, check all 3 image at once
    const handleImageCheck = async () => {
        console.log('---- start checking QC ----');
        setIsLoading(true)
        const result_qc1 = await onImageQuanlity(images[0], 1)
        if (!result_qc1) {
            return new Promise((resolve, reject) => {
                setIsLoading(false)
                resolve(false);
            });
        }
        const result_qc2 = await onImageQuanlity(images[1], 2)
        if (!result_qc2) {
            return new Promise((resolve, reject) => {
                setIsLoading(false)
                resolve(false);
            });
        }
        const result_qc3 = await onImageQuanlity(images[2], 3)
        if (!result_qc3) {
            return new Promise((resolve, reject) => {
                setIsLoading(false)
                resolve(false);
            });
        }
        return new Promise((resolve, reject) => {
            setIsLoading(false)
            resolve(true);
        });
    }

    const tsoNewApplication = useCallback(async () => {
        const responseIP = await dispatch(actionApplicationIP());
        const health_check_res = await dispatch(actionTuHealthCheck());
        console.log('health_check_res', health_check_res)
        const response = await dispatch(actionTsoNewApplication({ ip: responseIP?.ip }));
        if (response?.sts_code === 3) {
            // INVALID_TOKEN
            setIsVisibleInvalidToken(true);
        }
    }, [dispatch]);

    const onSubmit = async () => {
        // create new applications
        await tsoNewApplication();
        const result_qc = await handleImageCheck();
        if (!result_qc) {
            console.log('check QC images failed');
            return;
        }
        const response = await dispatch(
            actionTsoOcrDa({
                photo: images[0],
                incline_front: images[1],
                vertical_back: images[2],
                version: location?.state?.cardType,
                application_io: getPlatformInfo(),
                flow: 3,
                vertical_front_id: doc_ids[0],
                incline_front_id: doc_ids[1],
                vertical_back_id: doc_ids[2],
            }),
        );
        if (response?.sts_code !== 0) {
            await dispatch(actionErrorCount());
        }
        if (response?.sts_code === 26 && !disabled_timeout) {
            // doc auth ocr error 
            apply(images[0]);
            return;
        }
        navigate(`${location.pathname.replace('/confirm-id-check/hkid', '/confirm-id-check-result')}`, { replace: true, state: { status: true, notCheckLink: true, result: response?.sts_code, count: countTimes } });
    };

    const getUtmParams = async () => {
        const responseIP = await dispatch(actionApplicationIP());
        let data = {};
        const utm_medium = sessionStorage.getItem('utm_medium');
        const utm_source = sessionStorage.getItem('utm_source');
        const utm_campaign = sessionStorage.getItem('utm_campaign');
        const utm_id = sessionStorage.getItem('utm_id');
        const utm_term = sessionStorage.getItem('utm_term');
        const utm_content = sessionStorage.getItem('utm_content');
        const session_id = localStorage.getItem('session_id');
        data = {
            session_id,
            utm_medium,
            utm_source,
            utm_campaign,
            utm_id,
            utm_term,
            utm_content,
            ip: responseIP?.ip ?? '',
            access_query: `utm_medium=${utm_medium ?? ''}&utm_source=${utm_source ?? ''}&utm_campaign=${utm_campaign ?? ''}&utm_id=${utm_id ?? ''}&utm_term=${utm_term ?? ''}&utm_content=${utm_content ?? ''}`,
        }
        return data;
    }

    // try apply request later
    async function apply(photo) {
        setIsLoading(true);
        const utm_params = await getUtmParams();
        const res = await dispatch(actionApplyLater({
            utm_params,
            step: 'HKID',
            photo,
            flow: 3
        }));
        setIsLoading(false)
        navigate('/application/confirm/complete', { replace: true, state: { status: true, completed: false, content: '', tracking_2nd: true } });
    }

    const onImageQuanlity = async (image, step) => {
        setIsLoading(true)
        const response = await dispatch(
            actionImageQuanlity({
                photo: image,
                step
            }),
        );
        if (response?.sts_code === 3) {
            // INVALID_TOKEN
            setIsVisibleInvalidToken(true);
            return;
        }
        const success = response?.sts_code === 0; // TODO: for test
        setIsLoading(false);
        if (success) {
            console.log('received doc_ids: ', response?.content)
            setDocIds(prev => {
                return [...prev, response?.content]
            });
            doc_ids.push(response?.content);
            console.log('doc_ids', doc_ids);
        } else {
            await dispatch(actionErrorCount());
            navigate(`${location.pathname.replace('/confirm-id-check/hkid', '/confirm-id-check-result')}`, {
                replace: true,
                state: {
                    status: true,
                    result: success,
                    count: countTimes,
                    photo: image,
                    current: {}
                }
            });
        }
        return new Promise((resolve, reject) => {
            resolve(success);
        })
    }

    const getPlatformInfo = () => {
        let platformInfo = {
            device_type: '',
            browser_type: '',
            browser_version: '',
            os: ''
        };
        if (window.platform) {
            platformInfo.device_type = detectMob() ? 'Mobile' : 'PC';
            platformInfo.browser_type = window.platform.name;
            platformInfo.browser_version = window.platform.version;
            platformInfo.os = window.platform.os.family;
        }
        return JSON.stringify(platformInfo);
    }

    const getStepText = (next = 0) => {
        switch (step + next) {
            case 1:
                return '第一步：請掃描身份證的正面';
            case 2:
                return '第二步：請稍微向下傾斜身份證';
            case 3:
                return '第三步：請掃描身份證的背面';
            default:
                return '第一步：請掃描身份證的正面';
        }
    }

    const onFirstStepError = async () => {
        onTimeout();
        setShowPopup1(false);
    }

    const onBackLater = async () => {
        const img = images.length > 0 ? images[0] : null;
        setIsLoading(true)
        await tsoNewApplication();
        await apply(img);
        setIsLoading(false);
        //navigate('/application-id-check-result', { replace: true, state: { status: true, result: 99, count: 0 } });
    }

    return (
        <form className='w-full p-0 m-0 overflow-hidden' onSubmit={handleSubmit(onSubmit)}>
            {isVisibleInvalidToken && <InvalidToken />}
            {isVisibleBlockCamera && <PopUpBox.Error content={`請於稍後彈出的視窗允許取用相機權限`} onClick={() => setIsVisibleBlockCamera(false)}>
                <div className="text-center my-3">
                    <button onClick={requestPermissionCamera} type="button" className="rounded-full text-white text-sm bg-lightningYellow px-14 py-2">
                        確定
                    </button>
                </div>
            </PopUpBox.Error>}
            <Loading isVisible={isSubmitting || isLoading} />
            {showNextStep && <Error>
                <div className="text-center">
                    <p className="text-gray1 my-6"> 檢測不到證件，請重新開始 <span className="text-primary2">({countDown}s)</span></p>
                </div>
            </Error>}
            {
                showPopup1 && <Error>
                    <div className="text-center">
                        <p className="text-gray1 mt-3">未能成功掃描身份證</p>
                        <button type='button' onClick={() => onFirstStepError()} className="rounded-full text-white text-xl px-12 mt-4 py-3 bg-primary">
                            繼續嘗試
                        </button>
                        <button type='button' onClick={() => apply(null)} className="rounded-full text-white text-xl px-12 my-4 py-3 bg-primary2">
                            稍後認證
                        </button>
                    </div>
                </Error>
            }
            {
                showPopup2 && <Error>
                    <div className="text-center">
                        <p className="text-gray1 mt-3">身份證驗證失敗</p>
                        <p className="text-gray1 mt-3 px-11 text-justify">未能成功完成身份證驗證。閣下的貸款申請資料已提交處理，我們的貸款專員稍後會聯絡閣下跟進。</p>
                        <button type='button' onClick={onBackLater} className="rounded-full text-white text-xl px-14 my-4 py-3 bg-primary">
                            確定
                        </button>
                    </div>
                </Error>
            }
            <div id="videoBackground" className="absolute top-0 bg-transparent z-0 object-fill overflow-hidden" style={{ width: '100%', height: screenHeight }}></div>
            <div className='relative w-full z-10'>
                <div className='z-10 w-full'>
                    <div className='bg-white p-5 pt-12'>
                        <p className="flex-1 text-2xl text-primary2 text-right font-semibold border-b border-gray2 mb-4 pb-4">掃描本人身份證</p>
                    </div>
                </div>
                <div className='h-[40vh] relative z-10'>
                    <img id="img" src="vertical_front.png" style={{ display: 'none' }} />
                    <div id='rect' ref={rectangleContainerRef} className='w-full h-full relative z-10 object-fill'></div>
                    <div className='absolute xs4:top-8 xs:top-3 xs5:top-3 w-full aspect-[43/27]'>
                        <div className="absolute z-40 top-5 left-10 xs5:left-14 w-10 h-10 border-t-4 border-l-4 border-white" />
                        <div className="absolute z-40 top-5 right-10 xs5:right-14 w-10 h-10 border-t-4 border-r-4 border-white" />
                        <div className="absolute z-40 bottom-5 left-10 xs5:left-14 w-10 h-10 border-b-4 border-l-4 border-white" />
                        <div className="absolute z-40 bottom-5 right-10 xs5:right-14 w-10 h-10 border-b-4 border-r-4 border-white" />
                    </div>
                </div>
                <div className='w-full pt-12 relative h-[100vh] bg-white p-5'>
                    {
                        !isDetectSuccess ?
                            <div>
                                <div ref={toast} className="absolute z-40 top-3 left-1/3 px-5 py-2 text-white bg-red-500 hidden rounded-md"></div>
                                <p className="text-xl text-primary text-center mt-3 font-semibold">{getStepText()}</p>
                            </div> :
                            <div id='buttonContainer' className='w-full text-center bg-white'>
                                <button style={{ width: 160 }}
                                    disabled={isSubmitting || images.length < 3}
                                    type="submit"
                                    className={`rounded-full text-white text-xl px-14 py-3 ${(isSubmitting || images.length < 3) ? 'bg-mercury' : 'bg-primary'}`}>
                                    確定
                                </button>
                            </div>
                    }
                </div>
            </div>
        </form>
    );
}

export default ConfirmIdCheckDalite;