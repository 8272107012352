import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import LightBulb from '../../../../assets/images/light-bulb.svg';
import { Common } from '../../../../components';
import UploadDocSuccessful from '../UploadDocSuccessful';

import { actionLoanUploadDoc } from '../../../../store/loan/actions';
import { actionTracking } from '../../../../store/common/actions';
import Swal from 'sweetalert2';
import Close from '../../../../assets/images/close.svg';
import { sw2Alert } from '../../../../utils/common';


function UploadDoc({ requestId }) {
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    dispatch(
      actionTracking({
        url: window.location.href,
      }),
    );
  }, [dispatch]);

  const [files, setFiles] = useState([
    {
      title: '最近3個月之住址證明',
    },
    {
      title: '最近1~3個月之出糧戶口紀錄',
    },
    {
      title: '其他文件(如有)',
    },
  ]);

  const onUploadDoc = async () => {
    const formData = new FormData();
    files?.forEach((i) => i.file && formData.append('files', i.file));
    formData.append('request_id', requestId)
    const response = await dispatch(actionLoanUploadDoc(formData));

    if (response?.sts_code === 0) {
      setIsSuccess(true);
    } else if (response?.sts_code === 23){
      sw2Alert('未能上傳文件，請稍後再試。')
    }
  };

  return (
    <>
      {isSuccess ? (
        <UploadDocSuccessful />
      ) : (
        <div className="bg-selago p-5 rounded-xl mb-6 mx-5">
          <p className="flex-1 text-2xl text-gray font-semibold text-center mb-4">上載文件</p>
          <div className="pb-0 flex items-center text-lg mb-2">
            <img src={LightBulb} alt="" className="cursor-pointer" />
            <p className="text-primary mt-2">上傳證明文件須知</p>
          </div>
          <p className="pt-0 text-gray ml-4">
            ・拍攝證件時請確保圖片清晰，並盡量放大
            <br />
            ・每個檔案必須少於5MB
            <br />
            ・請確保文件沒有密碼保護
            <br />
            ・每次最多上傳10個檔案
            <br />
            ・檔案格式：jpg/png/pdf
          </p>
          {files.map((i, idx) => {
            return <Common.Upload setFiles={setFiles} files={files} idx={idx} key={idx} title={`${idx + 1}. ${i.title}`} />;
          })}
          {files.length < 10 && (
            <p
              className="text-primary mt-4 underline cursor-pointer"
              onClick={() =>
                setFiles([
                  ...files,
                  {
                    title: '其他文件',
                  },
                ])
              }
            >
              新增其他文件
            </p>
          )}
          <div className="flex justify-center mt-9 mb-4">
            <button
              disabled={!_.some(files, 'file')}
              onClick={onUploadDoc}
              className={`rounded-full text-white text-xl px-14 py-4 ${_.some(files, 'file') ? 'bg-lightningYellow' : 'bg-mercury'}`}
            >
              遞交文件
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default UploadDoc;
