import React from 'react';
import Logo from '../../../assets/images/logo.svg';
import UsePageTracking from '../UsePageTracking';

function AppHeader() {
  UsePageTracking()
  return (
    <div className="z-20 relative bg-white">
      <div className="bg-primary w-full h-3"></div>
      <div className="flex justify-between shadow-md">
        <div>
          <a href={process.env.REACT_APP_HOME_URL}>
            <img src={Logo} alt="" className="cursor-pointer" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default AppHeader;
