/* eslint-disable import/no-anonymous-default-export */
import TimeOverOTP from './TimeOverOTP';
import Error from './Error';
import Wrong5TimesTakePhoto from './Wrong5TimesTakePhoto';
import InvalidToken from './InvalidToken';

import IDCardTakePhoto from './IDCardTakePhoto';
import SelfieTakePhoto from './SelfieTakePhoto';
import ConfirmSelfieTakePhoto from './ConfirmSelfieTakePhoto';

import SendPhoneNumber from './SendPhoneNumber';
import LoginBox from './LoginBox';
import ConfirmIDCardTakePhoto from './ConfirmIDCardTakePhoto';

export default {
  TimeOverOTP,
  Error,
  Wrong5TimesTakePhoto,
  InvalidToken,

  IDCardTakePhoto,
  SelfieTakePhoto,
  ConfirmSelfieTakePhoto,

  SendPhoneNumber,
  LoginBox,
  ConfirmIDCardTakePhoto,
}
